import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { setOpenIntegrationModal } from '../redux/modalSlice';
import { Box, Checkbox, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import AutoComplete from '../components/AutoComplete';
import DocuTextField from '../cmp-utils/DocuTextField';
import SendIcon from '@mui/icons-material/Send';

function IntegrationModal({open}) {

    const [eventVisible , setEventVisible] = useState(false)
    const dispatch = useDispatch();

    const DemoArray = [
      {
        velue: '1',
        name: 'patrik'
      },
      {
        velue: '2',
        name: 'patrik_2'
      },
      {
        velue: '3',
        name: 'patrik_3'
      },
      {
        velue: '4',
        name: 'patrik_4'
      },
      {
        velue: '5',
        name: 'patrik_5'
      },
  ]

    const ContacthandleClose = () => {
        dispatch(setOpenIntegrationModal(false))
    };

    const handleEventVisible = () => {
        setEventVisible(!eventVisible)
    }

    const openHelp = () =>{
        window.open('https://www.docusoft.net/')
    }
    return (
        <>
            <Dialog
      open={open}
      onClose={ContacthandleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
    //   sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "600px" } }}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="clearfix">
          <DialogTitle>{"Integration"}</DialogTitle>
        </Box>
        <Button onClick={ContacthandleClose} autoFocus>
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent className=' white-box '>
      
      <Box sx={{ flexGrow: 1 }} >
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
         <Box className= ' mt-3'>
         <AutoComplete
                  lable="Calendly"
                  className="w-100"
                  ComData={DemoArray ? DemoArray : []}
                  target="Calendly"
                //   comFun={getForwardUsernObject}
                  // defaultValue={
                  //   Object.keys(mainObject).length !== 0 && mainObject.FromUser
                  //     ? mainObject?.FromUser
                  //     : ""
                  // }
                ></AutoComplete>
         </Box>
        </Grid>
        <Grid item xs={6} md={6}>
         <Box>
         <DocuTextField size={'small'} label={'Secret Key'} />
         <DocuTextField size={'small'} label={'Client Key'}/>
         </Box>

         <Box>
         <FormControlLabel
                control={
                  <Checkbox
                    // checked={eventVisible}
                    // onChange={handleEventVisible}
                    size="small"
                  />
                }
                label="Show All Event"
                sx={{ marginTop: "10px" }}
              />
          <FormControlLabel
                control={
                  <Checkbox
                  checked={eventVisible}
                  onChange={handleEventVisible}
                    size="small"
                  />
                }
                label="Select Event"
                sx={{ marginTop: "2px" }}
              />

         </Box>

         {eventVisible && (
            <>
            <Box className= ' mt-2'>
            <AutoComplete
                  lable="Select Event"
                  className="w-100"
                //   ComData={''}
                  target="Event"
                //   comFun={getForwardUsernObject}
                  // defaultValue={
                  //   Object.keys(mainObject).length !== 0 && mainObject.FromUser
                  //     ? mainObject?.FromUser
                  //     : ""
                  // }
                ></AutoComplete>
            </Box>
            </>
         )}

         <div className=" d-flex justify-content-end mt-3">
        <Typography
                  className="font-12 text-primary  underlined-typography me-3 mt-1"
                 onClick={openHelp}
                >
                 Help
        </Typography>
            <Button
              startIcon={<SendIcon />}
              id="basic-button"
              aria-haspopup="true"
              className="btn-blue "
            //   onClick={BookMeetingBuuton}
            onClick={ContacthandleClose}
            >
            Submit
            </Button>
          </div>
        </Grid>
        
      </Grid>
    </Box>
       
      </DialogContent>
    </Dialog>
        </>
    )
}

export default IntegrationModal