import React, { useState } from "react";
import { useLocalStorage } from "../custom-hook/useLocalStorage";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SimpleFileUpload from "./SimpleFileUpload";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DocuTextField from "../../src/cmp-utils/DocuTextField";
import { styled } from "@mui/system";
import {
  GetSignature_Json_Redux,
  SignatureRemoved,
  Upload_Signature,
} from "../redux/ak_api_helper";
import { useDispatch } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { setOpenSignDocument } from "../redux/modalSlice";
import SignDocument from "../modals/SignDocument";
import { toast } from "react-toastify";
import Signature from "../../src/assets/images/icons/icons8-autograph-24.png";
function CustomeSignature({
  setSignaturePreview,
  setSignatureSetup,
  setSignatureSetupVisibleSetting,
  // setOpenSignatur
}) {
  const portalObj = useLocalStorage("accid");
  const [value, setValue] = React.useState("1");
  const [notes, setNotes] = React.useState(true);
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [confirmVisible, setComfirmVisible] = useState(true);
  const [signatureSetupVisible, setSignatureSetupVisible] = useState(true);

  const clientBody = {
    accid: localStorage.getItem("accIdClient"),
    username: localStorage.getItem("EmailClient"),
    sPassword: localStorage.getItem("passwordClient")
  };

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Set notes to true only if newValue is '1', else set it to false
    setNotes(newValue === "1");
  };

  const handleSignatureSetup = () => {
    setSignaturePreview(true);
    setSignatureSetup(false);
    // setSignatureSetupVisibleSetting(false)
  };

  const listItmes = [
    {
      id: "Allowed file types:",
      data: "jpg , jpeg , png , gif",
    },
    {
      id: "Allowed Image Width:",
      data: "Should be be less than 300 pixels",
    },
    {
      id: "Allowed Image Height:",
      data: "Should be less than 100 pixels",
    },
    {
      id: "Allowed File Size:",
      data: " Should be less than 30KB",
    },
  ];
  /// Aakash Singh
  const [Createsignature, setCreatesignature] = useState();

  const [signatureResult, setsignatureResult] = useState(null);

  const clearHandler = () => {
    Createsignature.clear();
    // setsignatureResult(null);
  };
  const saveHandler = async () => {   
        if (!Createsignature.isEmpty()) {
      setComfirmVisible(false);
      setSaveButtonVisible(true);
      const res = Createsignature.getTrimmedCanvas()
        .toDataURL("image/png")
        .split(",")[1];
      setsignatureResult(res);
    } else {
      toast.error("Please draw a signature before saving.");
    }
  };
  const base64String = "signature"; // Replace with your Base64 string
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
  });
  const [fileUpload, setFileUpload] = useState("");

  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [fileStore, setFileStore] = useState("");

  const selecFiles = (event) => {
    // Get the selected file
    const selectedFile = event.target.files[0];

    // Extract the filename
    const filenameTempory =
      selectedFile && selectedFile.name ? selectedFile.name : "";
    setError("");

    // Extract and log only the file extension (document type)
    const fileExtension = filenameTempory.split(".").pop().toLowerCase();

    // Check if the file is a valid image file and its size is less than 30KB
    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "gif"
    ) {
      // Create a FileReader to read the image file
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          // Check if the image dimensions are within the specified limits
          if (image.width <= 300 && image.height <= 100) {
            setFileName(filenameTempory);
            setFileStore(selectedFile);

            // Read the file if it has been successfully stored in fileStore
            if (selectedFile) {
              const fileReader = new FileReader();
              fileReader.onloadend = () => {
                setFileUpload(fileReader.result.split(",")[1]);
              };
              fileReader.readAsDataURL(selectedFile);
            }
          } else {
            // Display an error if the image dimensions are too large
            toast.error(
              "Please select an image with dimensions less than or equal to 300x100 pixels"
            );
            setError(
              "Please select an image with dimensions less than or equal to 300x100 pixels."
            );
            console.log("Image dimensions are too large");
          }
        };
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setError("Please select a valid image file (PNG, JPG, JPEG, GIF).");
      toast.error("Please select a valid image file (PNG, JPG, JPEG, GIF)");
    }

    // Handle file upload if fileStore exists
    if (fileStore) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUpload(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(fileStore);
    }
  };

  const [selectedFont, setSelectedFont] = useState("font1"); // State to keep track of selected font

  // Function to handle font change
  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };

  // Function to get font family based on selected font
  const getFontFamily = (fontValue) => {
    switch (fontValue) {
      case "font1":
        return "Brush Script MT, cursive";
      case "font2":
        return "Dancing Script, cursive";
      case "font3":
        return "Satisfy, cursive";
      case "font4":
        return "Dancing Script, cursive";
      default:
        return "Brush Script MT, cursive"; // Default font family
    }
  };
  const [signatureText, setSignatureText] = useState("");
  const [font64, setfont64] = useState("");
  // Handler function to update the state when the value of the TextField changes
  const handleInputChange = (event) => {
    setSignatureText(event.target.value);
  };
  // Function to encode font to Base64
  const encodeFontToBase64 = (fontFamily) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = "24px " + fontFamily; // You can adjust the font size as needed
    ctx.fillText(`${signatureText}`, 10, 50); // Dummy text to measure font rendering

    return canvas.toDataURL();
  };
  const handleSaves = async () => {
    const selectedFontFamily = getFontFamily(selectedFont); // Get selected font family
    setfont64(encodeFontToBase64(selectedFontFamily.split(",")[1]));
    let ab64 = encodeFontToBase64(selectedFontFamily);
    var av = ab64.split(",")[1];

    const obj123 = {
      accid: localStorage.getItem("accIdClient"),
      username: localStorage.getItem("EmailClient"),
      sPassword: localStorage.getItem("passwordClient"),
      signature: fileUpload
        ? fileUpload
        : signatureResult
        ? signatureResult
        : av,
      extension: "",
    };

    // save new signature
    if (obj123.signature != "") {
      const response = await dispatch(Upload_Signature(obj123));

      // console.log("Upload_Signature response:", response);
      if (response) {
        // get new signature
        const response_1 = await dispatch(GetSignature_Json_Redux(clientBody));
      } else {
        toast.error("Something went wrong");
      }

      // setCreatesignature("");

      // console.log('Signature result', signatureResult, fileUpload);
      setSignatureSetupVisible(false);
      // setSignaturePreview(true);
      // setSignatureSetup(false);
      // setOpenSignatur(true)
      setSignaturePreview(true);
      setSignatureSetup(false);
      setSignatureSetupVisibleSetting(true);

      setsignatureResult(null);
      setFileUpload("");
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <>
      {signatureSetupVisible ? (
        <React.Fragment>
          <Grid container>
            <Grid item sm={12}>
              <Box className="d-flex align-items-center justify-content-between">
                <Typography
                  className="font-12 mb-1 poppins-semibold text-black"
                  variant="h3"
                >
                  Signature Setup :
                  <Tooltip
                    title="You have the option to either upload an image of your actual signature, use a drawing tool to create a signature or to opt for your name to appear in a specific font. The image captured will appear on any documents you are required to sign and on certificates produced as a result of you approving or disapproving a message."
                    arrow
                    placement="top"
                  >
                    <Button size="small" className="min-width-auto">
                      <InfoIcon
                        className="font-18 text-black"
                        sx={{ background: "transparent" }}
                      />
                    </Button>
                  </Tooltip>
                </Typography>
                <Button
                  onClick={handleSignatureSetup}
                  className="btn-red"
                  variant="contained"
                  size="small"

                  // variant="h3"
                >
                  <CloseIcon />
                  Cancel
                </Button>
              </Box>

              <Box
                sx={{ width: "100%", typography: "body1", marginTop: "20px" }}
                className="custom-tabs"
              >
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="flex-wrap-tabs"
                    >
                      <Tab label="UpLoad" value="1" />
                      <Tab label="Draw" value="2" />
                      <Tab label="Font" value="3" />
                    </TabList>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      marginTop: "10px",
                      background:
                      Number(value) === 1 || Number(value) === 3 ? "#fff" : "#f7f7f7#",
                      height: "110px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TabPanel value="1">
                      {fileName === "" ? (
                        <>
                          <Box className="text-start">
                            <Button
                              component="label"
                              role={undefined}
                              variant="contained"
                              className="btn-blue"
                              size="small"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload file
                              <VisuallyHiddenInput
                                type="file"
                                onChange={selecFiles}
                              />
                            </Button>
                          </Box>
                          {/* <SimpleFileUpload
                            lable={"Selected File"}
                            onchange={selecFiles}
                          /> */}
                          {/* <input type="file"  onChange={selecFiles} /> */}
                        </>
                      ) : (
                        <>
                          <ImageBox
                            component="img"
                            src={`data:image/${format};base64,${fileUpload}`}
                            alt="Base64 Image"
                            sx={{ width: 300, height: 95 }}
                          />
                        </>
                      )}
                    </TabPanel>

                    <TabPanel value="2">
                      {" "}
                      <Box
                        sx={{
                          cursor: `url(${Signature}), auto`,
                          background: "#fff",
                        }}
                      >
                        <div>
                          <SignatureCanvas
                            ref={(ref) => setCreatesignature(ref)}
                            penColor="red"
                            canvasProps={{
                              height: 100,
                              width: 300,
                              className: "sigCanvas",
                            }}
                          />
                        </div>
                      </Box>
                    </TabPanel>

                    <TabPanel value="3">
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={selectedFont}
                            onChange={handleFontChange}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="font1"
                                  control={<Radio />}
                                  label={
                                    <Typography
                                      component={Box}
                                      sx={{
                                        fontFamily: "Brush Script MT, cursive",

                                        color: "red",
                                        padding: "5px",
                                      }}
                                    >
                                      {signatureText
                                        ? signatureText
                                        : "Your Name"}
                                    </Typography>
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="font2"
                                  control={<Radio />}
                                  label={
                                    <Typography
                                      component={Box}
                                      sx={{
                                        fontFamily: "Dancing Script, cursive",
                                        fontSize: "10px",

                                        color: "red",
                                        padding: "5px",
                                      }}
                                    >
                                      {signatureText
                                        ? signatureText
                                        : "Your Name"}
                                    </Typography>
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="font3"
                                  control={<Radio />}
                                  label={
                                    <Typography
                                      component={Box}
                                      sx={{
                                        fontFamily: "Satisfy, cursive",

                                        color: "red",
                                        padding: "5px",
                                      }}
                                    >
                                      {signatureText
                                        ? signatureText
                                        : "Your Name"}
                                    </Typography>
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="font4"
                                  control={<Radio />}
                                  label={
                                    <Typography
                                      component={Box}
                                      sx={{
                                        fontFamily: "Dancing Script, cursive",

                                        color: "red",
                                        padding: "5px",
                                      }}
                                    >
                                      {signatureText
                                        ? signatureText
                                        : "Your Name"}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box>
                  {Number(value) === 3 && (
                    <>
                      <TextField
                        sx={{ marginTop: 2 }}
                        className="w-100"
                        label="Enter Signature Text"
                        id="outlined-size-small"
                        required
                        name="FirstName"
                        size="small"
                        value={signatureText} // Bind the state variable to the TextField
                        onChange={handleInputChange} // Handle changes
                      />
                    </>
                  )}
                </Box>

                {Number(value) === 1 ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "end" }}
                    className="mt-3"
                  >
                    <Button
                      variant="contained"
                      className="btn-blue me-2"
                      size="small"
                      onClick={() => {
                        setFileName("");
                        // clearHandler();
                      }}
                    >
                      Reset{" "}
                    </Button>

                    <Button
                      variant="contained"
                      className="btn-blue"
                      size="small"
                      // onClick={handleSaves}
                      onClick={() => {
                        {
                          fileUpload == ""
                            ? toast.error("Plese Select File before saving")
                            : handleSaves();
                        }
                      }}
                    >
                      Save{" "}
                    </Button>
                  </Box>
                ) : Number(value) === 2 ? (
                  <>
                    <Box
                      sx={{ display: "flex", justifyContent: "end" }}
                      className="mt-3"
                    >
                      <Button
                        variant="contained"
                        className="btn-blue ms-2"
                        size="small"
                        onClick={clearHandler}
                      >
                        Reset{" "}
                      </Button>

                      {confirmVisible && (
                        <>
                          <Button
                            variant="contained"
                            className="btn-blue ms-2"
                            size="small"
                            onClick={saveHandler}
                          >
                            Confirm{" "}
                          </Button>
                        </>
                      )}

                      {saveButtonVisible && (
                        <>
                          <Button
                            variant="contained"
                            className="btn-blue ms-2"
                            size="small"
                            onClick={handleSaves}
                          >
                            Save{" "}
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginTop: "17px",
                        marginLeft: "15px",
                      }}
                      className="btn-blue"
                      onClick={() => {
                        {
                          signatureText == ""
                            ? toast.error(
                                "Plese Add Signature text before saving"
                              )
                            : handleSaves();
                        }
                      }}
                    >
                      Save{" "}
                    </Button>
                  </>
                )}
              </Box>
              <hr />
              <Box>
                {Number(value) === 1 && (
                  <Box className="pt-3">
                    <Typography
                      className="font-12 mb-1 poppins-semibold text-black"
                      variant="h3"
                    >
                      Please Note:
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    ></Typography>

                    <Typography className="font-12 text-black" variant="p">
                      The following limitations will be applied to your image
                      file.
                    </Typography>

                    <List className="pt-2 mb-2 ps-2">
                      {listItmes &&
                        listItmes.map((item, index) => (
                          <ListItem key={index} className="p-0">
                            <ListItemIcon sx={{ minWidth: "26px" }}>
                              <FiberManualRecordIcon
                                className="font-12"
                                sx={{ color: "black" }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              <Box sx={{ display: "flex" }}>
                                <Typography className="font-12 poppins-medium">
                                  {item.id}:
                                </Typography>
                                <Typography className="font-12">
                                  {item.data}
                                </Typography>
                              </Box>
                            </ListItemText>
                          </ListItem>
                        ))}
                    </List>

                    <Typography
                      sx={{ fontSize: "13px", fontWeight: "500" }}
                      variant="p"
                    >
                      Click 'Select Image' to upload an image file, and once it
                      has been uploaded click 'Validate Image' to confirm the
                      image is acceptable.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SignDocument />
        </React.Fragment>
      )}
    </>
  );
}

export default CustomeSignature;
