import { WhatsApp } from "@mui/icons-material";
import axios from "axios";

export const devWebApi = axios.create({
  baseURL: "https://portal.docusoftweb.com/clientservices.asmx",
  timeout: 50000,
});
export const devApi = axios.create({
  baseURL: "https://development.docuengager.com/DSDesktopWebService.asmx/",
  timeout: 50000,
});

export const docuSms = axios.create({
  baseURL: "https://development.docuengager.com/DSDesktopWebService.asmx/",
  timeout: 50000,
});

export const docuSmsClient = axios.create({
  baseURL: "https://development.docuengager.com/DSDesktopWebService.asmx/",
});
export const portalClient = axios.create({
  baseURL: "https://portal.docuengager.com/clientservices.asmx/",
  timeout: 50000,
});
export const portalUser = axios.create({
  baseURL: "https://portal.docuengager.com/portaluserservices.asmx/",
  timeout: 50000,
});
export const practiceTest = axios.create({
  baseURL: "https://development.docuengager.com/DSDesktopWebService.asmx/",
  timeout: 5000000,
});

export const dsdesktopwebservice = axios.create({
  baseURL: "https://development.docuengager.com/DSDesktopWebService.asmx/",
  timeout: 500000,
});

export const docuEngagerClient = axios.create({
  baseURL: "https://portal.docuengager.com/ClientServices.asmx/",
  timeout: 500000,
});
export const WhatsAppservice = axios.create({
  baseURL: "https://digitalpractice.net/whatsAppApis/apis/",
  timeout: 500000,
});

export const calendlyService = axios.create({
  baseURL: "https://digitalpractice.net/calendly/",
  timeout: 500000,
});

export const practiceTestOnlyForTesting = axios.create({
  baseURL: "https://practicetest.docusoftweb.com/PracticeServices.asmx",
  timeout: 500000,
});

export const docuSMSApi = axios.create({
  baseURL: "https://docusms.uk/dswebclientmanager.asmx/",
  timeout: 500000,
});

export const DesktopWebService = axios.create({
  baseURL: "https://docusms.uk/dsdesktopwebservice.asmx/",
  timeout: 500000,
});

export const PracticeServices = axios.create({
  baseURL: "https://docusoftpractice.com/PracticeServices.asmx/",
  timeout: 500000,
});


