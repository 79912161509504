import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import { GetDocumentRequests_Json_Redux } from "../../redux/api_helper";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, TextField } from "@mui/material";
import { NavLink, useSearchParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";

const pageSizes = [10, 25, 50, 100];
export default function AllRequests() {
  let objReq = useLocalStorage("accid");
  const [loading, setLoading] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");

  //Remove Object and add new with replace name
  let newObject = { ...objReq, accId: objReq.accid };
  delete newObject.accid;
  let dispatch = useDispatch();
  const [getAllRequests, setAllRequests] = React.useState([]);
  const [renderData, setRenderData] = React.useState([]);
  const [searchParams] = useSearchParams();
  const emailForFilter = searchParams.get("email");

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      GetDocumentRequests_Json_Redux(newObject, function (res) {
        setLoading(false);
        if (emailForFilter) {
          let filteredData = res.filter(
            (itm) => itm.EmailId === emailForFilter
          );
          setAllRequests(filteredData);
          setRenderData(filteredData);
        } else {
          setAllRequests(res);
          setRenderData(res);
        }
      })
    );
  }, [dispatch, emailForFilter]);

  React.useEffect(() => {
    if (searchString.length > 2 && getAllRequests.length > 0) {
      setLoading(true);
      console.log(getAllRequests, "====getAllRequests");

      let fltData = getAllRequests.filter((d) =>
        d.Request.trim()
          .toLowerCase()
          .includes(searchString.trim().toLowerCase())
      );
      setRenderData(fltData);
      setLoading(false);
    } else {
      setRenderData(getAllRequests);
      setLoading(false);
    }
  }, [searchString]);

  return (
    <Box>
      <Box className="d-flex align-items-center justify-content-between mb-2">
        <h2 className="title">All Request</h2>
        <Box
          className="search-input"
          sx={{
            width: 340,
            maxWidth: "100%",
          }}
        >
          <SearchIcon />
          <TextField
            fullWidth
            label="Search"
            size="small"
            onChange={(e) => setSearchString(e.target.value)}
          />
        </Box>
      </Box>

      <Box className="white-box 000">
        {loading ? (
          <CustomLoader />
        ) : renderData.length > 0 ? (
          <DataGrid
            dataSource={renderData}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            className="table-grid"
          >
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Column dataField="Request" caption="Request" dataType="string" />
            <Column dataField="EmailId" caption="Email" dataType="string" />
            <Column
              dataField="Individual"
              caption="Individual"
              dataType="string"
            />
            <Column
              dataField="DocTempId"
              caption="Action"
              cellRender={(data) => {
                return (
                  <NavLink
                    to={`/user/all-invites?id=${data.data.DocTempId}&request=${data.data.Request}&EmailId=${data.data.EmailId}`}
                  >
                    <Button size="small" className="btn-blue" startIcon={<VisibilityIcon />}>
                      View Detail
                    </Button>
                  </NavLink>
                );
              }}
            />
            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
            <Paging defaultPageSize={10} />
          </DataGrid>
        ) : (
          <NoData messsage="No Data here" />
        )}
      </Box>
    </Box>
  );
}
