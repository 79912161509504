import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "sweetalert2/src/sweetalert2.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchdocuRequestData } from "../../redux/api_helper";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import NoData from "../../components/NoData";
import CustomLoader from "../../components/CustomLoader";
import { setRequestDocumentData } from "../../redux/dev_sonam_slice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RenderRequest from "../../components/RenderRequest";
import { useLocation, useSearchParams } from "react-router-dom";

function DocuRequest() {
  const dispatch = useDispatch();
  let accordionData = useSelector(
    (state) => state.dev_sonam.RequestDocumentData
  );
  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);

  const [searchParams] = useSearchParams();  
  const customerId = searchParams.get("EmailId");  
  const EmailID = searchParams.get("EmailId");  
  const id = searchParams.get("id")
  const request = searchParams.get("request")
  const EmailId = searchParams.get("EmailId")
  const PortalDocId = searchParams.get("PortalDocId")

  const [value, setValue] = React.useState(0);
  const [RecentRequests, setRecentRequests] = useState([]);
  const [allShowDoc, setAllShowDoc] = useState([]);
  const [filterByDoc, setFilterByDoc] = useState(null);

  let { docuRequestDataClient: portalAllRequest, docuRequestDataClientLoding } =
    useSelector((state) => state.docu);

  // useEffect(() => {
  //   if (portalAllRequest) {
  //     const formattedTasks = portalAllRequest.map((task) => {
  //       let timestamp;
  //       if (task.UploadedDate) {
  //         timestamp = parseInt(task.UploadedDate.slice(6, -2));
  //       }

  //       const date = new Date(timestamp);

  //       return { ...task, UploadedDate: date };
  //     });
  //     formattedTasks.sort((a, b) => b.UploadedDate - a.UploadedDate);
  //     setRecentRequests(formattedTasks);
  //   }
  // }, [portalAllRequest]);

  useEffect(() => {
    if (PortalDocId) {
      const newData = portalAllRequestAll.filter((portalReq) => {
        return portalReq.PortalDocId === PortalDocId;
      });
      setAllShowDoc(newData);
    } else{
      setAllShowDoc([])
    }
    // setAllShowDoc(portalAllRequestAll);
  }, [portalAllRequestAll, PortalDocId]);

  // useEffect(() => {
  //   if (filterByDoc !== null) {
  //     const newData = portalAllRequestAll.filter((portalReq) => {
  //       return portalReq.PortalDocId === filterByDoc.PortalDocId;
  //     });
  //     setAllShowDoc(newData);
  //   } else {
  //     setAllShowDoc(portalAllRequestAll);
  //   }
  // }, [filterByDoc]);

  const handleFilterDoc = (item) => {
    setFilterByDoc(item);
  };

  // useEffect(() => {
  //   if (portalAllRequest.length > 0 && portalAllRequestAll.length > 0) {
  //     if (value === 0) {
  //       let filterData = portalAllRequestAll.filter((item) => {
  //         return (
  //           item.TemplateName.toLowerCase() ===
  //           portalAllRequest[0]["TemplateName"].toLowerCase()
  //         );
  //       });

  //       if (filterData && filterData.length > 0) {
  //         dispatch(setRequestDocumentData(filterData));
  //       }
  //     }
  //   }
  // }, [value, portalAllRequest, portalAllRequestAll]);

  useEffect(() => {
    dispatch(fetchdocuRequestData(customerId || EmailID));
  }, [dispatch]);

  // dropdown
  const [anchorElRequests, setAnchorElRequests] = React.useState(null);
  const openRequests = Boolean(anchorElRequests);
  const RequestshandleClick = (event) => {
    setAnchorElRequests(event.currentTarget);
  };
  const RequestshandleClose = (e) => {
    setAnchorElRequests(null);
  };

  return (
    <Box>
      <Box className="container-fluid">
        <Box className="row">
          <Box className="col-md-12 m-auto">
            <Box className="white-box-">
                <h2 className="title mb-0">{allShowDoc.length>0 && allShowDoc[0].TemplateName}</h2>
              {/* <Box className="d-flex align-items-center justify-content-between">

                <div className="ms-auto me-2">
                  <Button
                    id="basic-button"
                    aria-controls={openRequests ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openRequests ? "true" : undefined}
                    onClick={RequestshandleClick}
                    className="btn-blue"
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Requests List
                  </Button>
                  <Menu
                    sx={{ maxHeight: "400px" }}
                    id="basic-menu"
                    anchorEl={anchorElRequests}
                    open={openRequests}
                    onClose={RequestshandleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className="custom-dropdown"
                  >
                    {RecentRequests &&
                      RecentRequests.map((item, index) => (
                        <MenuItem
                          value={value}
                          onClick={() => {
                            RequestshandleClose();
                            handleFilterDoc(item);
                          }}
                          key={index}
                        >
                          <ListItemIcon>
                            <HowToRegIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {item.TemplateName}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                </div>
                {filterByDoc && (
                  <Button
                    id="basic-button"
                    onClick={() => setFilterByDoc(null)}
                    className="btn-blue me-2"
                  >
                    Reset Filter
                  </Button>
                )}
                <Box
                  className="search-input"
                  sx={{
                    width: 340,
                    maxWidth: "100%",
                  }}
                >
                  <SearchIcon />
                  <TextField
                    fullWidth
                    label="Search"
                    size="small"
                    onChange={(e) =>
                      console.log(e.target.value, "=============")
                    }
                  />
                </Box>
              </Box> */}
              <hr />
              {docuRequestDataClientLoding ? (
                <>
                  <CustomLoader />
                </>
              ) : allShowDoc && allShowDoc.length > 0 ? (
                <>
                  <Box value={value} className="">
                    <Grid container spacing={2} className="mt-2">
                      {allShowDoc &&
                        allShowDoc.length > 0 &&
                        allShowDoc.map((accordionItem, accordionIndex) => (
                          <RenderRequest
                            accordionItem={accordionItem}
                            accordionIndex={accordionIndex}
                          />
                        ))}
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <NoData message={"No Data Here"} />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DocuRequest;
