import { Button } from "@mui/material";
import React from "react";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../../redux/dev_sonam_api_helper";
import { fetchdocuRequestData } from "../../redux/api_helper";
import { clientBodyaccIdData } from "../../utils/RequestsFunction";

const UpdateFormFill = ({accordionItem}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const EmailID = searchParams.get("EmailID");

  const dispatch = useDispatch();

  const handleUpdateDoc = () => {
    let uploadobj = {
      ...clientBodyaccIdData,
      docTempId: accordionItem?.DocTempId,
      docID: accordionItem?.DocId,
      statusId: "2",
    };

    dispatch(
      UpdateRequestedDocStatusAction_Json_ReduxClient(
        uploadobj,
        function (res) {
          dispatch(fetchdocuRequestData(EmailID));
        }
      )
    );
  };
  // openFileData(e, accordionItem)
  return (
    <Button
      component="label"
      variant="text"
      startIcon={<MarkEmailReadIcon />}
      className="btn-blue"
      sx={{
        padding: "5px",

        fontSize: "12px",

        marginRight: "10px",
      }}
      onClick={handleUpdateDoc}
    >
      Update Document
    </Button>
  );
};

export default UpdateFormFill;
