import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DraftsIcon from "@mui/icons-material/Drafts";
import DrawIcon from "@mui/icons-material/Draw";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  Badge,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomeSerchBar from "../../components/CustomeSerchBar";
import CustomLoader from "../../components/CustomLoader";
import NoData from "../../components/NoData";
import { Json_Portal_GetClientListByEmail_Redux } from "../../redux/ak_api_helper";
import {
  getPortalUsersInboxNew_Json,
  markMessageAsViewed,
} from "../../redux/dev_chetan_api_helper";
import {
  setApprovalCertificateBase64,
  setPortalUserInboxMessage,
} from "../../redux/dev_chetan_slice";
import { convertToDateFormat } from "../../utils/RequestsFunction";
import CamposeMessage from "./CamposeMessage";

function createData(
  Subject,
  Section,
  ReceivedDate,
  ActionBy,
  Status,
  PortalDocID,
  EmailID,
  ForApproval,
  FileApproved,
  Expired,
  ViewedDate,
  SenderName,
  ExpiryDate
) {
  return {
    Subject,
    Section,
    ReceivedDate,
    ActionBy,
    Status,
    PortalDocID,
    EmailID,
    ForApproval,
    FileApproved,
    Expired,
    ViewedDate,
    SenderName,
    ExpiryDate,
    history: [
      {
        date: "Section",
        customerId: `: ${Section.slice(3)}`,
      },
      {
        date: "Received Data",
        customerId: `: ${ReceivedDate}`,
        // amount: 1,
      },
      {
        date: "Action By",
        customerId: `: ${ActionBy}`,
      },
    ],
  };
}

function Row(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onDoubleClick={() => {
          //console.log("dbl click",row);
          dispatch(markMessageAsViewed(row.PortalDocID));
          dispatch(setApprovalCertificateBase64("undefined"));
          let status =
            row.Expired === "Yes"
              ? "Expired"
              : row.ForApproval === "No"
              ? "For Info"
              : row.FileApproved === null && row.ForApproval === "Yes"
              ? "Pending"
              : row.FileApproved === "Yes" && row.ForApproval === "Yes"
              ? "Approved"
              : row.FileApproved === "Yes" && row.ForApproval === "No"
              ? "Disapproved"
              : "";
          const stateData = {
            PortalDocID: row.PortalDocID,
            Subject: row.Subject,
            Section: row.Section,
            ActionBy: row.ActionBy,
            Status: row.Status,
            ReceivedDate: row.ReceivedDate,
            ExpiryDate: row?.ExpiryDate,
            EmailID: row.EmailID,
            messageStatus: status,
            isClient: true, // Assuming 'isClient' is always true
            senderName: row.SenderName,
          };

          // Use navigate with the state object
          navigate("/client/portalmessage/inboxes/incoming-message-viewer", {
            state: stateData,
          });

          // navigate(
          //   `/client/portalmessage/inboxes/incoming-message-viewer?PortalDocID=${
          //     row.PortalDocID
          //   }&Subject=${row.Subject}&Section=${row.Section}&ActionBy=${
          //     row.ActionBy
          //   }&Status=${row.Status}&ReceivedDate=${row.ReceivedDate}&ExpiryDate=${row?.ExpiryDate}&EmailID=${
          //     row.EmailID
          //   }&messageStatus=${status}&isClient=${"true"}&senderName=${
          //     row.SenderName
          //   }`
          // );
        }}
        className="pointer"
        hover
        aria-label="a dense table"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row.Subject ? row.Subject : "Not Available"}
        </TableCell>
        <TableCell
          align="center"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row?.Section && row?.Section?.includes(".")
            ? row.Section.slice(3).trim()
            : row?.Section || "No Section"}
        </TableCell>
        <TableCell
          align="center"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row.ReceivedDate}
        </TableCell>
        <TableCell
          align="center"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          {row.ActionBy === "Invalid date" ? "Not Assigned" : row.ActionBy}
        </TableCell>
        <TableCell
          align="right"
          className={row.ViewedDate === null ? "poppins-semibold" : ""}
        >
          <Box className="d-flex align-items-center justify-content-end ">
            {/* <Button className="btn-blue">View Detail</Button> */}
            <Chip
              label={
                row.Expired === "Yes"
                  ? "Expired"
                  : row.ForApproval === "No"
                  ? "For Info"
                  : (row.FileApproved === null || row.FileApproved === "No") &&
                    row.ForApproval === "Yes"
                  ? "Pending"
                  : row.FileApproved === "Yes" && row.ForApproval === "Yes"
                  ? "Approved"
                  : row.FileApproved === "Yes" && row.ForApproval === "No"
                  ? "Disapproved"
                  : ""
              }
              size="small"
              className={
                row.Expired === "Yes"
                  ? "chips bg-red"
                  : row.ForApproval === "No"
                  ? "chips bg-primary"
                  : (row.FileApproved === null || row.FileApproved === "No") &&
                    row.ForApproval === "Yes"
                  ? "chips bg-warning"
                  : row.FileApproved === "Yes" && row.ForApproval === "Yes"
                  ? "chips bg-accepted"
                  : row.FileApproved === "Yes" && row.ForApproval === "No"
                  ? "chips bg-red"
                  : ""
              }
            />
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="well-box mt-3">
              <Box className="white-box mb-0">
                <Grid container className="mb-">
                  <Grid item lg={4} xs={4} md={4}>
                    <Box className="table-inbox me-5">
                      <Table aria-label="purchases" size="small">
                        <TableBody>
                          {row.history.map((historyRow) => (
                            <TableRow key={historyRow.date}>
                              <TableCell
                                component="th"
                                scope="row"
                                className="poppins-semibold"
                              >
                                {historyRow.date}
                              </TableCell>
                              <TableCell>{historyRow.customerId}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    xs={8}
                    md={8}
                    className="align-items-center"
                  >
                    <Box className="contact-detail-row mb-0 mt-1">
                      <Box className="contact-detail-box d-flex text-left">
                        <DraftsIcon className="me-2 mb-0" />
                        <Box>
                          <Typography
                            variant="body1"
                            className="poppins-semibold"
                          >
                            Message Viewed
                          </Typography>
                          <Typography variant="body1" className="font-12">
                            {convertToDateFormat(row?.ViewedDate)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="contact-detail-box d-flex text-left">
                        {/* <img src={OrderApprove} className="d-block m-auto" /> */}
                        <MarkEmailReadIcon className="me-2 mb-0" />
                        <Box>
                          <Typography
                            sx={{}}
                            variant="body1"
                            className="poppins-semibold"
                          >
                            Approval
                          </Typography>
                          <Typography
                            sx={{}}
                            variant="body1"
                            className="font-12"
                          >
                            Requested
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="contact-detail-box d-flex text-left">
                        {/* <img src={Signature} className="d-block m-auto" /> */}
                        <DrawIcon className="me-2 mb-0" />

                        <Box>
                          <Typography
                            sx={{}}
                            variant="body1"
                            className="poppins-semibold"
                          >
                            Signature
                          </Typography>
                          <Typography
                            sx={{}}
                            variant="body1"
                            className="font-12"
                          >
                            Requested
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PortalInbox() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(Json_Portal_GetClientListByEmail_Redux());
  }, []);
  const [searchInput, setSearchInput] = React.useState("");
  const [filterCriteria, setFilterCriteria] = React.useState({});
  const {
    portalUserInboxMessage,
    allPortalUserInboxMessage,
    isInboxMessageLoading,
  } = useSelector((state) => state.dev_chetan);

  let sectionList = [];
  allPortalUserInboxMessage.map((itm) => {
    if (!sectionList.includes(itm?.ItemName)) {
      sectionList.push(itm?.ItemName);
    }
  });
  const unreadMessages = allPortalUserInboxMessage.filter(
    (itm) => itm.ViewedDate === null
  );
  const [openComposeMessageBox, setOpenComposeMessageBox] =
    React.useState(false);

  React.useEffect(() => {
    if (
      portalUserInboxMessage.length === 0 &&
      Boolean(localStorage.getItem("LoginClient")) === false
    ) {
      // dispatch(getPortalUserInboxMessages());
    } else {
      dispatch(getPortalUsersInboxNew_Json());
    }
  }, [dispatch]);

  React.useEffect(() => {
    const obj1 = {
      accId: localStorage.getItem("accIdClient")
        ? localStorage.getItem("accIdClient")
        : "",
      username: localStorage.getItem("EmailClient")
        ? localStorage.getItem("EmailClient")
        : "",
      sPassword: localStorage.getItem("passwordClient")
        ? localStorage.getItem("passwordClient")
        : "",
    };

    // dispatch(GetPortalContactDetailsByEmail(obj1));
  }, []);

  let allInboxMessages = [];
  if (portalUserInboxMessage.length > 0) {
    allInboxMessages = portalUserInboxMessage.map((itm) => {
      return createData(
        itm.Subject,
        itm.ItemName,
        moment(itm.ADate).format("DD/MM/YYYY"),
        moment(itm.ActionDate).format("DD/MM/YYYY"),
        itm.ForApproval,
        itm.PortalDocID,
        itm.EmailID,
        itm.ForApproval,
        itm.FileApproved,
        itm.Expired,
        itm.ViewedDate,
        itm.CName,
        moment(itm.ExpiryDate).format("DD/MM/YYYY")
      );
    });
  }

  const [currentPage, setCurrentPage] = React.useState(1);
  const handleChange = (e, value) => {
    setCurrentPage(value);
  };
  let itemsPerPage = 10;
  // Calculate the total number of pages
  const totalPages = Math.ceil(allInboxMessages.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allInboxMessages.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleInboxMessageSearch = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Only apply the filter if the input length is at least 3 characters
    if (inputValue.length >= 3) {
      setFilterCriteria((prev) => ({
        ...prev,
        Subject: [inputValue],
      }));
    } else {
      // Reset filter if input length is less than 3
      setFilterCriteria((prev) => ({
        ...prev,
        Subject: [],
      }));
    }
  };

  const handleDateRangePicker = (date) => {
    if (
      date.startDate === "clear" &&
      Object.keys(filterCriteria).includes("ADate")
    ) {
      let temp = { ...filterCriteria };
      delete temp["ADate"];
      setFilterCriteria(temp);
      return;
    }
    setFilterCriteria({
      ...filterCriteria,
      ADate: [date.startDate, date.endDate],
    });
  };
  // console.log('test format date object', moment(portalUserInboxMessage[0].ADate)._d);

  React.useMemo(() => {
    if (Object.keys(filterCriteria).length > 0) {
      let fltData = allPortalUserInboxMessage.filter(function (obj) {
        return Object.keys(filterCriteria).every(function (key) {
          if (key === "ADate") {
            if (
              moment(obj.ADate)._d >= filterCriteria["ADate"][0] &&
              moment(obj.ADate)._d <= filterCriteria["ADate"][1]
            ) {
              return true;
            }
          } else {
            return (
              (Array.isArray(filterCriteria[key]) &&
                filterCriteria[key].some(function (criteria) {
                  return String(obj[key])
                    .toLowerCase()
                    .includes(String(criteria.toLowerCase()));
                })) ||
              filterCriteria[key].length === 0
            );
          }
        });
      });
      dispatch(setPortalUserInboxMessage(fltData));
    } else {
      if (allPortalUserInboxMessage.length > 0)
        dispatch(setPortalUserInboxMessage(allPortalUserInboxMessage));
    }
  }, [filterCriteria]);

  // console.log('allPortalUserInboxMessage', allPortalUserInboxMessage);
  const [section, setSection] = React.useState("Section");

  const handleSectionChange = (event) => {
    let value = event.target.value;
    if (value === "Clear Filters") {
      setSection("Section");
      let tempFilters = { ...filterCriteria };
      delete tempFilters.ItemName;
      setFilterCriteria({ ...tempFilters });
      return;
    }
    setFilterCriteria({ ...filterCriteria, ItemName: [value] });
    setSection(value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDescending = (target) => {
    switch (target) {
      case "Subject":
        const sortedData = [...portalUserInboxMessage].sort((a, b) =>
          b.Subject.localeCompare(a.Subject)
        );
        dispatch(setPortalUserInboxMessage(sortedData));
        return;
      case "Received Date":
        const sortByRecDate = [...portalUserInboxMessage].sort(
          (a, b) => moment(a.ADate).toDate() - moment(b.ADate).toDate()
        );
        dispatch(setPortalUserInboxMessage(sortByRecDate));
        return;
      default:
        return [];
    }
  };
  const handleAscending = (target) => {
    switch (target) {
      case "Subject":
        const sortedData = [...portalUserInboxMessage].sort((a, b) =>
          a.Subject.localeCompare(b.Subject)
        );
        dispatch(setPortalUserInboxMessage(sortedData));
        return;
      case "Received Date":
        const sortByRecDate = [...portalUserInboxMessage].sort(
          (a, b) => moment(b.ADate).toDate() - moment(a.ADate).toDate()
        );
        dispatch(setPortalUserInboxMessage(sortByRecDate));
        return;
      default:
        return [];
    }
  };
  const handleSorting = (e, target) => {
    let boolean = e.target.checked;
    if (boolean) {
      handleDescending(target);
    } else {
      handleAscending(target);
    }
  };

  return (
    <Box className="white-box">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2 className="title">
          Inbox{" "}
          <Badge
            badgeContent={unreadMessages && unreadMessages.length}
            color="primary"
          >
            <MailIcon color="action" />
          </Badge>
        </h2>
      </Box>

      <Box className="d-flex justify-content-between flex-wrap">
        <Box className="d-flex align-items-center">
          <CustomeSerchBar
            className="mb-2 "
            value={searchInput}
            onChange={handleInboxMessageSearch}
          />
        </Box>
        <Box
          sx={{
            marginLeft: "25px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ marginRight: "10px", marginTop: "10px" }}>
            <FormatListBulletedIcon
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Typography component={"h3"} sx={{ m: 2 }}>
                Filter by:
              </Typography>
              <Stack direction="row" spacing={2} style={{ margin: "0px 15px" }}>
                <Box className="">
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={section}
                      onChange={handleSectionChange}
                    >
                      <MenuItem value="Section" style={{ display: "none" }}>
                        Section
                      </MenuItem>
                      <MenuItem value="Clear Filters">Clear Filters</MenuItem>
                      {sectionList.length > 0 &&
                        sectionList.map((itm, i) => {
                          return (
                            <MenuItem key={i} value={itm}>
                              {itm.slice(3)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
                <Box className="">
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={section}
                      onChange={handleSectionChange}
                    >
                      <MenuItem value="Section" style={{ display: "none" }}>
                        Section
                      </MenuItem>
                      <MenuItem value="Clear Filters">Clear Filters</MenuItem>
                      {sectionList.length > 0 &&
                        sectionList.map((itm, i) => {
                          return (
                            <MenuItem key={i} value={itm}>
                              {itm.slice(3)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            </Menu>
          </Box>
          <Box className="mb-2 d-flex">
            <CustomDateRangePicker
              strText={"Filter By Date"}
              tooltipTlt={"Recieved Date"}
              onChange={() => {}}
              setDateObj={handleDateRangePicker}
            />
            {/* <IconButton>
              <RefreshIcon />
            </IconButton> */}
          </Box>
        </Box>
      </Box>

      <hr />

      {isInboxMessageLoading ? (
        <CustomLoader />
      ) : allInboxMessages.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", maxHeight: "calc(100vh - 360px)" }}
          >
            <Table
              // aria-label="collapsible table"
              size="small"
              className="custom-table"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className="poppins-semibold">
                    Subject
                    <Checkbox
                      {...label}
                      icon={<ArrowUpwardIcon />}
                      checkedIcon={<ArrowDownwardIcon />}
                      onChange={(e) => handleSorting(e, "Subject")}
                    />
                  </TableCell>
                  <TableCell
                    className="poppins-semibold"
                    align="center"
                    onClick={() => handleDescending("Section")}
                  >
                    Section
                  </TableCell>
                  <TableCell className="poppins-semibold" align="center">
                    Received Date
                    <Checkbox
                      {...label}
                      icon={<ArrowUpwardIcon />}
                      checkedIcon={<ArrowDownwardIcon />}
                      onChange={(e) => handleSorting(e, "Received Date")}
                    />
                  </TableCell>
                  <TableCell className="poppins-semibold" align="center">
                    {" "}
                    Action By
                  </TableCell>

                  <TableCell className="poppins-semibold" align="right">
                    <Box className="pe-4">Status</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.length > 0 ? (
                  currentItems.map((row, i) => <Row row={row} key={i} />)
                ) : (
                  <NoData message={"No Match Found"} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <NoData message="No Message here" />
      )}

      <Box className="mt-3">
        {/* <PaginationComponent /> */}
        <Pagination
          count={totalPages}
          page={currentPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Box>

      <Box className="compose-messages">
        {openComposeMessageBox && (
          <CamposeMessage setOpenComposeMessageBox={setOpenComposeMessageBox} />
        )}
      </Box>
    </Box>
  );
}
