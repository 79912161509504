import React, { memo, useEffect, useState } from "react";
import { Drawer, DrawerHeader } from "../style-utils/sidebarNavUtils";
import {
  IconButton,
  Divider,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/images/logo.svg";
import logoIcon from "../assets/images/docu-icon-white.svg";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { NavLink } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MessageIcon from "@mui/icons-material/Message";
import PersonIcon from "@mui/icons-material/Person";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SettingsIcon from "@mui/icons-material/Settings";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  setAllPortalUserInboxMessage,
  setInboxMessageLoading,
  setPortalUserInboxMessage,
} from "../redux/dev_chetan_slice";
import { Width } from "devextreme-react/cjs/chart";

const userTabs = [
  // {
  //   tabLink: "/user/dashboard",
  //   tabName: "Dashboard",
  //   icon: <DashboardIcon />,
  //   subTab: [],
  // },
  {
    tabLink: "/user/portal-messages",
    tabName: "Portal Messages",
    icon: <InboxIcon />,
    subTab: [],
  },

  {
    tabName: "Request",
    icon: <MarkEmailReadIcon />,
    subTab: [
      {
        tabLink: "/user/all-requests",
        tabName: "All Requests",
        icon: <RequestQuoteIcon />,
        subTab: [],
      },
      {
        tabLink: "/user/templates",
        tabName: "Request Templates",
        icon: <MailIcon />,
        subTab: [],
      },

    ],
  },

  {
    tabLink: "/user/share-box",
    tabName: "Share Box",
    icon: <DashboardIcon />,
    subTab: [],
  },

  {
    tabName: "Contact",
    icon: <ContactPageIcon />,
    subTab: [
      {
        tabLink: "/user/contact/contacts",
        tabName: "Contacts",
        icon: <PersonIcon />,
      },
      {
        tabLink: "/user/contact/companies",
        tabName: "Companies",
        icon: <MailIcon />,
      },
    ],
  },

  // {
  //   tabName: "Settings",
  //   icon: <SettingsIcon />,
  //   subTab: [
  //     {
  //       tabLink: "/user/settings/account",
  //       tabName: " Account",
  //       icon: <InboxIcon />,
  //     },
  //     {
  //       tabLink: "/user/settings/profile",
  //       tabName: "Profile",
  //       icon: <MailIcon />,
  //     },
  //     {
  //       tabLink: "/user/settings/preferences",
  //       tabName: "Preferences",
  //       icon: <MailIcon />,
  //     },
  //     {
  //       tabLink: "/user/settings/plan-and-billing",
  //       tabName: "Plans & Billing",
  //       icon: <MailIcon />,
  //     },
  //     { tabName: "Integrations", icon: <MailIcon /> },
  //     {
  //       tabLink: "/user/settings/widget",
  //       tabName: "Widget",
  //       icon: <MailIcon />,
  //     },
  //     {
  //       tabLink: "/user/settings/branding",
  //       tabName: "Branding",
  //       icon: <MailIcon />,
  //     },
  //     { tabLink: "/user/settings/help", tabName: "Help", icon: <MailIcon /> },
  //   ],
  // },

  {
    tabLink: "/user/logout",
    tabName: "Logout",
    icon: <ExitToAppIcon />,
    subTab: [],
  },
];

const clientTabs = [
  // {
  //   tabLink: "/client/clientdashboard",
  //   tabName: "Dashboard",
  //   icon: <DashboardIcon />,
  //   subTab: [],
  // },

  {
    tabName: "Portal Message",
    icon: <InboxIcon />,
    subTab: [
      {
        tabLink: "/client/portalmessage/inboxes",
        tabName: "Inbox",
        icon: <MailIcon />,
        subTab: [],
      },
      {
        tabLink: "/client/portalmessage/sendinboxes",
        tabName: "Sent",
        icon: <MailIcon />,
        subTab: [],
      },
    ],
  },
  {
    tabLink: "/client/requests",
    tabName: "Requests",
    icon: <MarkEmailReadIcon />,
    subTab: [
      // { tabLink: '/client/requests/aml-11111', tabName: 'Aml 11111', icon: <InboxIcon /> },
      // { tabLink: '/client/requests/nurse-rgn', tabName: 'Nurse RGN', icon: <MailIcon /> },
      // { tabLink: '/client/requests/aml-check', tabName: 'Aml Check', icon: <MailIcon /> },
      // { tabLink: '/client/requests/greenfield-test', tabName: 'GreenField Test', icon: <MailIcon /> },
    ],
  },
  {
    tabLink: "/client/clientsharebox",
    tabName: "Share Box",
    icon: <DashboardIcon />,
    subTab: [],
  },
  {
    tabLink: "/client/logout",
    tabName: "Logout",
    icon: <ExitToAppIcon />,
    subTab: [],
    // onclick: handleclose
  },
];

// const handleclose = () =>{
//   localStorage.clear();
// }

function CustomDrawer({ open, handleDrawerToggle }) {
  const [tabs, setTabs] = useState(clientTabs);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(null);
  const [isAlreadyExpand, setIsAlreadyExpand] = useState(true);
  useEffect(() => {
    if (window.location.pathname.includes("/user")) {
      setTabs(userTabs);
    } else {
      setIsExpand(0);
      setIsAlreadyExpand({ 0: true });
    }
  }, []);
  const handleExpand = (index) => {
    setIsExpand(index);
  };
  const handleClick = (index, tab) => {
    if (tab.subTab.length > 0) {
      setIsAlreadyExpand({ [index]: isAlreadyExpand[index] ? false : true });
      handleExpand(index);
      return;
    }
    setIsExpand(null);
    setIsAlreadyExpand({});
    return;
  };

  return (
    <Drawer variant="permanent" className="left-sidebar" open={open}>
      <DrawerHeader className="d-none">
        <IconButton onClick={handleDrawerToggle}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />

      <Box className="text-center">
        <a href="#" className="logo">
          <img src={logo} className="d-none d-md-block" />
          <img src={logoIcon} className="logo-icon d-block d-md-none" />
        </a>
      </Box>

      <List className="navi">
        {tabs.map((tab, index) => (
          <div key={tab.tabName}>
            <NavLink
              to={tab.tabLink ? tab.tabLink : "#"}
              className="navi-list"
              onClick={() => {
                if (tab.tabName === "Logout") {
                  // localStorage.clear();
                  dispatch(setPortalUserInboxMessage([]));
                  dispatch(setAllPortalUserInboxMessage([]));
                  dispatch(setInboxMessageLoading(true));
                }
              }}
            >
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className="inner-navi-list text-decoration-none"
              >
                <ListItemButton
                  sx={{
                    minHeight: 40,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleClick(index, tab)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#555f6d",
                    }}
                  >
                    {tab.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={tab.tabName}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {tab.subTab.length > 0 &&
                  isAlreadyExpand[index] &&
                  isExpand === index ? (
                    <ExpandLess className="text-white arrow-icon" />
                  ) : (
                    tab.subTab.length > 0 && (
                      <ExpandMore className="text-white arrow-icon" />
                    )
                  )}
                </ListItemButton>
              </ListItem>
            </NavLink>

            {tab.subTab.length > 0 && (
              <Collapse
                in={isAlreadyExpand[index] && isExpand === index}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding className="submenu">
                  {tab.subTab.map((sub) => (
                    <ListItemButton
                      key={sub.tabName}
                      sx={{ pl: 5 }}
                      component={NavLink}
                      to={sub.tabLink ? sub.tabLink : "#"}
                    >
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <FiberManualRecordIcon className="text-gray font-12 " />
                      </ListItemIcon>
                      <ListItemText primary={sub.tabName} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
    </Drawer>
  );
}

export default memo(CustomDrawer);
