import { Box, Button, styled } from "@mui/material";

import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageIcon from "@mui/icons-material/Message";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import {
  DeleteRequestedDocumentFromClient_Json,
  DocusoftRequestedDocument_Json,
  PortalUsersRequestedDocs2_Json_Redux,
  RenewRequestedDocument_Json_Redux,
  RequestedDocumentCreated_Json_Redux,
  UpdateRequestedDocStatusAction_Json_Requested,
  fetchdocuRequestData,
  sonamConfirmMessage,
} from "../../../redux/api_helper";
import UploadRequestDoc from "../../../components/RequestComponant/UploadRequestDoc";
import { useState } from "react";
import { SendReminderUserAction } from "../../../redux/dev_aditya_action";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../../../redux/dev_sonam_api_helper";
import { useLocation } from "react-router-dom";

const UserRequestButtons = ({ data, accordionIndex, id }) => {
  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);
  // const customerId = searchParams.get("customerId");
  // const EmailID = searchParams.get("EmailID");
  const {customerId,EmailID} = location?.state  || {}

  let objReq = useLocalStorage("accid");
  const [preview, setPreview] = useState();
  const userBody = {
    accId: localStorage.getItem("accid"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
    emailid: customerId,
  };

  const dispatch = useDispatch();

  let newObject = {
    ...objReq,
    accId: objReq.accid,
    username: objReq.EmailId,
    sPassword: id,
    filterBy: "All",
  };
  delete newObject.accid;

  const sendRenewRequestedDocument = (data) => {
    dispatch(
      sonamConfirmMessage(
        "Are you sure you want send to Renewal Document",
        function (res) {
          if (res) {
            let renealObj = {
              accId: objReq.accid,
              email: objReq.email,
              password: objReq.password,
              emailid: data.EmailId,
              templateID: data.DocTempId,
              docid: data.DocId,
            };
            dispatch(
              RenewRequestedDocument_Json_Redux(renealObj, function (res) {
                if (res) {
                  // Show success message
                  toast.success("Renewal requested document Successfully.");
                } else {
                  // Show error message
                  toast.error("Something wrong to renewal requested document.");
                }
              })
            );
          }
        }
      )
    );
  };

  const docuSoftDocument = (data) => {
    let moveDocuObject = {
      accId: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      emailID: data.EmailId,
      docTempId: data.DocTempId,
      docID: data.DocId,
      folderid: data.Folder,
    };
    dispatch(
      DocusoftRequestedDocument_Json(moveDocuObject, function (res) {
        if (res) {
          // Show success message
          toast.success("Document DocuSofted successfully!");
        } else {
          // Show error message
          toast.error("something wrong.");
        }
      })
    );
  };

  const removeDocument = (data) => {
    dispatch(
      sonamConfirmMessage(
        "Are you sure you want to remove this document",
        function (res) {
          if (res) {
            let removeObj = {
              accId: objReq.accid,
              email: objReq.email,
              password: objReq.password,
              emailid: data.EmailId,
              templateID: data.DocTempId,
              docid: data.DocId,
            };
            dispatch(
              DeleteRequestedDocumentFromClient_Json(removeObj, function (res) {
                if (res) {
                  // Show success message
                  dispatch(fetchdocuRequestData(customerId));
                  toast.success("Removed : Successfully.");
                } else {
                  // Show error message
                  toast.error("Removed : Something wrong.");
                }
              })
            );
          }
        }
      )
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (e, data) => {
    const files = e.target.files;
    const file = files[0]; // Only allow one file to be selected

    if (!file) {
      return;
    }
    if (file) {
      dispatch(
        sonamConfirmMessage(
          "Are you sure you want to upload this file",
          async function (res) {
            if (res) {
              let obj = {
                accId: objReq.accid,
                email: objReq.email,
                password: objReq.password,
                emailID: data.EmailId,
                docTempId: data.DocTempId,
                docID: data.DocId,
              };
              // Create a FormData object
              const formData = new FormData();
              formData.append("file", file);
              formData.append("fileName", file.name);
              formData.append("fileExtension", file.name.split(".").pop());
              // Convert file to Base64
              const base64File = await fileToBase64(file);
              formData.append("fileBase64", base64File);

              let newFilesObject = {
                ...obj,
                DocExtension: "." + file.name.split(".").pop(),
                attachment: base64File,
              };
              dispatch(
                RequestedDocumentCreated_Json_Redux(
                  newFilesObject,
                  function (res) {
                    if (res) {
                      //Update status ===
                      //Updated Status for request docu status added by 19 jun
                      let updateDocuObject = {
                        accId: objReq.accid,
                        email: objReq.email,
                        password: objReq.password,
                        emailid: data.EmailId,
                        sPassword: "0",
                        docTempId: data.DocTempId,
                        docID: data.DocId,
                        statusId: 3,
                      };
                      dispatch(
                        UpdateRequestedDocStatusAction_Json_Requested(
                          updateDocuObject,
                          function (response) {
                            console.log(
                              "Status Updated for Upload Requested : ",
                              response
                            );
                          }
                        )
                      );
                      //ENd : Updated Status for request docu status added by 19 jun
                      // Show success message
                      dispatch(
                        PortalUsersRequestedDocs2_Json_Redux(
                          newObject,
                          function (res) {}
                        )
                      );
                      toast.success("Upload : Successfully.");
                    } else {
                      toast.error("Upload : something wrong.");
                    }
                  }
                )
              );
              //refresh data
              // setTimeout(() => {
              //   dispatch(
              //     PortalUsersRequestedDocs2_Json_Redux(
              //       newObject,
              //       function (res) {}
              //     )
              //   );
              // }, "1000");
            }
          }
        )
      );
      //return;
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSendNewButton = () => {
    let uploadobj = {
      ...userBody,
      sPassword: "0",
      docTempId: data?.DocTempId,
      docID: data?.DocId,
      statusId: "2",
    };

    dispatch(
      UpdateRequestedDocStatusAction_Json_ReduxClient(
        uploadobj,
        function (res) {
          dispatch(fetchdocuRequestData(customerId));
        }
      )
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        // border: "1px solid red",
      }}
      className="mt-4"
    >
      {/* new conditoin added for docustatus */}
      {Number(data?.RequestDocStatus) === 6 ||
      Number(data?.RequestDocStatus) == 7 ? (
        <Button
          variant="contained"
          className="btn-blue ms-2"
          size="small"
          onClick={() => sendRenewRequestedDocument(data)}
          startIcon={<MessageIcon />}
        >
          Send Renewal Email
        </Button>
      ) : (
        ""
      )}

      {[3, 4, 5, 6, 7].includes(Number(data.RequestDocStatus)) ? (
        <Button
          variant="contained"
          className="btn-blue ms-2"
          size="small"
          onClick={() => docuSoftDocument(data)}
          startIcon={<SnippetFolderIcon />}
        >
          Docusoft
        </Button>
      ) : (
        ""
      )}

      {(Number(data.Status) === 0 && Number(data?.RequestDocStatus) === 4) ||
      (Number(data.Status) >= 1 &&
        Number(data.Status) <= 3 &&
        [4, 5, 6, 7].includes(Number(data.RequestDocStatus))) ? (
        <Button
          variant="contained"
          className="btn-blue ms-2"
          size="small"
          onClick={handleSendNewButton}
          startIcon={<SnippetFolderIcon />}
        >
          Send New
        </Button>
      ) : (
        ""
      )}

      {/* <UploadFileModal /> */}
      {/* { New conditions added by 6 jun} */}
      {Number(data.Status) == 0 &&
      [2, 5, 6, 7].includes(Number(data.RequestDocStatus)) ? (
        // <Button
        //   className="btn-blue ms-2"
        //   component="label"
        //   role={undefined}
        //   variant="contained"
        //   tabIndex={-1}
        //   startIcon={<CloudUploadIcon />}
        //   size="small"
        //   // onClick={() => dispatch(setOpenUploadDocument({ data: data, isOpen: true }))}
        // >
        //   Upload Document
        //   <VisuallyHiddenInput
        //     type="file"
        //     onChange={(e) => handleFileChange(e, data)}
        //   />
        // </Button>
        <UploadRequestDoc accordionItem={data} setPreview={setPreview} />
      ) : (
        ""
      )}

      {Number(data?.RequestDocStatus) === 2 &&
      [1, 2, 3].includes(data.Status) ? (
        <Button
          variant="contained"
          className="btn-blue ms-2"
          size="small"
          onClick={() =>
            dispatch(
              SendReminderUserAction({
                messageID: data?.PortalMsgId,
                contactEmail: data.EmailId,
              })
            )
          }
          startIcon={<SnippetFolderIcon />}
        >
          Send Reminder
        </Button>
      ) : (
        ""
      )}

      <Button
        variant="contained"
        className="btn-red ms-2"
        size="small"
        onClick={() => removeDocument(data)}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
    </Box>
  );
};

export default UserRequestButtons;
