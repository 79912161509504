import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { default as animation, default as logo } from "../../assets/images/login.svg";
import DocuTextField from "../../cmp-utils/DocuTextField";
import Copyright from "../../components/Copyright";
import { portalUser } from "../../redux/AxiosInstance";

const ClientLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const Code = searchParams.get("Code");

  const [clientLogo, setClientLogo] = useState(
    `https://portal.docuengager.com/ClientArea/${Code}/logo.png`
  );
  const [imageExists, setImageExists] = useState(null);
  const [formData, setFormData] = useState({
    Email: "",
    password: "",
  });

  useEffect(() => {
    if (Code) {
      const img = new Image();
      img.src = clientLogo;
      img.onload = () => setImageExists(true);
      img.onerror = () => setImageExists(false);
    }
  }, [Code]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // console.log('data', name, value, formData);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      accId: Code ? Code : "0003",
      email: formData.Email,
      password: btoa(formData.password),
    };
    LoginDetail(obj);
  };
  async function LoginDetail(obj) {
    // console.log(obj,"data")
    try {
      const res = await portalUser.post("/PortalUserLoginNew_Json", obj);
      // console.log(res.data.d,"sonamfff")
      if (res.data.d === true) {
        // console.log(res.data.d,"11111")
        localStorage.clear();
        localStorage.setItem("LoginClient", true);
        localStorage.setItem("accIdClient", obj.accId);
        localStorage.setItem("EmailClient", obj.email);
        localStorage.setItem("passwordClient", obj.password);
        navigate("/client/portalmessage/inboxes");
      } else {
        toast.error("Invalid Credentials!!");
      }
    } catch (err) {
      console.log("Error while calling Json_GetAgreementList", err);
    }
  }
  const [openForgotPassord, setOpenForgotPassord] = React.useState(false);
  const handleClickOpenForgotPassord = () => {
    setOpenForgotPassord(true);
  };
  const handleCloseForgotPassord = () => {
    setOpenForgotPassord(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  // useEffect(() => {
  //     localStorage.clear();
  // },[])

  return (
    <Box>
      <Grid container spacing={2} className="mt-0">
        <Grid
          item
          xs={6}
          md={6}
          className="d-flex align-items-center flex-column"
        >
          <Box className="login-head">
            {imageExists ? (
              <img src={clientLogo} alt="" srcset="" loading="lazy" className="w-100"/>
            ) : (
              <img src={logo} loading="lazy" className="w-100"/>
            )}
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            noValidate           
          >
            <Box className="left-side-login">
              <Box className="inner-left-side-bar mt-auto">
                <Typography variant="h2" className="mb-3">
                  Login to your account
                </Typography>
                <DocuTextField
                  type={"text"}
                  fieldLabel={"Email Address"}
                  fieldName={"Email"}
                  fieldValue={formData.Email}
                  onChange={handleInputChange}
                />
                <DocuTextField
                  type={"password"}
                  fieldLabel={"Password"}
                  fieldName={"password"}
                  fieldValue={formData.password}
                  onChange={handleInputChange}
                />

                <Box className="d-flex align-items-center justify-content-between mb-2">
                  <FormControlLabel
                    className="font-12"
                    control={
                      <Checkbox value="remember" className="text-blue" />
                    }
                    label="Remember me"
                  />
                  <Link
                    onClick={handleClickOpenForgotPassord}
                    variant="body2"
                    className="pointer font-12 text-decoration-none text-black"
                  >
                    Forgot password?
                  </Link>
                </Box>

                <Button type="submit" fullWidth className="btn-blue">
                  Sign In
                </Button>
              </Box>
              <Box className="mt-auto">
                <Copyright sx={{ mt: 2, mb: 2 }} />
                <Box className="text-center">
                  <Link
                    href="https://www.docusoft.net/terms/"
                    target="_blank"
                    className="text-blue sembold px-3"
                  >
                    Terms & Condition
                  </Link>
                  <Link
                    href="https://www.docusoft.net/privacy-policy/"
                    target="_blank"
                    className="text-blue sembold px-3"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    href="#"
                    className="text-blue sembold px-3"
                    target="_blank"
                  >
                    Help
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} className="right-side-login">
          <Box className="text-center">
            <img src={animation} className="d-block img-fluid" />
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openForgotPassord}
        onClose={handleCloseForgotPassord}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="dropdown-box">
            <Typography variant="h4" className="font-18 bold text-black">
              Fogot Password?
            </Typography>
          </Box>
          {/*  */}
          <Button onClick={handleCloseForgotPassord} className="min-width-auto">
            <span className="material-symbols-outlined text-black">cancel</span>
          </Button>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className="text-center mb-4">
              <img src={logo} width={160} />
            </Box>

            <div class="alert alert-success" role="alert">
              <p className="font-12 mb-0">
                Enter your email and instructions will be sent to you!
              </p>
            </div>

            <Box className="d-fle mb-4">
              <TextField
                label="Email"
                variant="outlined"
                className="w-100 mb-3 form-control"
              />
              <Button
                variant="contained"
                size="small"
                className="btn-blue w-100"
              >
                Send Reset Link
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ClientLogin;