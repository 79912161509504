import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import QRCode from "qrcode.react";
import DocuTextField from "../../../cmp-utils/DocuTextField";
import AutoComplete from "../../../components/AutoComplete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
function UserSetting({ companyData }) {
  console.log("Company Data in UserSetting", companyData);
  const [authenticationVisible, setAuthenticationVisible] = useState(false);

  const handleScanner = () => {
    setAuthenticationVisible(!authenticationVisible);
  };

  return (
    <Box className="white-box">
      <Grid container rowSpacing={0} columnSpacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Box className=" mt-2">
            <AutoComplete lable="Default Folder"></AutoComplete>
          </Box>

          <Box className=" mt-3">
            <AutoComplete lable="Default Section"></AutoComplete>
          </Box>

          <Box className=" mt-2">
            <LocalizationProvider dateAdapter={AdapterDayjs} className="">
              <DemoContainer
                components={[
                  "DatePicker",
                  "TimePicker",
                  "DateTimePicker",
                  "DateRangePicker",
                ]}
              >
                <DatePicker dateFormat="DD/MM/YYYY" className="custom-date-2" />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Box>
            <Typography className="font-18 poppins-medium" variant="Body2">
              Two Factor Authentication
            </Typography>

            <Box className="clearfix mt-1">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={authenticationVisible}
                    onChange={handleScanner}
                  />
                }
                label="Enable"
                sx={{ marginTop: "-10px" }}
              />

              {authenticationVisible && (
                <>
                  <Grid container rowSpacing={0} columnSpacing={1}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Box>
                        {/* <Typography className="font-12 mb-2">
                          <span className="poppins-semibold">Step 1:</span> Scan
                          the QR code on your Google
                          <br /> Authenticator app
                        </Typography> */}
                        <QRCode value="https://example.com" size={100} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Box>
                        {/* <Typography className="font-12">
                          <span className="poppins-semibold">Step 2:</span>{" "}
                          Enter your Google Authenticator Pin
                        </Typography> */}

                        <Box className="">
                          <DocuTextField
                            label={"Enter Pin"}
                            size="small"
                            fullWith
                          />

                          <Box className=" d-flex justify-content-end">
                            <Button
                              // startIcon={<CheckIcon />}
                              variant="contained"
                              size="small"
                              className="btn-blue mt-1 ms-0 px-2"
                              // onClick={handleSignatureSetting}
                            >
                              Save
                              {/* <span className="d-inline-block px-2"></span> */}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}

              <Box className=" mt-2">
                {" "}
                <span className="Request_Detailas">
                  Your Agreement Number{" "}
                </span>{" "}
                <span className="font-12 poppins-medium textw">{companyData}</span>

                <span className="font-12 poppins-medium textw text-primary"><KeyboardArrowRightIcon /> <a href="https://www.docusoft.net/">Help</a></span>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <hr />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserSetting;
