import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ad_GetAllContacts: [],
  ad_GetAllClientList: [],
  requestcommentList: [],
  getDocumentStatusList: [],
  editCompanyModal: false,
  editContactModal: false,
  signDocument: {},
};

const adityaReducer = createSlice({
  name: "ak_Slice",
  initialState,
  reducers: {
    GetAllContacts_reducer: (state, action) => {
      state.ad_GetAllContacts = action.payload;
    },
    GetAllClientList_reducer: (state, action) => {
      state.ad_GetAllClientList = action.payload;
    },
    GetAllRequestComments: (state, { payload }) => {
      state.requestcommentList = payload;
    },
    GetDocumentStatusReducer: (state, { payload }) => {
      state.getDocumentStatusList = payload;
    },
    EditCompanyOpenMdalReducer: (state, { payload }) => {
      state.editCompanyModal = !state.editCompanyModal;
    },
    EditContactOpenMdalReducer: (state, { payload }) => {
      state.editContactModal = payload;
    },
    SignDocSaveReducer: (state, { payload }) => {
      const parseData = JSON.parse(payload);
      state.signDocument = parseData[0];
    },
  },
});

export const {
  GetAllContacts_reducer,
  GetAllClientList_reducer,
  GetAllRequestComments,
  EditCompanyOpenMdalReducer,
  EditContactOpenMdalReducer,
  SignDocSaveReducer,
} = adityaReducer.actions;
export default adityaReducer.reducer;
