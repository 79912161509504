import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Attachments from "./Attachments";
import ComposePortalMessage from "./ComposePortalMessage";
import RequestDocuments from "./RequestDocuments";
import Summary from "./Summary";

const stepsData = [
  {
    id: 0,
    label: "Portal Message",
    comp: <ComposePortalMessage />,
    completed: false,
    hidden: false,
  },
  {
    id: 1,
    label: "Attachments",
    comp: <Attachments />,
    completed: false,
    hidden: false,
  },
  {
    id: 2,
    label: "Request Documents",
    comp: <RequestDocuments />,
    completed: false,
    hidden: false,
  },
  {
    id: 3,
    label: "Summary",
    comp: <Summary />,
    completed: false,
    hidden: false,
  },
];

function CreateNewRequest() {
  const [activeStepId, setActiveStepId] = useState(0);
  const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
  const [steps, setSteps] = useState(stepsData);

  const redx = useSelector((state) => state.docu);
  useEffect(() => {
    handleReset();
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      if (updatedSteps[1]) updatedSteps[1].hidden = redx.NewRequest;
      if (updatedSteps[2]) updatedSteps[2].hidden = redx.NewMessage;
      return updatedSteps;
    });
  }, [redx.NewRequest, redx.NewMessage]);
  const visibleSteps = steps.filter((step) => !step.hidden);
  const totalSteps = visibleSteps.length;

  const handleNext = () => {
    let newActiveStepId = activeStepId + 1;
    if (newActiveStepId < totalSteps) {
      setActiveStepId(newActiveStepId);
    } else {
      setIsAllStepsCompleted(true);
    }
  };

  const handleBack = () => {
    let newActiveStepId = activeStepId - 1;
    if (newActiveStepId >= 0) {
      setActiveStepId(newActiveStepId);
    }
  };

  const handleStep = (stepId) => () => {
    setActiveStepId(stepId);
  };

  const handleReset = () => {
    setActiveStepId(0);
    steps.forEach((step) => {
      step.completed = false;
    });
    setIsAllStepsCompleted(false);
  };

  const currentStep = visibleSteps[activeStepId];

  return (
    <Box className="container">
      <Box className="row">
        <Box className="col-xxl-10 col-xl-12 m-auto">
          <h2 className="title mt-4 mb-3 font-20">Create Request</h2>
          <Box className="white-box">
            <Box className="step-form">
              <Stepper nonLinear activeStep={activeStepId}>
                {visibleSteps.map((step, index) => (
                  <Step key={step.label} completed={step.completed}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {step.label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {isAllStepsCompleted ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {currentStep.comp}
                    <hr />
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        color="inherit"
                        disabled={activeStepId === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        className={"btn-black"}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStepId === totalSteps - 1 ? (
                        ""
                      ) : (
                        <Button
                          onClick={handleNext}
                          className="btn-blue"
                          endIcon={<EastIcon />}
                        >
                          {activeStepId === totalSteps - 1 ? "Finish" : "Next"}
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateNewRequest;
