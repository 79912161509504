import { Box, Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

// import pdf from "../../assets/images/files-icon/pdf.png";
import csv from "../assets/images/files-icon/csv.png";
import doc from "../assets/images/files-icon/doc.png";
import jpg from "../assets/images/files-icon/jpg.png";
import pdf from "../assets/images/files-icon/pdf.png";
import png from "../assets/images/files-icon/png.png";
import text from "../assets/images/files-icon/text.png";
import xls from "../assets/images/files-icon/xls.png";

import { useLocation } from "react-router-dom";
import {
  fetchdocuRequestData,
  RequestedDocumentAsByteArray_Json_ReduxData,
  sonamConfirmMessage1,
} from "../redux/api_helper";
import { GetRequestDocumentAction } from "../redux/dev_aditya_action";
import { UpdateRequestedDocStatusAction_Json_ReduxClient } from "../redux/dev_sonam_api_helper";
import { setRequestDocumentData } from "../redux/dev_sonam_slice";
import {
  getStatusBaseButtonRequests,
  GetStatusChipRequests,
  GetStatusRequests,
} from "../utils/RequestsFunction";
import DragAndDropRequestDoc from "./RequestComponant/DragAndDropRequestDoc";
import ShowRequestDocument from "./RequestComponant/ShowRequestDocument";

const RenderRequest = ({ accordionItem, accordionIndex }) => {
  const [Uploadcheck, setUploadcheck] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [preview, setPreview] = useState("");
  const [DocPrew, setDocPrew] = useState();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const EmailID = searchParams.get("EmailID");

  const dispatch = useDispatch();
  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const getDocumentIcon = (extension) => {
    const normalizedExtension = extension.toLowerCase();

    switch (normalizedExtension) {
      case ".pdf":
        return <img src={pdf} style={{ width: 36 }} alt="documents" />;
      case ".png":
        return <img src={png} style={{ width: 36 }} alt="documents" />;
      case ".docx":
        return <img src={doc} style={{ width: 36 }} alt="documents" />;
      case ".xlsx":
      case ".xls":
        return <img src={xls} style={{ width: 36 }} alt="documents" />;
      case ".jpg":
      case ".jpeg":
        return <img src={jpg} style={{ width: 36 }} alt="documents" />;
      case ".text":
        return <img src={text} style={{ width: 36 }} alt="documents" />;
      case ".csv":
        return <img src={csv} style={{ width: 36 }} alt="documents" />;
      default:
        return null;
    }
  };

  const fileDownload = (data) => {
    let newObjectFile = {
      accId: localStorage.getItem("agrno")
        ? localStorage.getItem("agrno")
        : "0003",
      email: localStorage.getItem("Email")
        ? localStorage.getItem("Email")
        : "patrick@docusoft.net",
      password: localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "UGF0cmljazEyMy4=",
      username: data?.EmailId,
      template: data?.DocTempId,
      docid: data?.DocId,
    };
    dispatch(
      RequestedDocumentAsByteArray_Json_ReduxData(
        newObjectFile,
        function (res) {
          if (res) {
            let base64 = "data:text/plain;base64," + res;
            let filename = data.DocName + data.DocExtension;
            const [mimePart, dataPart] = base64.split(",");
            const mimeType = mimePart.match(/:(.*?);/)[1];
            const byteCharacters = atob(dataPart);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } else {
            console.log("File not found");
          }
        }
      )
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e, index, accordionItem) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      selectFile(files[0], index, accordionItem);
    }
  };

  const selectFile = (file, index, accordionItem) => {
    if (!file) {
      return;
    }
    if (file) {
      console.log(file, "========file");
      setPreview(file);

      const reader = new FileReader();

      // Convert file to base64 string
      reader.onloadend = () => {
        const base64String = reader.result;
        // setBase64(base64String);  // Store the base64 string in state
        setPreview(base64String); // Set image preview
      };
      console.log(preview, "========file");

      reader.readAsDataURL(file); // Read file as Data URL (base64)
    }

    if (accordionItem.FileWithExtension) {
      dispatch(
        sonamConfirmMessage1(
          `Are you sure you want to replace this file?`,
          accordionItem.DocName + "." + accordionItem.DocExtension,
          file.name,
          function (res) {
            if (res) {
              let uploadobj = {
                docTempId: accordionItem?.DocTempId,
                docID: accordionItem?.DocId,
                statusId: "4",
              };

              dispatch(
                UpdateRequestedDocStatusAction_Json_ReduxClient(
                  uploadobj,
                  function (res) {
                    dispatch(fetchdocuRequestData(EmailID));
                  }
                )
              );
              processFile(file, index, accordionItem);
            }
          }
        )
      );
      return;
    } else {
      let statusobj = {
        docTempId: accordionItem?.DocTempId,
        docID: accordionItem?.DocId,
        statusId: "3",
      };

      dispatch(
        UpdateRequestedDocStatusAction_Json_ReduxClient(
          statusobj,
          function (res) {
            // console.log(res, "UpdateRequestedDocStatusAction_Json");
          }
        )
      );
      processFile(file, index, accordionItem);
    }
  };

  const processFile = (file, index, accordionItem) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const extension = getFileExtension(file.name);
      const base64WithoutPrefix = reader.result.split(",")[1];

      const newFile = {
        name: file.name,
        size: file.size,
        base64: base64WithoutPrefix,
        extension,
      };
      if (newFile) {
        RequestedDocumentCreated_Json(accordionItem, extension, newFile.base64);

        const updatedAccordionData = portalAllRequestAll.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              fileStore: [newFile],
              FileExtenson: extension,
              FileWithExtension: newFile.name,
              uploadStatus: "Document Uploaded",
              FileOnlyExtension: extension,
            };
          }
          return item;
        });
        dispatch(setRequestDocumentData(updatedAccordionData));
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
  };

  const statusClass = (data) => {
    switch (true) {
      case data?.ExpiryDate &&
        moment(data.ExpiryDate).format("DD/MM/YYYY") <
          moment().format("DD/MM/YYYY"):
        return "expired";
      case moment(data.ExpiryDate).diff(moment(), "days") <= 2:
        return "expiring";
      case data?.IsApproved === "True":
        return "approved";
      case data?.IsApproved === "False":
        return "rejected";
      case data?.IsApproved === "":
        return "pending";
      default:
        return "pending";
    }
  };

  const getFileExtension = (filename) => {
    const extension = filename.slice(
      ((filename.lastIndexOf(".") - 1) >>> 0) + 1
    );
    return extension ? `${extension}` : "";
  };

  const RequestedDocumentCreated_Json = (docData, DocExtension, attachment) => {
    let obj = {
      accId: localStorage.getItem("agrno")
        ? localStorage.getItem("agrno")
        : "0003",
      email: localStorage.getItem("Email")
        ? localStorage.getItem("Email")
        : "patrick@docusoft.net",
      password: localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "UGF0cmljazEyMy4=",
      emailID: docData?.EmailId,
      docTempId: docData?.DocTempId,
      docID: docData?.DocId,
      DocExtension: DocExtension ? DocExtension : "",
      attachment: attachment,
    };
  };

  useEffect(() => {
    getDoc();
  }, [dispatch]);

  const getDoc = () => {
    let obj = {
      DocTempId: accordionItem.DocTempId,
      DocId: accordionItem.DocId,
    };
    dispatch(
      GetRequestDocumentAction(obj, (res) => {
        const json = JSON.parse(res);
        // console.log("requset Doc :=> ", json);
        setDocPrew(json[0]);
      })
    );
  };

  return (
    <Grid key={accordionIndex} item lg={6} className="d-flex">
      <Box
        item
        className={`mb-0 white-box main-details-box w-100 ${statusClass(
          accordionItem
        )}`}
      >
        <Box>
          <Box className="d-flex align-items-center justify-content-between">
            <h2 className="poppins-medium font-12">{accordionItem?.DocName}</h2>
            <Box className="d-flex ms-2 align-items-center">
              {Uploadcheck === true &&
              accordionItem.uploadStatus === "Document Uploaded" ? (
                <Chip
                  label="Document Uploaded"
                  size="small"
                  className="chips"
                  sx={{
                    background: "green",
                  }}
                />
              ) : (
                GetStatusChipRequests(
                  accordionItem.RequestDocStatus,
                  accordionItem.StatusName,
                  accordionItem.Status
                )
              )}
              <Box className="ms-1">{GetStatusRequests(accordionItem)}</Box>
            </Box>
          </Box>

          <hr />

          <Box>
            <Typography>Status Id :{accordionItem.Status}</Typography>
            <Typography> DocTempId : {accordionItem.DocTempId}</Typography>
            <Typography>
              RequestDocStatus : {accordionItem?.RequestDocStatus}
            </Typography>
            <Typography>DocId : {accordionItem?.DocId}</Typography>
          </Box>

          <Box>
            {accordionItem?.RequestDocStatus != "2" && (
              <Box className="details-box-row d-flex flex-wrap mb-0">
                {accordionItem?.Individual.length > 1 && (
                  <Box className="details-box">
                    <Typography className="poppins-medium mb-1">
                      Uploaded by
                    </Typography>
                    <Typography className="font-12 badge text-bg-info">
                      <PersonIcon className="font-18" />{" "}
                      {accordionItem?.Individual}{" "}
                    </Typography>
                  </Box>
                )}

                {accordionItem?.UploadedDate && (
                  <Box className="details-box">
                    <Typography className="poppins-medium mb-1">
                      Uploaded on
                    </Typography>
                    <Typography className="font-12 badge text-bg-primary">
                      <CalendarMonthIcon className="font-18" />
                      {accordionItem?.UploadedDate
                        ? moment(accordionItem.UploadedDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </Typography>
                  </Box>
                )}
                {accordionItem?.IsApproved && (
                  <Box className="details-box">
                    <Typography className="poppins-medium mb-1">
                      Approved date
                    </Typography>
                    <Typography className="font-12 badge text-bg-success">
                      {" "}
                      <CalendarMonthIcon className="font-18" />
                      {accordionItem?.ApprovalDate
                        ? moment(accordionItem.UploadedDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </Typography>
                  </Box>
                )}
                {accordionItem?.IsApproved && (
                  <Box className="details-box">
                    <Typography className="poppins-medium mb-1">
                      Approved
                    </Typography>
                    {accordionItem?.IsApproved === "True" ? (
                      <Typography className="font-12 badge text-bg-success">
                        <CheckCircleIcon className="font-18" />
                        Yes
                      </Typography>
                    ) : (
                      <Typography className="font-12 badge text-bg-danger">
                        <CheckCircleIcon className="font-18" />
                        No
                      </Typography>
                    )}
                  </Box>
                )}
                {accordionItem?.IsApproved === "True" && (
                  <Box className="details-box">
                    <Typography className="poppins-medium mb-1">
                      Approved by
                    </Typography>
                    <Typography className="font-12 badge text-bg-success">
                      <VerifiedUserIcon className="font-18" />{" "}
                      {accordionItem?.ApprovedByUser}
                    </Typography>
                  </Box>
                )}
                {accordionItem?.ExpiryDate && (
                  <Box className="details-box">
                    <Typography className="poppins-medium mb-1">
                      Expiry date
                    </Typography>
                    <Typography className="font-12 badge text-bg-danger">
                      <CalendarMonthIcon className="font-18" />
                      {accordionItem?.ExpiryDate
                        ? moment(accordionItem.ExpiryDate).format("DD/MM/YYYY")
                        : ""}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            <Box className="mt-3">
              {DocPrew && (DocPrew?.DocData || DocPrew?.DocData2) && (
                <ShowRequestDocument DocumentData={DocPrew} />
              )}
            </Box>

            <Box item>
              {/* <Box>
                <p>Status: {accordionItem.Status}</p>
                <p>RequestDocStatus: {accordionItem.RequestDocStatus}</p>
              </Box> */}
              <Box>
                {((accordionItem.Status == "0" &&
                  accordionItem.RequestDocStatus == "2") ||
                  (accordionItem.Status == "0" &&
                    accordionItem.RequestDocStatus == "5") ||
                  (accordionItem.Status == "0" &&
                    accordionItem.RequestDocStatus == "7")) &&
                accordionItem.DocExtension == null ? (
                  <Box className="d-flex align-items-center justify-content-between mb-3">
                    <Box className="well-box w-100">
                      {accordionItem.FileWithExtension ? (
                        <Box
                          sx={{
                            border: "1px solid #f1f1f1",
                            boxShadow: "none",
                          }}
                          className="white-box d-flex align-items-center justify-content-between p-3 mb-2"
                        >
                          <Box className="d-flex align-items-center">
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "550",
                              }}
                              className="d-flex align-items-center"
                            >
                              {accordionItem.FileOnlyExtension
                                ? getDocumentIcon(
                                    accordionItem?.FileOnlyExtension
                                  )
                                : accordionItem?.DocExtension &&
                                  getDocumentIcon(accordionItem?.DocExtension)}
                              <span className="d-inline-block ms-2">
                                {accordionItem.FileWithExtension}
                              </span>
                            </Typography>
                          </Box>

                          <Box>
                            {accordionItem.FileWithExtension && (
                              <Tooltip title="Download">
                                <Button
                                  className="ms-2 min-width-auto"
                                  size="small"
                                  onClick={() => fileDownload(accordionItem)}
                                >
                                  <DownloadIcon className="text-blue" />
                                </Button>
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      ) : (
                        <DragAndDropRequestDoc
                          accordionItem={accordionItem}
                          accordionIndex={accordionIndex}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <>
                    {accordionItem.FileWithExtension != undefined ? (
                      <>
                        <Box
                          className="d-flex align-items-center justify-content-between mb-3"
                          sx={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "5px",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "550",
                            }}
                            className="d-flex align-items-center"
                          >
                            {accordionItem.FileOnlyExtension
                              ? getDocumentIcon(
                                  accordionItem?.FileOnlyExtension
                                )
                              : accordionItem?.DocExtension &&
                                getDocumentIcon(accordionItem?.DocExtension)}

                            <span className="d-inline-block ms-2">
                              {accordionItem.FileWithExtension}
                            </span>
                          </Typography>
                          <Box>
                            {accordionItem.FileWithExtension && (
                              <Tooltip title="Download">
                                <Button
                                  className="ms-2 min-width-auto"
                                  size="small"
                                  onClick={() => fileDownload(accordionItem)}
                                >
                                  <DownloadIcon className="text-blue" />
                                </Button>
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <>
                  {getStatusBaseButtonRequests(
                    accordionIndex,
                    accordionItem,
                    setPreview
                  )}
                </>
              </Box>
            </Box>
            {/*  */}
          </Box>

          {accordionItem?.notes && accordionItem?.notes != null && (
            <Box
              item
              sx={{
                maxHeight: "100px",
                overflow: "auto",
              }}
            >
              <Box className="mt-1">
                <Typography className="poppins-medium font-12 border-bottom pb-1 mb-1">
                  Reason for disapproval{" "}
                </Typography>
                <Typography className="font-12 text-">
                  {accordionItem?.notes}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default RenderRequest;
