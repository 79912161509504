import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoComplete from "../components/AutoComplete";
import Checkbox from "@mui/material/Checkbox";
import CheckboxesMultiSelectTags from "../components/CheckBoxesMultiSelectTags";
import {
  Json_GetFolders_Redux,
  Json_GetConfiguration,
  addCompany_Redux,
  SetClientAddress_Redux,
  Json_GetClientCardDetails_Redux,
  Json_GetClientAddresses_Redux,
  Json_UpdateClient_Redux,
} from "../redux/ak_api_helper";
import { setOpenCompanyModal } from "../redux/modalSlice";
import { toast } from "react-toastify";
import { Autocomplete, Button } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { setcompanyCardDitiels_Redux } from "../redux/akSlice";
import { AllCountriesList } from "../custom-hook/AllCountriesList";

const CreateCompany = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenCompanyModal(false));
  };
  const { GetFolders } = useSelector((state) => state.ak);
  const { companyAddress } = useSelector((state) => state.ak);
  const { getClientIdName } = useSelector((state) => state.ak);
  const { getClientIdMethod } = useSelector((state) => state.ak);
  const { getFolderObject } = useSelector((state) => state.ak);
  const { companyCardDitiels, companyCardDitielsAddresses } = useSelector(
    (state) => state.ak
  );
  const isEmpty = Object.keys(companyCardDitiels).length === 0;

  const { bussiness, sources, mangers, companyStatus } = useSelector(
    (state) => state.ak
  );

  //start editing code for company
  const [value, setValue] = useState("");
  const [manager, setManager] = useState(null);
  const [bussines, setbussines] = useState(null);
  const [sources1, setsources1] = useState(null);
  const [comStatus, setcomStatus] = useState(null);
  const [FolderIdtolabel, setFolderIdtolabel] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [storedValue, setStoredValue] = useState("");
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [CompanyFormData, setCompanyFormData] = useState({
    ID: "",
    Name: "",
    TelPhoneNo: "",
    Town: "",
    MobileNo: "",
    contactEmail: "",
    "Post Code": "",
    "Address Line 1": "",
    "Address Line 2": "",
    "Address Line 3": "",
    status: "",
    PassWord: "",
    Manager: "",
    County: "",
    Country: "",
    Business: "",
    Source: "",
    Folder: [],
  });

  React.useEffect(() => {   
    dispatch(Json_GetFolders_Redux());
    dispatch(Json_GetConfiguration());
  }, []);


    console.log("companyCardDitiels =>>> ", companyCardDitiels);
    console.log("CompanyFormData===>", CompanyFormData);
    console.log("FolderIdtolabel ==>", FolderIdtolabel);
    console.log("sources == >",sources);
    
  

  useMemo(() => {
    if (FolderIdtolabel) {
      setSelectedFolders((selectedFolders) => [
        ...selectedFolders,
        FolderIdtolabel,
      ]);
    }
  },[FolderIdtolabel])

  useEffect(
    () => {
      // Find the manager with the specified ID
      const foundManager = mangers.find(
        (manager) => manager.UserId === companyCardDitiels.ManagerID
      );
      // Set the manager object in the state
      setManager(foundManager || { UserName: "" });
      const foundbussiness = bussiness.find(
        (manager) => manager.UserId === companyCardDitiels.BussId
      );
      // Set the manager object in the state
      setbussines(foundbussiness || { BussName: "" });
      const foundsources = sources.find(
        (manager) => manager.SourceId === companyCardDitiels.SourceId
      );
      // Set the manager object in the state
      setsources1(foundsources || { SourceName: "" });
      const foundstatus = companyStatus.find(
        (manager) => manager.StatusId === companyCardDitiels.StatusId
      );
      // Set the manager object in the state
      setcomStatus(foundstatus || { StatusName: "" });
      const foundfolder = GetFolders.find(
        (manager) => manager.FolderID == getFolderObject
      );
      // Set the manager object in the state
      setFolderIdtolabel(foundfolder || { Folder: "" });

      try {
        if (companyCardDitiels) {
          setCompanyFormData({
            ID: companyCardDitiels.OriginatorNo
              ? companyCardDitiels.OriginatorNo
              : "" || "",
            Name: companyCardDitiels.OriginatorName || "",
            TelPhoneNo: companyCardDitiels.TelNo || "",
            Town: companyCardDitielsAddresses.Town || "",
            MobileNo: companyCardDitiels.AltTelNo || "",
            contactEmail: companyCardDitiels.Email || "",
            "Post Code": companyCardDitielsAddresses.Postcode || "",
            "Address Line 1": companyCardDitielsAddresses.Add1 || "",
            "Address Line 2": companyCardDitielsAddresses.Add2 || "",
            "Address Line 3": companyCardDitielsAddresses.Add3 || "",
            status: companyCardDitiels.status || "",
            PassWord: companyCardDitiels.OrgPassword || "",
            Manager: companyCardDitiels.ManagerID || "",
            County: companyCardDitielsAddresses.County || "",
            Country: companyCardDitiels.Country || "",
            Business: companyCardDitiels.Business || "",
            Source: companyCardDitiels.Source || "",
            Folder: companyCardDitiels.Folder || [],
          });
        }
      } catch (err) {
        console.log("trycatch", err);
      }
    },
    [companyCardDitiels[0]],
    companyCardDitiels.ManagerID
  );

  //end editing code for company

  const handleFnameInputChange = (event) => {
    const { name, value } = event.target;
    setCompanyFormData({ ...CompanyFormData, [name]: value });
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange1 = (event) => {
    const newEmail = event.target.value;
    const { name, value } = event.target;
    setCompanyFormData({ ...CompanyFormData, [name]: value });
    setEmail(newEmail);
    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email address");
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const { name, value } = event.target;
    setCompanyFormData({ ...CompanyFormData, [name]: value });

    if (
      value.includes(CompanyFormData.ID) ||
      event.nativeEvent.inputType === "deleteContentBackward"
    ) {
      setIsChecked(false);
    }
  };

  React.useEffect(() => {
    if (isChecked) {
      setStoredValue(" " + textFieldValue);
    } else {
      setStoredValue("");
    }
  }, [isChecked, textFieldValue]);
  React.useEffect(() => {
    if (storedValue && value.includes(storedValue)) {
      setValue(value.replace(storedValue, ""));
    }
  }, [storedValue, value]);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      if (!CompanyFormData.Name.includes(CompanyFormData.ID)) {
        setCompanyFormData({
          ...CompanyFormData,
          Name: `${CompanyFormData.Name} ${CompanyFormData.ID}`,
        });
      }
    } else {
      setCompanyFormData({
        ...CompanyFormData,
        Name: CompanyFormData.Name.split(" ").slice(0, -1).join(" ").toString(),
      });
    }
  };

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z0-9-]*$/; // Regex pattern: only letters, numbers, and hyphens allowed

    if (regex.test(value)) {
      setTextFieldValue(value);
      setCompanyFormData({ ...CompanyFormData, [name]: value });
    }
  };

  const mainAddress = () => {
    let obj = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      OriginatorNo: CompanyFormData.ID ? CompanyFormData.ID : "",
      AddressId: 1,
      AddressType: "Main Address",
      Add1: CompanyFormData["Address Line 1"]
        ? CompanyFormData["Address Line 1"]
        : "",
      Add2: CompanyFormData["Address Line 2"]
        ? CompanyFormData["Address Line 2"]
        : "",
      Add3: CompanyFormData["Address Line 3"]
        ? CompanyFormData["Address Line 3"]
        : "",
      Town: CompanyFormData.Town ? CompanyFormData.Town : "",
      County: CompanyFormData.County ? CompanyFormData.County : "",
      Postcode: CompanyFormData["Post Code"]
        ? CompanyFormData["Post Code"]
        : "",
      Country: CompanyFormData.Country
        ? CompanyFormData.Country
        : "United Kingdom",
    };
    console.log("Company object", obj);
    dispatch(SetClientAddress_Redux(obj));
  };

  function todayDate() {
    var today = new Date().toJSON().slice(0, 10);
    return today;
  }

  const companyCreate = () => {
    let clientdata = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),
      ProjectIdList: CompanyFormData.Folder ? CompanyFormData.Folder : "",
      OriginatorNo: CompanyFormData.ID ? CompanyFormData.ID : "",
      OriginatorName: CompanyFormData.Name ? CompanyFormData.Name : "",
      Address: CompanyFormData.fullAddress ? CompanyFormData.fullAddress : "",
      TelNo: CompanyFormData.TelPhoneNo ? CompanyFormData.TelPhoneNo : "",
      AlteTelNo: CompanyFormData.MobileNo ? CompanyFormData.MobileNo : "",
      Faxno: "",
      ContactName: "",
      UDF1: "",
      UDF2: "",
      UDF3: "",
      StickyNote: "",
      ContactEmail: CompanyFormData.contactEmail
        ? CompanyFormData.contactEmail
        : "",
      MParameter: "",
      CDate: todayDate(),
      BussId: CompanyFormData.Business.BussId
        ? CompanyFormData.Business.BussId
        : -1,
      SourceId: CompanyFormData.Source.SourceId
        ? CompanyFormData.Source.SourceId
        : -1,
      StatusId: CompanyFormData.status.StatusId
        ? CompanyFormData.status.StatusId
        : "8",
      Description: "",
      OrgPassword: CompanyFormData.PassWord ? CompanyFormData.PassWord : "",
      ManagerId: CompanyFormData.Manager.UserId
        ? CompanyFormData.Manager.UserId
        : localStorage.getItem("FolderId"),
      OrgActive: "Yes",
    };
    console.log(CompanyFormData, "clientdata", clientdata);
    if (CompanyFormData.Name !== "" && CompanyFormData.contactEmail !== "") {
      dispatch(addCompany_Redux(clientdata))
        .then(() => {
          mainAddress(); // Call mainAddress on success
          toast.success("Company added successfully"); // Show error toast message
        })
        .catch((error) => {
          // Handle any errors if the addCompany_Redux action fails
          console.error("Failed to add contact:", error);
        });
    } else {
      // alert("Please fill all the required fields");
      toast.error("Please fill all the required fields");
    }
    // let UpdateClient = {
    //   agrno: localStorage.getItem("agrno"),
    //   Email: localStorage.getItem("Email"),
    //   password: localStorage.getItem("password"),

    //   OriginatorNo:  CompanyFormData.ID ? CompanyFormData.ID : "",
    //   OriginatorName: getClientIdName,
    //   Address: "",
    //   TelNo:  CompanyFormData.TelPhoneNo ? CompanyFormData.TelPhoneNo : "",
    //   AlteTelNo:  CompanyFormData.MobileNo ? CompanyFormData.MobileNo : "",
    //   Faxno: "",
    //   ContactName:  CompanyFormData.Name ? CompanyFormData.Name : "",
    //   OrgActive: "Yes",
    //   StickyNote: "",
    //   ContactEmail: "",
    // BussId: CompanyFormData.Business.BussId
    //     ? CompanyFormData.Business.BussId
    //     : -1,
    //   SourceId: CompanyFormData.Source.SourceId
    //     ? CompanyFormData.Source.SourceId
    //     : -1,
    //   StatusId: CompanyFormData.status.StatusId
    //     ? CompanyFormData.status.StatusId
    //     : "8",
    //   Description: "",
    //   OrgPassword: CompanyFormData.PassWord ? CompanyFormData.PassWord : "",
    //   ManagerId: CompanyFormData.Manager.UserId
    //   ? CompanyFormData.Manager.UserId
    //   : '1',
    //   CCode: getClientIdMethod,
    // };
    // dispatch(Json_UpdateClient_Redux(UpdateClient));
  };

  const companyUpdate = () => {
    let UpdateClient = {
      agrno: localStorage.getItem("agrno"),
      Email: localStorage.getItem("Email"),
      password: localStorage.getItem("password"),

      OriginatorNo: CompanyFormData.ID ? CompanyFormData.ID : "",
      OriginatorName: getClientIdName,
      Address: "",
      TelNo: CompanyFormData.TelPhoneNo ? CompanyFormData.TelPhoneNo : "",
      AlteTelNo: CompanyFormData.MobileNo ? CompanyFormData.MobileNo : "",
      Faxno: "",
      ContactName: CompanyFormData.Name ? CompanyFormData.Name : "",
      OrgActive: "Yes",
      StickyNote: "",
      ContactEmail: "",
      BussId: CompanyFormData.Business.BussId
        ? CompanyFormData.Business.BussId
        : -1,
      SourceId: CompanyFormData.Source.SourceId
        ? CompanyFormData.Source.SourceId
        : -1,
      StatusId: CompanyFormData.status.StatusId
        ? CompanyFormData.status.StatusId
        : "8",
      Description: "",
      OrgPassword: CompanyFormData.PassWord ? CompanyFormData.PassWord : "",
      ManagerId: CompanyFormData.Manager.UserId
        ? CompanyFormData.Manager.UserId
        : "1",
      CCode: getClientIdMethod,
    };
    dispatch(Json_UpdateClient_Redux(UpdateClient)).then(() => {
      mainAddress(); // Call mainAddress on success
      toast.success("Company added successfully"); // Show error toast message
      dispatch(
        setcompanyCardDitiels_Redux({
          companyCardDitiels: [],
        })
      );
    });
  };

  const handleChange1 = (event, newValue) => {
    setSelectedFolders(newValue);
    // setCompanyFormData({...CompanyFormData,Folder:newValue});
    event.preventDefault();
    if (newValue) {
      const folderIds = newValue.map((folder) => folder.FolderID).join(",");
      console.log(newValue, "foldergetdata", folderIds);
      let data = { ...CompanyFormData };
      data = { ...data, ["FolderId"]: folderIds };
      setCompanyFormData({ ...CompanyFormData, Folder: folderIds });
    }
  };

  return (
    <>
    <Box>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <TextField
            className="w-100"
            label="ID"
            id="outlined-size-small"
            size="small"
            sx={{ marginRight: 1 }}
            required
            name="ID"
            value={CompanyFormData.ID}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <Box className="d-flex align-items-center ms-3">
            <Typography>Attach ID</Typography>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          </Box>
        </Grid>

        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <TextField
            fullWidth
            label="Name"
            id="outlined-size-small"
            size="small"
            value={CompanyFormData.Name}
            name="Name"
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
        <Autocomplete
            multiple
            size="small"
            options={GetFolders}
            disableCloseOnSelect
 getOptionLabel={(option) => option.Folder}
            defaultValue={(FolderIdtolabel && Object.keys(FolderIdtolabel).length>0) ? [FolderIdtolabel] : selectedFolders}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.Folder}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Folder"
                placeholder="Select folders"
              />
            )}
            onChange={handleChange1}
            value={selectedFolders}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <TextField
            className="w-100"
            fullWidth
            label="Email"
            id="outlined-size-small"
            size="small"
            required
            name="contactEmail"
            value={CompanyFormData.contactEmail}
            onChange={handleEmailChange1}
            error={!!emailError}
            helperText={emailError}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <TextField
            fullWidth
            label="MobileNo"
            id="outlined-size-small"
            size="small"
            name="MobileNo"
            value={CompanyFormData.MobileNo}
            onChange={handleFnameInputChange}
            required
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <TextField
            fullWidth
            label="TelPhoneNo"
            id="outlined-size-small"
            size="small"
            name="TelPhoneNo"
            value={CompanyFormData.TelPhoneNo}
            onChange={handleFnameInputChange}
            required
          />
        </Grid>
        <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
          <AutoComplete
            ComData={mangers}
            defaultValue={manager ? manager : ""}
            target="UserName"
            comFun={(e, newValue) => {
              setCompanyFormData({ ...CompanyFormData, Manager: newValue });
            }}
            lable="Manager"
          ></AutoComplete>
        </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Accordion defaultExpanded className="accordian-box mb-0">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                spacing={2}
              >
                <Typography expandIcon={<ExpandMoreIcon  />}>Address</Typography>
              </AccordionSummary>

              <AccordionDetails className="py-4">
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Address Line 1"
                      id="outlined-size-small"
                      size="small"
                      name="Address Line 1"
                      value={CompanyFormData["Address Line 1"]}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Address Line 2"
                      id="outlined-size-small"
                      size="small"
                      name="Address Line 2"
                      value={CompanyFormData["Address Line 2"]}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Address Line 3"
                      id="outlined-size-small"
                      size="small"
                      name="Address Line 3"
                      value={CompanyFormData["Address Line 3"]}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Town"
                      id="outlined-size-small"
                      size="small"
                      name="Town"
                      value={CompanyFormData.Town}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="County"
                      id="outlined-size-small"
                      size="small"
                      name="County"
                      value={CompanyFormData.County}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Post Code"
                      id="outlined-size-small"
                      size="small"
                      name="Post Code"
                      value={CompanyFormData["Post Code"]}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>

                  <Grid item xs={6} sm={4} md={6} lg={6} xl={6} xxl={6}>
                    <AutoComplete
                      ComData={AllCountriesList}
                      target="label"
                      defaultValue={CompanyFormData? CompanyFormData?.Country:"United Kingdom"}
                      comFun={(e, newValue) => {
                        setCompanyFormData({
                          ...CompanyFormData,
                          Country: newValue.label,
                        });
                      }}
                      lable="Country"
                    ></AutoComplete>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Accordion className="accordian-box">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                spacing={2}
              >
                <Typography expandIcon={<ExpandMoreIcon />}>
                  Additional Details
                </Typography>
              </AccordionSummary>

              <AccordionDetails className="py-4">
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <AutoComplete
                      ComData={bussiness}
                      defaultValue={bussines ? bussines : ""}
                      target="BussName"
                      comFun={(e, newValue) => {
                        setCompanyFormData({
                          ...CompanyFormData,
                          Business: newValue,
                        });
                      }}
                      lable="Business"
                    ></AutoComplete>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <AutoComplete
                      ComData={companyStatus}
                      defaultValue={comStatus ? comStatus : "Active"}
                      target="StatusName"
                      comFun={(e, newValue) => {
                        setCompanyFormData({
                          ...CompanyFormData,
                          status: newValue,
                        });
                      }}
                      lable="Status"
                    ></AutoComplete>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <AutoComplete
                      ComData={sources}
                      defaultValue={sources1 ? sources1 : ""}
                      target="SourceName"
                      comFun={(e, newValue) => {
                        setCompanyFormData({
                          ...CompanyFormData,
                          Source: newValue,
                        });
                      }}
                      lable="Source"
                    ></AutoComplete>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <TextField
                      fullWidth
                      label="Password"
                      id="outlined-size-small"
                      size="small"
                      name="PassWord"
                      value={CompanyFormData.PassWord}
                      onChange={handleFnameInputChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Box className="mt-4 mb-2">
          {isEmpty ? (
            <Button
              onClick={companyCreate}
              autoFocus
              variant="outlined"
              className="btn-blue me-2"
              startIcon={<CreateIcon />}
            >
              Create Company
            </Button>
          ) : (
            <Button
              onClick={companyUpdate}
              autoFocus
              variant="outlined"
              className="btn-blue me-2"
              startIcon={<CreateIcon />}
            >
              Update Company
            </Button>
          )}
          <Button
            onClick={handleClose}
            className="btn-red"
            variant=""
            startIcon={<DeleteIcon />}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CreateCompany;

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];

