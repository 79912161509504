import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SentMessages from "./SentMessages";
import ReceivedMessages from "./ReceivedMessages";
import RetractedMessages from "./RetractedMessages";
export default function PortalMessages() {

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      {/* <h2 className="title"></h2> */}
      <Box sx={{ width: "100%", typography: "body1" }} className="custom-tabs">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" className="mb-0 flex-wrap-tabs min-h-auto">
              <Tab label="Sent Messages" value="1" />
              <Tab label="Received Messages" value="2" />
              <Tab label="Retracted Messages" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" className="p-0 mt-">
            <SentMessages value={value}/>
          </TabPanel>
          <TabPanel value="2" className="p-0 mt-">
            <ReceivedMessages value={value}/>
          </TabPanel>
          <TabPanel value="3" className="p-0 mt-">
            <RetractedMessages value={value}/>
          </TabPanel>
          
        </TabContext>
      </Box>
    </Box>
  );
}
