import DrawIcon from "@mui/icons-material/Draw";
import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { SignDocSaveReducer } from "../../redux/adityaReducer";
import { GetSignature_Json_Redux } from "../../redux/ak_api_helper";
import { GetSignatureDocumentAction } from "../../redux/dev_aditya_action";
import { setPortalMessageDocuments } from "../../redux/dev_chetan_slice";
import { setfetchDocumentRequest } from "../../redux/docuSlice";
import { setOpenSignDocument } from "../../redux/modalSlice";
import { clientBodyaccidData } from "../../utils/RequestsFunction";

const SignatureRequest = ({ accordionItem }) => {
  const dispatch = useDispatch();

  const handleSignClick = () => {
    {
      // dispatch(SignDocSaveReducer({}));
      dispatch(setOpenSignDocument(true));
      dispatch(GetSignature_Json_Redux(clientBodyaccidData));
      dispatch(setfetchDocumentRequest(accordionItem));
      dispatch(setPortalMessageDocuments([]));

      let obj = {
        DocTempId: accordionItem.DocTempId,
        DocId: accordionItem.DocId,
      };
      dispatch(GetSignatureDocumentAction(obj, (res) => {}));

      // dispatch(
      //   UpdateRequestedDocStatusAction_Json_ReduxClient(
      //     uploadobj,
      //     function (res) {
      //       console.log(res, "UpdateRequestedDocStatusAction_Json");
      //     }
      //   )
      // );
    }
  };
  return (
    <Button
      component="label"
      variant="text"
      startIcon={<DrawIcon />}
      className="btn-blue"
      sx={{
        padding: "5px",

        fontSize: "12px",

        marginRight: "10px",
      }}
      onClick={handleSignClick}
    >
      Signature
    </Button>
  );
};

export default SignatureRequest;
