import React, { useEffect, useState } from "react";
import AutoComplete from "../../../components/AutoComplete";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DrawIcon from "@mui/icons-material/Draw";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import pdf from "../../../assets/images/files-icon/pdf.png";
import GetFileType from "../../../components/FileType";
import {
  CheckDocumentForFormFilling_Json_Redux,
  CheckDocumentForSignature_Json_Redux,
  GetPortalRequestDocuments_Json_Redux,
  RequestedDocumentCreated_Json_Redux,
  getFileExtension,
} from "../../../redux/api_helper";
import { setRequestDocument_Redux } from "../../../redux/docuSlice";
import CreateTemplate from "../Templates/CreateTemplate";
import DounloadFile from "./DownloadFile";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const agrno = localStorage.getItem("agrno");
const password = localStorage.getItem("password");
const FolderId = localStorage.getItem("FolderId");
const Email = localStorage.getItem("Email");

function RequestDocuments() {
  let redx = useSelector((state) => state.docu.ComposePortalMgsObj);

  let docRe = useSelector((state) => state.docu.RequestDocumnets);

  let docRe222 = useSelector((state) => state.docu.RequestDocumnets);

  let main = useSelector((state) => state.docu);
  //console.log("main redux", main);
  let dispatch = useDispatch();

  const [requestDocuments, setRequestDocuments] = useState([]);

  const [RequestDoc, setRequestDoc] = useState(main.PortalDocReqTemplatesLiset);
  const [selectedTemplateObj, setSelectedTemplateObj] = useState({});
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);

  // Define a single function to handle dispatching actions based on object type
  const handleObjectDispatch = (objectType, value) => {
    // Update mainObject state
    setRequestDoc((prevState) => ({
      ...prevState,
      [objectType]: value,
    }));
  };

  const CheckDocumentForFormFilling_Json = (dt, callback) => {
    try {
      if (redx.ToEmail) {
        let o = {
          emailid: redx.ToEmail[0]["E-Mail"],
          sPassword: "0",
          docTempId: dt.DocTempId,
          docID: dt.DocId,
        };
        dispatch(
          CheckDocumentForFormFilling_Json_Redux(o, function (res) {
            if (res) {
              console.log("CheckDocumentForFormFilling_Json_Redux", res);
              callback(res);
            }
          })
        );
      } else {
        toast.error("Please select the Email ");
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server CheckDocumentForFormFilling_Json_Redux",
        error
      );
    }
  };

  const CheckDocumentForSignature_Json = (dt, callback) => {
    try {
      if (redx.ToEmail[0]) {
        let o = {
          emailid: redx.ToEmail[0]["E-Mail"],
          sPassword: "0",
          docTempId: dt.DocTempId,
          docID: dt.DocId,
        };
        dispatch(
          CheckDocumentForSignature_Json_Redux(o, function (res) {
            if (res) {
              console.log("CheckDocumentForSignature_Json", res);
              callback(res);
            }
          })
        );
      } else {
        toast.error("Please select the Email ");
      }
    } catch (error) {
      console.log(
        "Network error: No response received from server CheckDocumentForSignature_Json",
        error
      );
    }
  };

  const getTemplatesObject = (value) => {
    setIntervalId(null);
    clearInterval(intervalId);
    if (!value) {
      console.log("No value selected");
      return;
    }

    try {
      dispatch(setRequestDocument_Redux(value));

      setSelectedTemplateObj(value);

      setSelectedTemplate({ action: "Edit", data: value });

      const obj = {
        id: value.DocTempID,
      };
      dispatch(
        GetPortalRequestDocuments_Json_Redux(obj, function (res) {
          let strGuid = uuidv4().replace(/-/g, "");
          if (res) {
            if (res.length > 0) {
              let result = res.map((e) => {
                let o = { ...e }; // Create a shallow copy of the object

                o.guid = strGuid;
                if (e.DocData && e.DocExtension) {
                  o.FileName = e.DocName + "." + e.DocExtension;
                  o.DocData = byteArrayToBase64(e.DocData);
                }

                // if (e.RequestDocType === 1) {
                //   CheckDocumentForSignature_Json(e, function (data) {
                //     if (data.length > 0) {
                //       if (data[0].RequestDocStatus === "1") {
                //         o.SignObj = data[0];
                //       }

                //     }

                //   })
                // }
                // else if (e.RequestDocType === 2) {
                //   CheckDocumentForFormFilling_Json(e, function (data) {
                //     console.log("CheckDocumentForFormFilling_Json", data);
                //     if (data.length > 0) {
                //       o.formReady = data[0];
                //     }
                //   })
                // }
                // else if (e.RequestDocType === 3) {
                //   CheckDocumentForFormFilling_Json(e, function (data) {
                //     console.log("CheckDocumentForFormFilling_Json request33", data);
                //     if (data.length > 0) {
                //       o.formReq = data[0];
                //     }
                //   })
                // }

                return o; // Return the modified object
              });

              if (result.length > 0) {
                setTimeout(() => {
                  setRequestDocuments(result);
                  console.log("CheckDocumentForFormFilling_Json122", result);
                }, 2000);
              }
            } else {
              setRequestDocuments([]);
            }
          } else {
            setRequestDocuments([]);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalUsersRequestedDocs_Json_Redux",
        error
      );
    }
  };

  useEffect(() => {
    if (docRe222 && docRe222.DocTempID) {
      getTemplatesObject(docRe222);
    }
  }, [docRe222, docRe]);

  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedFilesForm, setSelectedFilesForm] = useState({});
  const [selectedFileFormReuest, setselectedFileFormReuest] = useState({});

  const handleChangeSelectFileSignature = async (item, event) => {
    if (redx.ToEmail[0]["E-Mail"]) {
      const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      let filetype = getFileExtension(file.name);

      if (filetype.toLowerCase() === "pdf") {
        setSelectedFiles((prevState) => ({
          ...prevState,
          [item.DocName]: {
            file,
            base64,
            item,
          },
        }));

        let obj = {
          accId: agrno,
          email: Email,
          emailID: redx.ToEmail[0]["E-Mail"], //redx.ToEmail[0]["E-Mail"],
          password: password,
          docTempId: item.DocTempId.toString(),
          docID: item.DocId.toString(),
          DocExtension: "." + getFileExtension(file.name),
          attachment: base64,
        };

        RequestedDocumentCreated_Json(obj);

        // setRequestDocuments((prevDocuments) =>
        //   prevDocuments.map(doc =>
        //     doc.guid === item.guid ? { ...doc, FileName: file.name } : doc
        //   )
        // );
      } else {
        toast.error("This feature only support PDF file !");
      }
    } else {
      toast.error("Please select Email");
    }
  };

  function RequestedDocumentCreated_Json(obj) {
    try {
      dispatch(
        RequestedDocumentCreated_Json_Redux(obj, function (data) {
          if (data) {
            if (data.d)
              console.log("RequestedDocumentCreated_Json_Redux", data);
            toast.success("Document Uploaded");
            setTimeout(() => {
              if (docRe && docRe.DocTempID) {
                getTemplatesObject(docRe);
              }
            }, 2500);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, RequestedDocumentCreated_Json",
        error
      );
    }
  }

  const handleChangeSelectFileUploadForm = async (item, event) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    setSelectedFilesForm((prevState) => ({
      ...prevState,
      [item.DocName]: {
        file,
        base64,
        item,
      },
    }));

    let obj = {
      accId: agrno,
      email: Email,
      emailID: redx.ToEmail[0]["E-Mail"], //redx.ToEmail[0]["E-Mail"],
      password: password,
      docTempId: item.DocTempId.toString(),
      docID: item.DocId.toString(),
      DocExtension: "." + getFileExtension(file.name),
      attachment: base64,
    };
    RequestedDocumentCreated_Json(obj);
  };

  const handleChangeSelectFileUploadFormRquest = async (item, event) => {
    console.log("hello request", item);
    const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    setselectedFileFormReuest((prevState) => ({
      ...prevState,
      [item.DocName]: {
        file,
        base64,
        item,
      },
    }));

    let obj = {
      accId: agrno,
      email: Email,
      emailID: redx.ToEmail[0]["E-Mail"], //redx.ToEmail[0]["E-Mail"],
      password: password,
      docTempId: item.DocTempId.toString(),
      docID: item.DocId.toString(),
      DocExtension: "." + getFileExtension(file.name),
      attachment: base64,
    };
    RequestedDocumentCreated_Json(obj);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Extract base64 part
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const addSignature = (fileData) => {
    try {
      if (
        redx.ToEmail &&
        redx.ToEmail.length > 0 &&
        redx.ToEmail[0]["E-Mail"]
      ) {
        console.log("add signature", fileData);
        let url = `https://Signing.docuengager.com/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sEmailId=${redx.ToEmail[0]["E-Mail"]}&sendemail=&clientname=${redx.Client.Client}&option=upload&DocTempId=${fileData.item.DocTempId}&DocId=${fileData.item.DocId}&to=${redx.ToEmail[0]["E-Mail"]}&template=${selectedTemplateObj.DocTempID}&rwndrnd=0.8166129123678032`;
        window.open(url);
        handleCheckStatus(fileData.item);
      } else {
        toast.error("Please select the email");
      }
    } catch (error) {
      console.log("add signature", error);
    }
  };

  const [intervalId, setIntervalId] = React.useState(null);

  // useEffect(() => {
  //   // Cleanup on component unmount
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [intervalId]);

  const handleCheckStatus = (data) => {
    // Clear any existing interval
    if (intervalId) {
      clearInterval(intervalId);
    }

    const newIntervalId = setInterval(() => {
      checkSignatureStatus(data);
    }, 2000);

    setIntervalId(newIntervalId);

    console.log("GetPortalRequestDocuments_Json1133", newIntervalId);
  };

  const checkSignatureStatus = (e) => {
    console.log("GetPortalRequestDocuments_Json1133", intervalId);
    if (e.RequestDocType === 1) {
      CheckDocumentForSignature_Json(e, (data) => {
        console.log("GetPortalRequestDocuments_Json11", data, intervalId);
        if (data.length > 0) {
          if (data[0].RequestDocStatus === 1) {
            setIntervalId(null); // Clear the intervalId state
            if (docRe && docRe.DocTempID) {
              getTemplatesObject(docRe);
            }
            clearInterval(intervalId);
            return () => clearInterval(intervalId);
          }
        }
      });
    }
  };

  const addSignatureReady = (fileData) => {
    console.log("add signature22", fileData);
    try {
      if (
        redx.ToEmail &&
        redx.ToEmail.length > 0 &&
        redx.ToEmail[0]["E-Mail"]
      ) {
        let url = `https://Signing.docuengager.com/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sEmailId=${redx.ToEmail[0]["E-Mail"]}&sendemail=&clientname=${redx.Client.Client}&option=upload&DocTempId=${fileData.DocTempId}&DocId=${fileData.DocId}&to=${redx.ToEmail[0]["E-Mail"]}&template=${selectedTemplateObj.DocTempID}&rwndrnd=0.8166129123678032`;
        window.open(url);
        handleCheckStatus(fileData);
      } else {
        toast.error("Please select the email");
      }
    } catch (error) {
      console.log("add signature", error);
    }
  };

  const addUploadForm = (fileData) => {
    try {
      console.log("add UploadForm", fileData);
    } catch (error) {
      console.log("add UploadForm", error);
    }
  };
  const [closeBox, setCloseBox] = useState(false);
  const handleCloseclose = () => {};

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const checkRequestDocType = (
  //   data,
  //   handleChangeSelectFileSignature,
  //   handleChangeSelectFileUploadForm,
  //   selectedFile,
  //   selectedFileForm,
  //   base64Signature,
  //   base64UploadForm,
  //   addSignature,
  //   addUploadForm,
  //   addSignatureReady
  // ) => {
  //   let isType = '';

  //   try {
  //     if (data) {
  //       console.log('checkRequestDocType', selectedFile, selectedFileForm);
  //       switch (data.RequestDocType) {
  //         case 0:
  //           isType = '';
  //           break;
  //         case 1:
  //           if (data.SignObj) {
  //             let igmname = `${data.SignObj.DocName}${data.SignObj.DocExtension}`
  //             isType = (
  //               <Box className='d-flex justify-content-end justify-content-center'>
  //                 <VerifiedIcon sx={{ color: "green" }}></VerifiedIcon>
  //                 <SimpleFileUpload
  //                   comFun={handleChangeSelectFileSignature}
  //                   fileName={igmname}
  //                   accept={".pdf,.PDF"}
  //                   Type={"pdf"}
  //                 />
  //                 <Box>
  //                   <Button
  //                     variant='contained'
  //                     className='btn-blue'
  //                     size='small'
  //                     startIcon={<DrawIcon />}
  //                     onClick={() => addSignatureReady(data)}
  //                   >
  //                     Signature
  //                   </Button>
  //                 </Box>
  //               </Box>
  //             );
  //           }
  //           else {
  //             isType = (
  //               <Box className='d-flex justify-content-end justify-content-center'>
  //                 <SimpleFileUpload
  //                   comFun={handleChangeSelectFileSignature}
  //                   fileName={selectedFile ? selectedFile.name : ""}
  //                   accept={".pdf,.PDF"}
  //                   Type={"pdf"}

  //                 />
  //                 <Box>
  //                   <Button
  //                     variant='contained'
  //                     className='btn-blue'
  //                     size='small'
  //                     startIcon={<DrawIcon />}
  //                     onClick={() => addSignature(selectedFile ? selectedFile : data)}
  //                   >
  //                     Signature
  //                   </Button>
  //                 </Box>
  //               </Box>
  //             );
  //           }

  //           break;
  //         case 2:
  //           if (data.formReady) {

  //             let igmname = `${data.formReady.DocName}${data.formReady.DocExtension}`
  //             isType = (
  //               <Box className='d-flex justify-content-end justify-content-center'>
  //                 <VerifiedIcon sx={{ color: "green" }}></VerifiedIcon>
  //                 <SimpleFileUpload
  //                   comFun={handleChangeSelectFileUploadForm}
  //                   fileName={igmname}
  //                   accept={".xlsx,.xls,.doc,.docx"}
  //                   Type={"doc"}
  //                 //closeBtn={false}
  //                 />

  //               </Box>
  //             );
  //           }
  //           else {
  //             isType = (
  //               <Box className='d-flex justify-content-end justify-content-center'>
  //                 <SimpleFileUpload
  //                   comFun={handleChangeSelectFileUploadForm}
  //                   fileName={selectedFileForm ? selectedFileForm.name : ''}
  //                   accept={".xlsx,.xls,.doc,.docx"}
  //                   Type={"doc"}
  //                 //closeBtn={true}
  //                 // closeBox={false}
  //                 />

  //               </Box>
  //             );
  //           }

  //           break;
  //         case 3:
  //           isType = '';
  //           break;
  //         default:
  //           isType = '';
  //       }
  //     } else {
  //       return '';
  //     }
  //   } catch (error) {
  //     console.log('checkRequestDocType', error);
  //   }
  //   return isType;
  // };

  // modal
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    dispatch(setRequestDocument_Redux(""));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    console.log("Hello click", panel, event, isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  // Function to format the date to "dd/mm/yyyy"
  function formatDate(num) {
    const currentDate = new Date();
    const reminderDate = new Date(currentDate);
    reminderDate.setDate(reminderDate.getDate() + num);

    const day = String(reminderDate.getDate()).padStart(2, "0");
    const month = String(reminderDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = reminderDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const HandleClickSignature = (fileData) => {
    try {
      if (
        redx.ToEmail &&
        redx.ToEmail.length > 0 &&
        redx.ToEmail[0]["E-Mail"]
      ) {
        console.log("add signature", fileData);
        let url = `https://Signing.docuengager.com/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sEmailId=${redx.ToEmail[0]["E-Mail"]}&sendemail=&clientname=${redx.Client.Client}&option=upload&DocTempId=${fileData.DocTempId}&DocId=${fileData.DocId}&to=${redx.ToEmail[0]["E-Mail"]}&template=${fileData.DocTempId}&rwndrnd=0.8166129123678032`;
        window.open(url);
        //handleCheckStatus(fileData.item)
      } else {
        toast.error("Please select the email");
      }
    } catch (error) {
      console.log("add signature", error);
    }
  };

  function byteArrayToBase64(byteArray) {
    let binaryString = "";
    for (let i = 0; i < byteArray.length; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }
    return btoa(binaryString);
  }

  const HandleClickUploadDocument = (value) => {
    try {
      console.log("upload document", value);
    } catch (error) {
      console.log("handle change error", error);
    }
  };

  const HanleChangeUploadFile = (value) => {
    try {
      console.log("upload document", value);
    } catch (error) {
      console.log("handle change error", error);
    }
  };

  let uniq = {};

  const [openView, setOpenView] = useState(false);
  const [base64Data, setBase64Data] = useState("");
  const [fileType, setFileType] = useState("");

  const handleClickDownload = (data, fileType) => {
    try {
      console.log("base 64 and type", data, fileType);

      if (data) {
        //const byteArray = new Uint8Array(data);
        const base64 = data; //byteArrayToBase64(byteArray);

        console.log("base 64 and type", fileType, base64);
        setBase64Data(base64);
        setFileType(fileType);
        if (fileType)
          if (
            fileType === "xlsx" ||
            fileType === "doc" ||
            fileType === "docx"
          ) {
            // Check if the file type is an Excel or Word document
            // Trigger direct download
            let xlx = "vnd.ms-excel";
            let msword = "msword";
            let fix = fileType === "xlsx" ? xlx : msword;
            const link = document.createElement("a");
            link.href = `data:application/${fix};base64,${base64}`;
            link.download = `downloaded_file.${fileType}`; // Set the desired filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            // For other file types, open the dialog
            setOpenView(true);
            //setBase64Data(base64);
            //  setFileType(fileType);
          }
      }
    } catch (error) {
      console.log("Download failed:", error);
    }
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  return (
    <div>
      <DounloadFile
        open={openView}
        onClose={handleCloseView}
        base64Data={base64Data}
        fileType={fileType}
      />

      <h2 className="title mt-4 mb-3 font-20">Request Documents</h2>
      <Box className="d-flex align-items-center">
        {/* <AutoComplete ComData={main.PortalDocReqTemplatesLiset} target="TemplateName" comFun={(e, v) => getTemplatesObject(v)} defaultValue={docRe && Object.keys(docRe).length !== 0 && docRe && docRe.TemplateName ? docRe.TemplateName : ""} lable={'Select Template'} className='font-12' /> */}
        <AutoComplete
          ComData={main.PortalDocReqTemplatesLiset.filter(
            (obj) => !uniq[obj.TemplateName] && (uniq[obj.TemplateName] = true)
          )}
          target="TemplateName"
          comFun={(e, v) => getTemplatesObject(v)}
          defaultValue={
            docRe &&
            Object.keys(docRe).length !== 0 &&
            docRe &&
            docRe.TemplateName
              ? docRe.TemplateName
              : ""
          }
          lable={"Select Template"}
          className="font-12"
        />
        <Box>
          <Button
            className="btn-blue ms-2"
            variant="contained"
            startIcon={<CreateIcon />}
            onClick={handleClickOpenModal}
          >
            Edit
          </Button>
        </Box>

        <Box className="ms-2 d-flex">
          <Button
            className="btn-blue me-2"
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddCircleOutlineIcon />}
          >
            Create New Template
          </Button>
          <Button
            className="btn-blue"
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Request
          </Button>

          {/* <AutoComplete ComData={RequestDoc ? RequestDoc : ""} target="TemplateName" comFun={(e, v) => getTemplatesObject(v)} defaultValue={Object.keys(docRe).length !== 0 && docRe.TemplateName ? docRe.TemplateName : ""} lable={'Select Template'} className='font-12' />
        <Box className='ms-3'>
          <Button className='btn-blue' variant='contained' onClick={handleClickOpen} startIcon={<CreateIcon />}>Create New Template</Button>
        */}
        </Box>
      </Box>

      <Box className="mt-3">
        {/* <Box className='mt-4'>
          <Grid item xs={12}>
            <CheckboxesMultiSelectTags label={'Select Template'} />
          </Grid>
        </Box> */}

        <div className="main-accordian main-accordian-2">
          {requestDocuments && requestDocuments.length > 0
            ? requestDocuments.map((item, index) => {
                const panelId = `panel${index + 1}`;
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === panelId}
                    onChange={handleChange(panelId)}
                    className="accordian-box"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${panelId}-content`}
                      id={`${panelId}-header`}
                    >
                      <Tooltip title="For Info" arrow>
                        <Button size="small" className="min-width-auto me-1">
                          <InfoIcon className="font-18 text-black" />
                        </Button>
                      </Tooltip>

                      <Typography>{item.DocName}</Typography>

                      {item.SignTemplateId && (
                        <Tooltip title="" arrow>
                          <Button size="small" className="min-width-auto ms-2">
                            <VerifiedIcon className="font-20 text-success" />
                          </Button>
                        </Tooltip>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="d-flex justify-content-end pt-2">
                        <Grid container rowSpacing={0} columnSpacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="border-end"
                          >
                            <span>Expire In:</span>
                            <span className="badge rounded-pill bg-light-primary poppins-medium pt-1">
                              {formatDate(item.ExpiryInDays)}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                          >
                            <span>Remind In:</span>
                            <span className="badge rounded-pill bg-light-primary poppins-medium pt-1">
                              {formatDate(item.RemindIn)}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <hr />
                            <Box className="mb-3">
                              {/* <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        size='small'
                        className='btn-blue me-2'
                        onClick={()=>HandleClickUploadDocument(item)}
                       
                      >
                        Upload file
                        <input type="file" onChange={HanleChangeUploadFile} hidden />
                      </Button> */}

                              <Box className="d-flex align-items-center">
                                {item.DocExt && (
                                  <>
                                    <GetFileType
                                      Type={item.DocExt ? item.DocExt : ""}
                                    ></GetFileType>
                                    <Typography className="ms-1 font-12 poppins-medium">
                                      {item.DocName
                                        ? item.DocName + "." + item.DocExt
                                        : ""}

                                      <DownloadForOfflineIcon
                                        onClick={() =>
                                          handleClickDownload(
                                            item.DocData,
                                            item.DocExt
                                          )
                                        }
                                      ></DownloadForOfflineIcon>
                                    </Typography>
                                  </>
                                )}
                              </Box>

                              {item.RequestDocType === 1 && (
                                <Button
                                  component="label"
                                  role={undefined}
                                  variant="contained"
                                  tabIndex={-1}
                                  startIcon={<DrawIcon />}
                                  size="small"
                                  className="btn-blue"
                                  onClick={() => HandleClickSignature(item)}
                                >
                                  Signature
                                </Button>
                              )}
                            </Box>
                            {item.FileName && (
                              <Box
                                sx={{
                                  border: "1px solid #f1f1f1",
                                  boxShadow: "none",
                                }}
                                className="white-box d-flex align-items-center justify-content-between p-2"
                              >
                                <Box className="d-flex">
                                  <img
                                    src={pdf}
                                    style={{ width: 30 }}
                                    alt="documents"
                                    className="me-2"
                                  />
                                  <Typography className="d-flex align-items-center font-13 poppins-semibold">
                                    {item.FileName}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Tooltip title="Close">
                                    <Button
                                      className="ms-2 min-width-auto"
                                      size="small"
                                    >
                                      <CloseIcon className="text-red font-20" />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                        <hr />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : null}
        </div>

        {/* <Box className="table-responsive">
          {requestDocuments && requestDocuments.length > 0 ? (
            <table className='table table-striped mb-0 custom-table'>
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th className='text-center'>Expire In</th>
                  <th className='text-center'>Remind In</th>
                  <th className='text-end'>Doc Type</th>
                  <th className='text-end'>Attachment</th>
                </tr>
              </thead>
              <tbody>
                {requestDocuments && requestDocuments.length > 0 ? requestDocuments.map((item, index) => (
                  <tr key={index}>
                    <td>{item.DocName}</td>
                    <td className='text-end'>{item?.ExpiryInDays}</td>
                    <td className='text-end'>{item?.RemindIn}</td>
                    <td className='text-end'>{item?.RequestDocType}</td>
                    <td className='text-end'>
                      <Box className='d-flex justify-content-end'>
                        <RequestDocTypeComponent
                          data={item}
                          handleChangeSelectFileSignature={(file) => handleChangeSelectFileSignature(item, file)}
                          handleChangeSelectFileUploadForm={(file) => handleChangeSelectFileUploadForm(item, file)}
                          handleChangeSelectFileUploadFormRquest={(file) => handleChangeSelectFileUploadFormRquest(item, file)}
                          selectedFile={selectedFiles[item.DocName]?.file}

                          selectedFileForm={selectedFilesForm[item.DocName]?.file}
                          selectedFileFormReuest={selectedFileFormReuest[item.DocName]?.file}

                          addSignature={() => addSignature(selectedFiles[item.DocName])}

                          addUploadForm={() => addUploadForm(selectedFilesForm[item.DocName])}

                          addSignatureReady={() => addSignatureReady(item)}
                        />
                      </Box>
                    </td>
                  </tr>
                )) : null}
              </tbody>
            </table>
          ) : <NoData message='No Documents here' />}

        </Box> */}

        {/* <Box className='py-2 text-end'>
          <Button variant="contained" className='btn-blue me-3'>
            Create Request
          </Button>
        </Box> */}
      </Box>

      {/* modal */}

      <Dialog
        // sx={{ maxWidth: '1600px', width: '100%' }}
        maxWidth="lg"
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-request-document modal-lg"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Create New Template"}</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleCloseModal} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateTemplate
              isHideTitle={false}
              selectedTemplate={selectedTemplate}
            />
          </DialogContentText>

          {/* <DialogActions className='pt-4 px-0'>
            <Button onClick={handleCloseModal} className='btn-red' variant="outlined">Cancel</Button>
            <Button onClick={handleCloseModal} className='btn-blue' autoFocus variant="outlined">
              Save Settings
            </Button>
          </DialogActions> */}
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal modal-request-document modal-lg"
        maxWidth="lg"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Create New Template"}</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleClose} autoFocus>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CreateTemplate isHideTitle={false} />
          </DialogContentText>

          {/* <DialogActions className='pt-4 px-0'>
            <Button onClick={handleClose} className='btn-red' variant="outlined">Cancel</Button>
            <Button onClick={handleClose} className='btn-blue' autoFocus variant="outlined">
              Save Settings
            </Button>
          </DialogActions> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RequestDocuments;
