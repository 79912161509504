import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import { RequestedDocumentAsByteArray_Json_Redux } from "../../../redux/api_helper";

import moment from "moment";
import csv from "../../../assets/images/files-icon/csv.png";
import doc from "../../../assets/images/files-icon/doc.png";
import jpg from "../../../assets/images/files-icon/jpg.png";
import pdf from "../../../assets/images/files-icon/pdf.png";
import png from "../../../assets/images/files-icon/png.png";
import powerpoint from "../../../assets/images/files-icon/powerpoint.png";
import text from "../../../assets/images/files-icon/text.png";
import xls from "../../../assets/images/files-icon/xls.png";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { setOpenApproveModal } from "../../../redux/modalSlice";
import {
  GetStatusChipRequests,
  GetStatusRequests,
  UserStatusClass,
} from "../../../utils/RequestsFunction";
import UserRequestButtons from "./UserRequestButtons";

// import pdf from "../../assets/images/files-icon/pdf.png";

const UserRenderRequest = ({ accordionItem, id }) => {
  const [loading, setLoading] = React.useState(false);
  let objReq = useLocalStorage("accid");
  const [expanded, setExpanded] = React.useState(false);
  const [getRequestDetails_test, setRequestDetails] = React.useState([]);

  const dispatch = useDispatch();

  let newObject = {
    ...objReq,
    accId: objReq.accid,
    username: objReq.EmailId,
    sPassword: id,
    filterBy: "All",
  };
  delete newObject.accid;

  const fileDownload = (data) => {
    let newObjectFile = {
      ...objReq,
      accId: objReq.accid,
      username: data.EmailId,
      template: data.DocTempId,
      docid: data.DocId,
    };
    delete newObjectFile.accid;

    dispatch(
      RequestedDocumentAsByteArray_Json_Redux(newObjectFile, (res) => {
        if (res) {
          const base64 = `data:text/plain;base64,${res}`;
          // Ensure the extension starts with a dot
          const filename = `${data.DocName}${
            data.DocExtension.startsWith(".")
              ? data.DocExtension
              : `.${data.DocExtension}`
          }`;

          // Split the base64 string and extract mime type and data
          const [mimePart, dataPart] = base64.split(",");
          const mimeType = mimePart.match(/:(.*?);/)[1];

          // Convert the Base64 data into a Uint8Array
          const byteArray = new Uint8Array(
            atob(dataPart)
              .split("")
              .map((char) => char.charCodeAt(0))
          );

          // Create a Blob and a download link
          const blob = new Blob([byteArray], { type: mimeType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.click();

          // Clean up the link
          link.remove();
        } else {
          console.log("File not found");
        }
      })
    );
  };

  return (
    accordionItem &&
    accordionItem.length > 0 &&
    accordionItem.map((data, i) => (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        xxl={6}
        className={`d-flex ${UserStatusClass(data)}`}
        key={i}
      >
        <Box
          className={`mb-0 white-box main-details-box w-100 ${UserStatusClass(
            data
          )}`}
        >
          <Box className="d-flex align-items-center justify-content-between">
            <h2 className="poppins-medium font-12">{data?.DocName}</h2>

            <Box className="d-flex ms-2 align-items-center">
              {GetStatusRequests(data.Status)}

              {GetStatusChipRequests(data.RequestDocStatus, data.StatusName)}
            </Box>
          </Box>

          <hr />

          {data?.RequestDocStatus != "2" && (
            <Box className="details-box-row d-flex flex-wrap mb-1">
              {/* <Typography className="title">Details</Typography> */}
              {data?.Individual && (
                <Box className="details-box">
                  <Typography className="poppins-medium mb-1">
                    Uploaded by
                  </Typography>
                  <Typography className="font-12 badge text-bg-info">
                    <PersonIcon className="font-18" /> {data?.Individual}{" "}
                  </Typography>
                </Box>
              )}

              {data?.UploadedDate && (
                <Box className="details-box">
                  <Typography className="poppins-medium mb-1">
                    Uploaded on
                  </Typography>
                  <Typography className="font-12 badge text-bg-primary">
                    <CalendarMonthIcon className="font-18" />
                    {data?.UploadedDate
                      ? moment(data.UploadedDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
              )}
              {data?.IsApproved === "True" && (
                <Box className="details-box">
                  <Typography className="poppins-medium mb-1">
                    Approved date
                  </Typography>
                  <Typography className="font-12 badge text-bg-success">
                    {" "}
                    <CalendarMonthIcon className="font-18" />
                    {data?.ApprovalDate
                      ? moment(data.ApprovalDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
              )}
              {data?.IsApproved && (
                <Box className="details-box">
                  <Typography className="poppins-medium mb-1">
                    Approved
                  </Typography>
                  {data?.IsApproved === "True" ? (
                    <Typography className="font-12 badge text-bg-success">
                      <CheckCircleIcon className="font-18" />
                      Yes
                    </Typography>
                  ) : (
                    <Typography className="font-12 badge text-bg-danger">
                      <CheckCircleIcon className="font-18" />
                      No
                    </Typography>
                  )}
                </Box>
              )}
              {data?.IsApproved === "True" && data?.ApprovedByUser && (
                <Box className="details-box">
                  <Typography className="poppins-medium mb-1">
                    Approved by
                  </Typography>
                  <Typography className="font-12 badge text-bg-success">
                    <VerifiedUserIcon className="font-18" />{" "}
                    {data?.ApprovedByUser}
                  </Typography>
                </Box>
              )}

              {/* <Box>
                      <Typography className="poppins-medium mb-1">
                        {" "}
                        data.Status : {data.Status}
                      </Typography>

                      <Typography className="poppins-medium mb-1">
                        {" "}
                        data.RequestDocStatus : {data.RequestDocStatus}
                      </Typography>
                      <Typography className="poppins-medium mb-1">
                        {" "}
                        data.DocTempId : {data.DocTempId}
                      </Typography>
                      <Typography className="poppins-medium mb-1">
                        {" "}
                        data.DocId : {data.DocId}
                      </Typography>
                    </Box> */}

              {data?.ExpiryDate && (
                <Box className="details-box">
                  <Typography className="poppins-medium mb-1">
                    Expiry date
                  </Typography>
                  <Typography className="font-12 badge text-bg-danger">
                    <CalendarMonthIcon className="font-18" />
                    {data?.ExpiryDate
                      ? moment(data.ExpiryDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          
          {/* render document */}
          <Box>
            {loading ? "Loading..." : ""}
            {data?.DocExtension && data?.DocName && (
              <Box
                sx={{
                  border: "1px solid #f1f1f1",
                  boxShadow: "none",
                }}
                className="white-box d-flex align-items-center justify-content-between p-2 mb-3"
              >
                <Box className="d-flex align-items-center">
                  {data?.DocExtension === ".pdf" ||
                  data?.DocExtension === ".PDF" ? (
                    <img
                      src={pdf}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".png" ||
                    data?.DocExtension === ".PNG" ? (
                    <img
                      src={png}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".docx" ||
                    data?.DocExtension === ".DOCX" ? (
                    <img
                      src={doc}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".xlsx" ||
                    data?.DocExtension === ".xls" ? (
                    <img
                      src={xls}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".jpg" ||
                    data?.DocExtension === ".jpeg" ? (
                    <img
                      src={jpg}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".text" ? (
                    <img
                      src={text}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".csv" ||
                    data?.DocExtension === ".CSV" ? (
                    <img
                      src={csv}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : data?.DocExtension === ".pptx" ||
                    data?.DocExtension === ".PPTX" ? (
                    <img
                      src={powerpoint}
                      style={{ width: 30 }}
                      alt="documents"
                      className="me-2"
                    />
                  ) : (
                    <PictureAsPdfIcon sx={{ marginRight: "10px" }} />
                  )}
                  {/* <PictureAsPdfIcon sx={{ marginRight: "10px" }} /> */}

                  <Typography className="poppins-medium font-12">
                    {data?.DocExtension
                      ? `${data.DocName}${
                          data.DocExtension.startsWith(".")
                            ? data.DocExtension
                            : `.${data.DocExtension}`
                        }`
                      : ""}
                  </Typography>
                </Box>

                <Box className="d-flex">
                  {}
                  {["3", "4", "5", "6", "7"].includes(
                    data?.RequestDocStatus
                  ) ? (
                    <Tooltip title="Download">
                      <Button
                        className="min-width-auto ms-2 text-bg-info"
                        size="small"
                        onClick={() => fileDownload(data)}
                      >
                        <DownloadIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {data?.RequestDocStatus && data?.RequestDocStatus === "3" ? (
                    <Tooltip title="Approve">
                      <Button
                        className="min-width-auto ms-2 text-bg-primary"
                        size="small"
                        onClick={() =>
                          dispatch(
                            setOpenApproveModal({
                              id: id,
                              type: "a",
                              data: data,
                              isOpen: true,
                            })
                          )
                        }
                      >
                        <ThumbUpIcon className=" font-20" />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {data?.Status !== "3" && data?.RequestDocStatus === "3" ? (
                    <Tooltip title="Disapprove">
                      <Button
                        className="min-width-auto ms-2 text-bg-danger"
                        size="small"
                        onClick={() =>
                          dispatch(
                            setOpenApproveModal({
                              id: id,
                              type: "d",
                              data: data,
                              isOpen: true,
                            })
                          )
                        }
                      >
                        <ThumbDownAltIcon className="font-20" />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            )}

            {data?.notes && (
              <Box container spacing={0}>
                <Box
                  item
                  sm={12}
                  sx={{
                    background: "#fff",
                    borderRadius: "8px",
                    // padding: "20px",
                    // maxHeight:'100px'
                    maxHeight: "100px",
                    overflow: "auto",
                  }}
                >
                  <Box>
                    <Typography className="poppins-medium font-12 border-bottom pb-1 mb-1">
                      Reason for disapproval{" "}
                    </Typography>
                    <Typography className="font-12 text-">
                      {data?.notes}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <UserRequestButtons data={data} />
          </Box>
        </Box>
      </Grid>
    ))
  );
};

export default UserRenderRequest;
