import { Box, Chip, Grid, Tooltip, Typography, Button } from "@mui/material";
import GradingIcon from "@mui/icons-material/Grading";
import DrawIcon from "@mui/icons-material/Draw";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import InfoIcon from "@mui/icons-material/Info";
import UploadRequestDoc from "../components/RequestComponant/UploadRequestDoc";
import SignatureRequest from "../components/RequestComponant/SignatureRequest";
import FormFillRequest from "../components/RequestComponant/FormFillRequest";
import ReplaceRequestDoc from "../components/RequestComponant/ReplaceRequestDoc";
import dayjs from "dayjs";

import csv from "../../src/assets/images/files-icon/csv.png";
import doc from "../../src/assets/images/files-icon/doc.png";
import jpg from "../../src/assets/images/files-icon/jpg.png";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import png from "../../src/assets/images/files-icon/png.png";
import text from "../../src/assets/images/files-icon/text.png";
import xls from "../../src/assets/images/files-icon/xls.png";
import UpdateFormFill from "../components/RequestComponant/UpdateFormFill";
import { useParams, useSearchParams } from "react-router-dom";
import { portalUser } from "../redux/AxiosInstance";
import { GetAllSentMessages_Json_Redux } from "../redux/api_helper";
import { useDispatch } from "react-redux";
// import UpdateFormFill from "../components/RequestComponant/UpdateFormFill";

export const clientBodyaccIdData = {
  accId: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient"),
};

export const userBodyDAtaa = {
  accid: localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

export const userBodyaccId = {
  accId: localStorage.getItem("agrno"),
  email: localStorage.getItem("Email"),
  password: localStorage.getItem("password"),
};

export const clientLoginStatus = localStorage.getItem("LoginClient");

export const clientBodyaccidData = {
  accid: localStorage.getItem("accIdClient"),
  username: localStorage.getItem("EmailClient"),
  sPassword: localStorage.getItem("passwordClient"),
};

export const GetStatusChipRequests = (res, requestStatus, statusForinfo) => {
  if (statusForinfo && statusForinfo == 3) {
    return <></>;
  } else {
    switch (res) {
      case "1":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#0000FF" }}
            className="chips"
          />
        );
      case "2":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#eea706" }}
            className="chips"
          />
        );
      case "3":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#008000" }}
            className="chips"
          />
        );
      case "4":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#008000" }}
            className="chips"
          />
        );
      case "5":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#195e83" }}
            className="chips"
          />
        );
      case "6":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#FF0000" }}
            className="chips"
          />
        );
      case "7":
        return (
          <Chip
            label={requestStatus}
            size="small"
            sx={{ background: "#A52A2A" }}
            className="chips"
          />
        );
      default:
        return <></>;
    }
  }
};

export const GetStatusRequests = (data) => {
  switch (true) {
    case data && data.Status == "0":
      return (
        <Tooltip title="Request Document" arrow>
          <Button size="small" className="min-width-auto">
            <GradingIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    case data && data.Status == "1":
      return (
        <Tooltip title="Request Signature" arrow>
          <Button size="small" className="min-width-auto">
            <DrawIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    case data && data.Status == "2":
      return (
        <Tooltip title="Send Form" arrow>
          <Button size="small" className="min-width-auto">
            <MarkEmailReadIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    case data && data.Status == "3":
      return (
        <Tooltip title="For Info" arrow>
          <Button size="small" className="min-width-auto">
            <InfoIcon className="font-18 text-black" />
          </Button>
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export const getStatusBaseButtonRequests = (
  accordionIndex,
  accordionItem,
  setPreview
) => {
  if (
    accordionItem &&
    accordionItem.Status == "0" &&
    accordionItem.RequestDocStatus == "3"
  ) {
    return (
      <ReplaceRequestDoc
        accordionItem={accordionItem}
        setPreview={setPreview}
      />
    );
  } else {
    switch (true) {
      case accordionItem &&
        ((accordionItem.Status == "0" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "0" &&
            accordionItem.RequestDocStatus == "5") ||
          (accordionItem.Status == "0" &&
            accordionItem.RequestDocStatus == "7")):
        return (
          <UploadRequestDoc
            accordionItem={accordionItem}
            setPreview={setPreview}
          />
        );

      case accordionItem &&
        ((accordionItem.Status == "1" &&
          accordionItem.RequestDocStatus == "2") ||
          (accordionItem.Status == "1" &&
            accordionItem.RequestDocStatus == "5")):
        return <SignatureRequest accordionItem={accordionItem} />;
      case accordionItem &&
        accordionItem.Status == "2" &&
        accordionItem.RequestDocStatus == "2":
        return <FormFillRequest accordionItem={accordionItem} />;

      case accordionItem &&
        ((accordionItem.Status == "2" &&
          accordionItem.RequestDocStatus == "5") ||
          (accordionItem.Status == "2" &&
            accordionItem.RequestDocStatus == "3")):
        return <UpdateFormFill accordionItem={accordionItem} />;

      default:
        return <></>;
    }
  }
};

export const UserStatusClass = (data) => {
  switch (true) {
    case data.RequestDocStatus == 1:
      return "approved";
    case data.RequestDocStatus == 2:
      return "pending";
    case data.RequestDocStatus == 3:
      return "approved";
    case data.RequestDocStatus == 4:
      return "approved";
    case data.RequestDocStatus == 5:
      return "rejected";
    case data.RequestDocStatus == 6:
      return "expiring";
    case data.RequestDocStatus == 7:
      return "expired";
    default:
      return "pending";
  }
};

export const GetDocTypeIconRequests = (target) => {
  const normalizedExtension = target.toLowerCase();

  switch (normalizedExtension) {
    case ".pdf":
      return <img src={pdf} style={{ width: 36 }} alt="documents" />;
    case ".png":
      return <img src={png} style={{ width: 36 }} alt="documents" />;
    case ".docx":
      return <img src={doc} style={{ width: 36 }} alt="documents" />;
    case ".xlsx":
    case ".xls":
      return <img src={xls} style={{ width: 36 }} alt="documents" />;
    case ".jpg":
    case ".jpeg":
      return <img src={jpg} style={{ width: 36 }} alt="documents" />;
    case ".text":
      return <img src={text} style={{ width: 36 }} alt="documents" />;
    case ".csv":
      return <img src={csv} style={{ width: 36 }} alt="documents" />;
    default:
      return null;
  }
};

export const GetDocTypeIcon2Requests = (target) => {
  switch (target) {
    case ".pdf":
      return pdf;
    case ".txt":
      return text;
    case ".docx":
      return doc;
    case ".jpg":
      return jpg;
    case ".jpeg":
      return jpg;
    case ".png":
      return png;
    case ".xls":
      return xls;
    case ".csv":
      return csv;
    default:
      return text;
  }
};

export const GetrequestTime = () => {
  const timestamp = parseInt("/Date(1731427920000)/".match(/\d+/)[0], 10);

  // Format the date using Day.js
  const formattedDate = dayjs(timestamp).format("ddd DD MMM YYYY [at] HH:mm");
  return formattedDate;
};

export const convertToDateFormat = (dateString) => {
  if (!dateString) return ""; // Handle null or undefined cases gracefully

  // Extract the timestamp from the /Date(...) format
  const timestamp = parseInt(dateString.match(/\d+/)[0], 10);

  // Format the date using Day.js
  const formattedDate = dayjs(timestamp).format("DD/MM/YYYY");
  return formattedDate;
};

export const portalUserExcuteAPI = async (route, payload) => {
  try {
    let res = await portalUser.post(route, payload);

    if (res) {
      // console.log(res?.data, "========= res.data >>>>");

      const data = res?.data?.d;

      // Handle cases where `d` is an empty string or empty object
      if (
        data === "" ||
        (typeof data === "object" && Object.keys(data).length === 0)
      ) {
        return true;
      }

      // Check if `data` is a string and parse it as JSON
      if (typeof data === "string") {
        try {
          const parsedData = JSON.parse(data);
          return parsedData;
        } catch (parseError) {
          console.error("Failed to parse string as JSON:", parseError);
          throw new Error("Invalid JSON string received.");
        }
      }

      // If `data` is neither a string nor empty, return it as is
      return data;
    }
  } catch (error) {
    console.error(
      "Network error: No response received from server, RequestedDocumentCreated_Json",
      error
    );
    throw error; // Re-throw error for further handling
  }
};


export const useGetUserSentMessage = () => {
  const dispatch = useDispatch();

  const fetchSentMessages = () => {
    let sentObj = {
      ...userBodyDAtaa,
      ccode: "",
      emailAddress: "",
    };
    dispatch(GetAllSentMessages_Json_Redux(sentObj, function (res) {}));
  };

  return fetchSentMessages;
};

export const RequestDownloadFile = (e, doc) => {
  e.preventDefault();

  if (doc && (doc.DocData || doc.DocData2)) {
    // Normalize the file extension
    let extension = doc.DocExtension.startsWith(".")
      ? doc.DocExtension.toLowerCase()
      : `.${doc.DocExtension.toLowerCase()}`;

    // Determine MIME type
    let mimeType = "application/octet-stream"; // default
    switch (extension) {
      case ".pdf":
        mimeType = "application/pdf";
        break;
      case ".docx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case ".xlsx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case ".xls":
        mimeType = "application/vnd.ms-excel";
        break;
    }

    // const byteArray = doc.DocData
    //   ? base64ToUint8Array(doc.DocData)
    //   : base64ToUint8Array(doc.DocData2);
    const byteArray = base64ToUint8Array(doc.DocData2);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = URL.createObjectURL(blob);

    // Format the filename to remove invalid characters and ensure the extension is correct
    const filename = `${doc.DocName.replace(
      /[^a-zA-Z0-9_\- ]+/g,
      ""
    )}${extension}`;

    // Trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } else {
    console.error("Document data is not available for download");
  }
};

const base64ToUint8Array = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};