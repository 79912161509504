import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, ListItemIcon, Typography } from "@mui/material";
import { ContentCut } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MenuList from "@mui/material/MenuList";
import SortIcon from "@mui/icons-material/Sort";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  ConvertToPdf_Json_Redux,
  GetSignatureCreationStatus_Json_Redux,
  Json_ExplorerSearchDoc_Redux,
  Json_GetItemBase64DataById_Redux,
  PrepareDocumentsForPublish_Json_Redux,
  RemoveFilesForUpload_Json_Redux,
} from "../../../redux/api_helper";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import DataGrid, {
  Column,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import GetFileType from "../../../components/FileType";
import { setAttachementTab_Redux } from "../../../redux/docuSlice";
import Swal from "sweetalert2";
import AddContact from "../../../assets/images/icons/01.png";
import NoData from "../../../components/NoData";
import { useRef } from "react";

const options = [
  "Signature",
  "Remove",
  "Rename",
  "Create Zip",
  "Convert to PDF",
];

const ITEM_HEIGHT = 48;

const agrno = localStorage.getItem("agrno");
const password = localStorage.getItem("password");
const FolderId = localStorage.getItem("FolderId");
const Email = localStorage.getItem("Email");
function Attachments() {
  let req = useLocalStorage("required");
  let accid = useLocalStorage("accid");

  let redx = useSelector((state) => state.docu.ComposePortalMgsObj);
  let redxAtt = useSelector((state) => state.docu.AttachmentTab);
  let mainDex = useSelector((state) => state.docu);
  let dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [intervalId, setIntervalId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickItem, setClickItem] = React.useState(null);
  const [anchorEls, setAnchorEls] = React.useState({}); // Store anchors per item

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //
  const [AttachmentanchorEl, setAttachmentAnchorEl] = React.useState(null);
  const AttachmentOpen = Boolean(AttachmentanchorEl);
  const AttachmenthandleClick = (event) => {
    setAttachmentAnchorEl(event.currentTarget);
  };
  const AttachmenthandleClose = () => {
    setAttachmentAnchorEl(null);
  };

  //
  const [FilePreviewopen, FilePreviewsetOpen] = React.useState(false);

  const FilePreviewhandleClickOpen = () => {
    FilePreviewsetOpen(true);
  };

  const FilePreviewhandleClose = () => {
    FilePreviewsetOpen(false);
  };

  const getFileExtension = (fileName) => {
    // Split the file name by the dot (.)
    const parts = fileName.split(".");
    // Return the last part, which is the extension
    return parts[parts.length - 1];
  };

  // React.useEffect(() => {
  //   clearInterval(intervalId);
  //   setIntervalId(null);
  // }, [intervalId]);

  React.useEffect(() => {
    clearInterval(intervalId);
    setIntervalId(null);

    if (redxAtt.length > 0) {
      setSelectedFiles(redxAtt);
    }
  }, []);

  React.useEffect(() => {
    dispatch(setAttachementTab_Redux(selectedFiles));
  }, [selectedFiles]);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const selectedFilesArray = Array.from(files);
    const filesData = [];
    let completeCounter = 0;
    selectedFilesArray.forEach((file, index) => {
      const reader = new FileReader();
      completeCounter++;
      reader.onload = () => {
        let fileByte = reader.result.split(";")[1].replace("base64,", "");
        const fileData = {
          FileName: file.name,
          Base64: fileByte ? fileByte : "", // Base64 data of the file
          FileSize: file.size,
          Preview: reader.result, // Data URL for preview
          DocId: "",
          Guid: mainDex.GUID ? mainDex.GUID : toast("GUID is blank"),
          FileType: getFileExtension(file.name).toLowerCase(),
          Status: false,
        };

        filesData.push(fileData);
        // Check if this is the last file
        if (index === selectedFilesArray.length - 1) {
          setSelectedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            ...filesData,
          ]);

          if (completeCounter === selectedFilesArray.length) {
            PrepareDocumentsForPublish_Json(filesData, 1);
          }
        }
      };
      reader.readAsDataURL(file); // Read file as data URL (base64)
    });
    setAnchorEl(null);
  };

  ////////////////////////////////////DMS Document
  const [documentLisdoc, setOpenDocumentList] = React.useState(false);
  const [dmsDocumentList, setDMSDocumentList] = React.useState([]);
  //const [allMode, setAllMode] = useState('allPages');
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [anchorSelectFileEl, setAnchorSelectFileEl] = React.useState(null);

  const openSelectFile = Boolean(anchorSelectFileEl);

  const handleClickSelectFile = (event) => {
    setAnchorSelectFileEl(event.currentTarget);
  };

  const handleSelectFileClose = () => {
    setAnchorSelectFileEl(null);
  };

  const handleDocumentClickOpen = () => {
    setAnchorEl(null);
    setDMSDocumentList([]);
    setAnchorSelectFileEl(null);
    if (redx.Client && redx.Client.ClientID) {
      Json_ExplorerSearchDoc();
      setOpenDocumentList(true);
    } else {
      toast.warn("Select Referece !");
    }
  };

  const Json_ExplorerSearchDoc = () => {
    let cid = redx.Client.ClientID;
    let fid = redx.Folder.FolderID;
    try {
      if (cid && fid) {
        let obj = {};
        obj.ProjectId = fid;
        obj.ClientId = cid;
        obj.sectionId = "-1";
        let p = { ...req, ...obj };
        dispatch(
          Json_ExplorerSearchDoc_Redux(p, function (data) {
            if (data) {
              let tble6 = data.Table6;
              setDMSDocumentList(tble6);
            }
          })
        );
      }
    } catch (error) {
      console.log("ExplorerSearchDoc", error);
    }
  };
  // Define the function to render cells based on the 'Type' column
  const renderTypeCell = (data) => {
    // // Define the condition based on which the icon will be rendered
    // if (data.value === 'pdf') {
    //   return <Tooltip title={data.value} placement="top-start"><PictureAsPdfIcon></PictureAsPdfIcon></Tooltip>;
    // } else if (data.value === 'txt') {

    //   return <Tooltip title={data.value} placement="top-start"><TextSnippetIcon></TextSnippetIcon></Tooltip>;
    // }
    // // You can add more conditions or return default content if needed
    // return <Tooltip title={data.value} placement="top-start">{data.value}</Tooltip>;
    return <GetFileType Type={data.value.toLowerCase()}></GetFileType>;
  };
  const pageSizes = [10, 25, 50, 100];

  const handleCloseDocumentList = () => {
    setOpenDocumentList(false);
  };

  const handleSelectionChanged = (selectedItems) => {
    setSelectedRows(selectedItems.selectedRowsData);
  };

  const AddDocuments = () => {
    let filesData = [];

    let completedOperations = 0;
    selectedRows.forEach((row, index) => {
      Json_GetItemBase64DataById(row, function (base64data) {
        const fileData = {
          FileName: row.Description + "." + row.Type,
          Base64: base64data ? base64data : "", // Base64 data of the file
          FileSize: "",
          Preview: "", // Data URL for preview
          DocId: row["Registration No."],
          Guid: mainDex.GUID ? mainDex.GUID : toast("GUID is blank"),
          FileType: row["Type"].toLowerCase(),
          Description: row.Description,
          Status: false,
        };
        filesData.push(fileData);
        completedOperations++; // Increment the completed operations counter
        // Check if all operations are completed
        if (completedOperations === selectedRows.length) {
          // Add new files to the uploadedFiles array
          setSelectedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            ...filesData,
          ]);

          PrepareDocumentsForPublish_Json(filesData, 2);
          setOpenDocumentList(false);
        }
      });
    });
  };

  function Json_GetItemBase64DataById(item, callBack) {
    try {
      let obj = {};
      obj.ItemId = item["Registration No."];
      let p = { ...req, ...obj };
      dispatch(
        Json_GetItemBase64DataById_Redux(p, function (data) {
          if (data) {
            if (data !== "No Data Exist") {
              return callBack(data);
            } else {
            }
          }
        })
      );
    } catch (error) {
      console.log("Json_GetItemBase64DataById error", error);
    }
  }

  function PrepareDocumentsForPublish_Json(filedata, ids) {
    try {
      const ItemId = filedata.map((obj) => obj.DocId);
      const fileNames = filedata.map((obj) => obj["FileName"]);
      const fileDataBase64 = filedata
        .filter((obj) => obj["Base64"] !== "")
        .map((obj) => obj["Base64"]);
      let o = {};
      o.uploadID = mainDex.GUID;
      o.filenames = fileNames;
      o.attachments = fileDataBase64;
      o.itemNos = ids === 1 ? null : ItemId;
      let p = { ...accid, ...o };
      dispatch(
        PrepareDocumentsForPublish_Json_Redux(p, (data) => {
          if (data) {
            console.log("PrepareDocumentsForPublish_Json", data);
            if (data === "Success") {
            }
          }
        })
      );
    } catch (error) {
      console.log({
        status: false,
        message: "Attachment is Not Uploaded Try again",
        error: error,
      });
    }
  }

  const SigningMethods = (e) => {
    try {
      setAttachmentAnchorEl(null);
  
      if (e.FileType === "pdf") {
        if (redx.ToEmail) {
          const ToEmail = redx.ToEmail.map((obj) => obj["E-Mail"]).join(",");
          const url = `https://signing.docusms.uk/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sendclient=${redx.Client.ClientID}&sendemail=&clientname=${redx.Client.Client}&option=upload&file=${agrno}-${mainDex.GUID}/${e.FileName}&to=${ToEmail}&rwndrnd=0.8166129123678032`;
  
          // Open the URL in a new window
          window.open(url, "_blank", "noopener,noreferrer");
          handleCheckStatus(e);
        } else {
          toast.error("Please select an email!");
        }
      } else {
        toast.error("Please convert the file to PDF.");
      }
    } catch (error) {
      console.error("Error in SigningMethods:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  
  const intervalRef = useRef(null);

  const handleCheckStatus = (data) => {
    // Clear existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  
    // Start a new interval
    intervalRef.current = setInterval(() => {
      GetSignatureCreateStatus(data);
    }, 5000);
  };
  
 
  function GetSignatureCreateStatus(e) {
    try {
      let o = { Id: mainDex.GUID, Name: e.FileName };
  
      dispatch(
        GetSignatureCreationStatus_Json_Redux(o, (data) => {
          if (data) {
            console.log("Response is true, clearing interval...");
            if (intervalRef.current) {
              clearInterval(intervalRef.current); // Clear interval
              intervalRef.current = null; // Reset the ref
            }
  
            // Update the status in selectedFiles
            const updatedFiles = selectedFiles.map((file) =>
              file.FileName === e.FileName ? { ...file, Status: true } : file
            );
            setSelectedFiles(updatedFiles);
          }
        })
      );
    } catch (error) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      console.error("Error:", error);
    }
  }
  

  const RemoveAttachment = (e) => {
    console.log(e, "============= e");

    try {
      let o = {
        accid: agrno,
        email: Email,
        password: password,
        uploadID: mainDex.GUID,
        filename: e.FileName,
      };

      setAttachmentAnchorEl(null);
      dispatch(
        RemoveFilesForUpload_Json_Redux(o, function (data) {
          if (data === "Success") {
            if (selectedFiles.length > 0) {
              let res = selectedFiles.filter(
                (el) => el.FileName !== e.FileName
              );
              setSelectedFiles(res);
            }
          } else {
            toast.error("Attachemnt not remove please try again !", e.FileName);
          }
        })
      );
    } catch (error) {
      console.log("Guid is blank", error);
    }
  };

  // Function to get file extension from file name
  const getFileName = (fileName) => {
    // Split the file name by the dot (.)
    const parts = fileName.split(".");
    // Return the last part, which is the extension
    return parts[0];
  };

  const FileType = (fileName) => {
    // for (var i = 0; i < fileName.length; i++) {
    let Typest = fileName.lastIndexOf(".");
    var Type = fileName.slice(Typest + 1);
    var type = Type.toUpperCase();
    return type;
  };

  function ConvertToPdf_Json(e) {
    try {
      let o = {
        Guid: mainDex.GUID,
        FileName: e.FileName,
      };
      dispatch(
        ConvertToPdf_Json_Redux(o, function (data) {
          if (data) {
            let fname = getFileName(data);
            let res = selectedFiles.map((file) => {
              if (getFileName(file.FileName) === fname) {
                return {
                  ...file,
                  FileName: data,
                  FileType: FileType(data).toLowerCase(),
                };
              } else {
                return file;
              }
            });
            setSelectedFiles(res);
            toast.success("Converted File !");
          }
        })
      );
      setAttachmentAnchorEl(null);
    } catch (error) {
      console.log({
        status: false,
        message: "Folder is Blank Try again",
        error: error,
      });
    }
  }

  function DeleteFile(e, d) {
    e.preventDefault();
    e.stopPropagation();

    console.log(d, "=========== d ");

    Swal.fire({
      // title: "Are you sure you want to delete this item?",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveAttachment(d);
      }
    });
  }

  const handleClickMenuItem = (item) => {
    console.log(item, "========== item");
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEls((prev) => ({ ...prev, [index]: event.currentTarget }));
  };

  const handleMenuClose = (index) => {
    setAnchorEls((prev) => ({ ...prev, [index]: null }));
  };

  const handleMenuItemClick = (action, index) => {
    action(); // Perform the specific action
    handleMenuClose(index); // Close the menu for the specific item
  };

  return (
    <Box>
      <Box className="py-4 d-flex align-items-center justify-content-center">
        <Box className="mt-3">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="btn-blue"
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Attachment
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className="custom-dropdown"
          >
            <MenuItem>
              <Box>
                <input
                  className="d-none"
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  id="fileUpload"
                />
                <label htmlFor="fileUpload" className="d-flex">
                  <ListItemIcon>
                    <CloudUploadIcon fontSize="small" />
                  </ListItemIcon>{" "}
                  Upload File
                </label>
              </Box>
            </MenuItem>

            <MenuItem onClick={handleDocumentClickOpen}>
              <ListItemIcon>
                <CloudUploadIcon fontSize="small" />
              </ListItemIcon>{" "}
              Import from DocuSoft
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <MenuList className="p-0">
        {selectedFiles.map((item, index) => (
          <MenuItem
            className="pe-2"
            key={index}
            onDoubleClick={FilePreviewhandleClickOpen}
          >
            {console.log(item,"============ item")}
            <ListItemIcon>
              <GetFileType Type={item.FileType} />
            </ListItemIcon>

            <Box>
              <Typography variant="body1" className="font-14">
                {item.FileName}
              </Typography>
              <Typography variant="body1" className="font-12">
                {item.FileSize}
              </Typography>
            </Box>

            <Box className="ms-auto">
              {item.Status ? (
                <span className="badge text-bg-primary">Ready</span>
              ) : (
                ""
              )}

              <IconButton
                aria-label="more"
                aria-controls={anchorEls[index] ? `menu-${index}` : undefined}
                aria-expanded={Boolean(anchorEls[index])}
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, index)}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                id={`menu-${index}`}
                anchorEl={anchorEls[index]}
                open={Boolean(anchorEls[index])}
                onClose={() => handleMenuClose(index)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {item.FileType === "pdf" && <MenuItem
                  onClick={() =>
                    handleMenuItemClick(() => SigningMethods(item), index)
                  }
                >
                  Signature
                </MenuItem>}
                <MenuItem
                  onClick={(e) =>
                    handleMenuItemClick(() => DeleteFile(e, item), index)
                  }
                >
                  Remove
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => {}, index)}>
                  Create Zip
                </MenuItem>
                {item.FileType && item.FileType.toLowerCase() !== "pdf" && (
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick(() => ConvertToPdf_Json(item), index)
                    }
                  >
                    Convert to PDF
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </MenuItem>
        ))}
      </MenuList>

      {/* <React.Fragment>
        <Button variant="outlined" onClick={FilePreviewhandleClickOpen}>
          Open alert dialog
        </Button>
        <Dialog
          open={FilePreviewopen}
          onClose={FilePreviewhandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"File Preview"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h2>File here</h2>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={FilePreviewhandleClose}>Disagree</Button>
            <Button onClick={FilePreviewhandleClose} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment> */}

      <Dialog
        open={documentLisdoc}
        // onClose={handleCloseDocumentList}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-modal"
        maxWidth="md"
      >
        <Box className="d-flex align-items-center justify-content-between modal-head">
          <Box className="clearfix">
            <DialogTitle>{"Document List"}</DialogTitle>
          </Box>

          {/*  */}
          <Button onClick={handleCloseDocumentList}>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DataGrid
              dataSource={dmsDocumentList}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              showBorders={true}
              width="100%"
              selectedRowKeys={selectedRows}
              selection={{ mode: "multiple" }}
              onSelectionChanged={handleSelectionChanged} // Handle selection change event
              className="table-grid"
            >
              <FilterRow visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />

              <Column
                dataField="Type"
                caption="Type"
                cellRender={renderTypeCell} // Render cells based on condition
                width={60}
              />

              <Column
                dataField="Description"
                caption="Description"
                width={300}
                cellRender={(data) => {
                  return (
                    <Tooltip
                      title={data.data.Description}
                      placement="top-start"
                    >
                      {data.data.Description}
                    </Tooltip>
                  );
                }}
              />
              <Column
                dataField="Section"
                caption="Section"
                cellRender={(data) => {
                  return (
                    <Tooltip title={data.data.Section} placement="top-start">
                      {data.data.Section}
                    </Tooltip>
                  );
                }}
              />
              {/* <Column
                                dataField="Client"
                                caption="Client"
                            /> */}
              <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
              <Paging defaultPageSize={10} />
            </DataGrid>

            {/* file upload end */}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="p-4 pt-3">
          <Button
            variant="contained"
            onClick={AddDocuments}
            className="btn-blue"
          >
            {"Add Document"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Attachments;
