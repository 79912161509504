import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
    Button, Checkbox, FormControlLabel, Grid, Link, TextField, Typography, Dialog, DialogContent, DialogContentText
} from "@mui/material";
import logo from "../assets/images/Docusoft-logo-red.svg";
import animation from "../assets/images/login.svg";
import { useNavigate } from "react-router-dom";
import DocuTextField from "../cmp-utils/DocuTextField";
import Copyright from "../components/Copyright";
import { toast } from "react-toastify";
import { docuSms } from "../redux/AxiosInstance";

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Email: '',
        password: ''
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log('data', name, value, formData);

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        let obj = {
            Email: formData.Email,
            password: btoa(formData.password),
        };
        LoginDetail(obj);
    };
    async function LoginDetail(obj) {
        try {
            const res = await docuSms.post('/Json_GetAgreementList', obj);
            
            if (res.data.d !== "Invalid") {
                const tbl = JSON.parse(res?.data?.d).Table;
                if (tbl.length > 0) {
                    localStorage.clear();
                    localStorage.setItem("Login", true);
                    localStorage.setItem("agrno", tbl[0].vAgreementNo);
                    localStorage.setItem("accid", tbl[0].vAgreementNo);
                    localStorage.setItem("IsAdmin", tbl[0].strIsAdmin);
                    localStorage.setItem("UserId", tbl[0].intUserId);
                    localStorage.setItem("Email", obj.Email);
                    localStorage.setItem("password", obj.password);
                    localStorage.setItem("companyName", tbl[0].vCustomerName);
                     localStorage.setItem("EmailId", 'patrick.docusoft@outlook.com');
                    GetAgreementList(obj, tbl[0].vAgreementNo);
                } else {
                }
            }else{
                toast.error("Invalid Credentials !!")
            }
        } catch (err) {
            console.log("Error while calling Json_GetAgreementList", err);
        }
    }
    const Json_getViewerToken = async (obj) => {
        try {
            const res = await docuSms.post('/Json_getViewerToken', obj);
            localStorage.setItem("ViewerToken", res?.data?.d);
            navigate("/user/portal-messages");
        } catch (err) {
            console.log("Error while calling Json_getViewerToken", err);
        }
    };
    const GetAgreementList = async (obj, agrno) => {
        obj.agrno = agrno;
        try {
            let res = await docuSms.post('/Json_GetAccessToken', obj);
            localStorage.setItem("FolderId", res?.data?.d);
            localStorage.setItem("ProjectId", res?.data?.d);
            Json_getViewerToken(obj);
        } catch (err) {
            console.log("Error while calling Json_GetAccessToken", err);
        }
    };
    const [openForgotPassord, setOpenForgotPassord] = React.useState(false);
    const handleClickOpenForgotPassord = () => {
        setOpenForgotPassord(true);
    };
    const handleCloseForgotPassord = () => {
        setOpenForgotPassord(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    };
    return (
        <Box>
            <Grid container spacing={2} className="mt-0">
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    className="d-flex align-items-center flex-column p-5"
                >
                    <Box className="login-head">
                        <img src={logo} />
                    </Box>

                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        onKeyDown={handleKeyDown}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <Box className="left-side-login">
                            <Box className="inner-left-side-bar mt-auto">
                                <Typography variant="h2" className="mb-3">
                                    Login to your account
                                </Typography>
                                <DocuTextField type={'text'} fieldLabel={'Email Address'} fieldName={'Email'} fieldValue={formData.Email} onChange={handleInputChange} />
                                <DocuTextField type={'password'} fieldLabel={'Password'} fieldName={'password'} fieldValue={formData.password} onChange={handleInputChange} />

                                <Box className="d-flex align-items-center justify-content-between mb-2">
                                    <FormControlLabel
                                        className="font-12"
                                        control={
                                            <Checkbox value="remember" className="text-blue" />
                                        }
                                        label="Remember me"
                                    />
                                    <Link
                                        onClick={handleClickOpenForgotPassord}
                                        variant="body2"
                                        className="pointer font-12 text-decoration-none text-black"
                                    >
                                        Forgot password?
                                    </Link>
                                </Box>

                                <Button
                                    type="submit"
                                    fullWidth
                                    className="btn-blue"
                                >
                                    Sign In
                                </Button>
                            </Box>
                            <Box className="mt-auto">
                                <Copyright sx={{ mt: 2, mb: 2 }} />
                                <Box className="text-center">
                                    <Link
                                        href="https://www.docusoft.net/terms/"
                                        target="_blank"
                                        className="text-blue sembold px-3"
                                    >
                                        Terms & Condition
                                    </Link>
                                    <Link
                                        href="https://www.docusoft.net/privacy-policy/"
                                        target="_blank"
                                        className="text-blue sembold px-3"
                                    >
                                        Privacy Policy
                                    </Link>
                                    <Link
                                        href="#"
                                        className="text-blue sembold px-3"
                                        target="_blank"
                                    >
                                        Help
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={6} className="right-side-login">
                    <Box className="text-center">
                        <img src={animation} className="d-block img-fluid" />
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={openForgotPassord}
                onClose={handleCloseForgotPassord}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="d-flex align-items-center justify-content-between modal-head">
                    <Box className="dropdown-box">
                        <Typography variant="h4" className="font-18 bold text-black">
                            Fogot Password?
                        </Typography>
                    </Box>
                    {/*  */}
                    <Button onClick={handleCloseForgotPassord} className="min-width-auto">
                        <span className="material-symbols-outlined text-black">cancel</span>
                    </Button>
                </Box>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box className="text-center mb-4">
                            <img src={logo} width={160} />
                        </Box>

                        <div class="alert alert-success" role="alert">
                            <p className="font-12 mb-0">
                                Enter your email and instructions will be sent to you!
                            </p>
                        </div>

                        <Box className="d-fle mb-4">
                            <TextField
                                label="Email"
                                variant="outlined"
                                className="w-100 mb-3 form-control"
                            />
                            <Button
                                variant="contained"
                                size="small"
                                className="btn-blue w-100"
                            >
                                Send Reset Link
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
export default Login;