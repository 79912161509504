import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography
} from "@mui/material";
import React, { useState } from "react";

import AutoComplete from "../../../components/AutoComplete";
// import CustomDatePicker from '../../../../CustomDatePicker';
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import CustomDatePicker from "../../../components/CustomDatePicker";
import GetFileType from "../../../components/FileType";
import NoData from "../../../components/NoData";
import {
  CheckDocumentForFormFilling_Json_Redux,
  CheckDocumentForSignature_Json_Redux,
  GetPortalRequestDocuments_Json_Redux,
  MessagePublishedPortalTask_Json_Redux,
  UpdateRequestedDocStatusAction_Json_Redux
} from "../../../redux/api_helper";
import {
  setActionDate_Redux,
  setApproval,
  setExpireDate_Redux,
  setGUID_Redux,
  setPriority
} from "../../../redux/docuSlice";
import HtmlEditorDX from "./HtmlEditor";
import RequestDocuments from "./RequestDocuments";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const agrno = localStorage.getItem("agrno");
const password = localStorage.getItem("password");
const FolderId = localStorage.getItem("FolderId");
const Email = localStorage.getItem("Email");

function Summary() {
  let redx = useSelector((state) => state.docu.ComposePortalMgsObj);
  let docRe = useSelector((state) => state.docu.RequestDocumnets);
  let redxAtt = useSelector((state) => state.docu.AttachmentTab);
  let dateDx = useSelector((state) => state.docu);
  ////////////////// Priority
  let priorityarr = [
    { id: 1, name: "Normal" },
    { id: 2, name: "High" },
  ];

  let forApproval = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];

  // const [approVal, setForApprobal]=useState(dateDx.Approval);
  // const [priority, setPriority]=useState(dateDx.Priority);

  const [actionDate, setActionDte] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  // const [actionDate, setActionDte] = useState(dayjs(dateDx.ActionDate ? dateDx.ActionDate : new Date()));
  // const [expireDate, setExpireDate] = useState(dayjs(dateDx.ExpireDate ? dateDx.ExpireDate : new Date()));

  let dispatch = useDispatch();
  // Define a single function to handle dispatching actions based on object type
  const [mainObject, setMainObject] = useState({
    Folder: "",
    Section: "",
    Client: "",
    FromUser: "",
    CCEmail: "",
    ToEmail: "",
    Subject: "",
    TextEditor: "",
    Template: "",
    SMSTemplate: "",
    isSignature: null,
    isMessage: null,
  });

  const handleObjectDispatch = (objectType, value) => {
    // Update mainObject state
    setMainObject((prevState) => ({
      ...prevState,
      [objectType]: value,
    }));
  };

  const CheckDocumentForFormFilling_Json = (dt, callback) => {
    try {
      let o = {
        emailid: redx.ToEmail[0]["E-Mail"],
        sPassword: "0",
        docTempId: dt.DocTempId,
        docID: dt.DocId,
      };
      dispatch(
        CheckDocumentForFormFilling_Json_Redux(o, function (res) {
          if (res) {
            console.log("CheckDocumentForFormFilling_Json_Redux", res);
            callback(res);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server CheckDocumentForFormFilling_Json_Redux",
        error
      );
    }
  };

  const CheckDocumentForSignature_Json = (dt, callback) => {
    try {
      let o = {
        emailid: redx.ToEmail[0]["E-Mail"],
        sPassword: "0",
        docTempId: dt.DocTempId,
        docID: dt.DocId,
      };
      dispatch(
        CheckDocumentForSignature_Json_Redux(o, function (res) {
          if (res) {
            console.log("CheckDocumentForSignature_Json", res);
            callback(res);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server CheckDocumentForSignature_Json",
        error
      );
    }
  };

  const [Stage, setStage] = useState(false);

  const checkStatus = (callback) => {
    if (!docRe) {
      console.log("No value selected");
      return;
    }
    try {
      const obj = {
        id: docRe.DocTempID,
      };
      dispatch(
        GetPortalRequestDocuments_Json_Redux(obj, function (res) {
          if (res) {
            if (res.length > 0) {
              let result = res.map((e) => {
                let o = { ...e }; // Create a shallow copy of the object
                if (e.RequestDocType === 1) {
                  CheckDocumentForSignature_Json(e, function (data) {
                    console.log("GetPortalRequestDocuments_Json11", data);
                    if (data.length > 0 && data[0].RequestDocStatus === "1") {
                      setStage(true);
                    } else {
                      setStage(false);
                    }
                  });
                } else if (e.RequestDocType === 2) {
                  CheckDocumentForFormFilling_Json(e, function (data) {
                    console.log("CheckDocumentForFormFilling_Json", data);
                    if (data.length > 0) {
                      setStage(true);
                    } else {
                      setStage(false);
                    }
                  });
                } else if (e.RequestDocType === 3) {
                  CheckDocumentForFormFilling_Json(e, function (data) {
                    console.log("CheckDocumentForFormFilling_Json", data);
                    if (data.length > 0) {
                      setStage(true);
                    } else {
                      setStage(false);
                    }
                  });
                }

                return o; // Return the modified object
              });

              callback(result);
            }
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalUsersRequestedDocs_Json_Redux",
        error
      );
    }
  };

  const UpdateRequestedDocStatusAction_Json = () => {
    //docRe
    try {
      let docidJoin = dateDx?.PortalRequestDocuments?.map((e) => e.DocId).join(
        ","
      );
      let o = {
        emailid: redx.ToEmail[0]["E-Mail"],
        sPassword: "0",
        docTempId: dateDx?.PortalRequestDocuments[0].DocTempId,
        docID: docidJoin,
        statusId: 2,
      };
      dispatch(
        UpdateRequestedDocStatusAction_Json_Redux(o, function (res) {
          if (res) {
            console.log("UpdateRequestedDocStatusAction_Json_Redux", res);
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, UpdateRequestedDocStatusAction_Json",
        error
      );
    }
  };

  async function CreatePortalMessage() {
    // checkStatus(function(res){
    //   console.log("hello doc", res,Stage)
    //   saveRequest();
    //   if (Stage) {
    //     saveRequest();
    //   }
    //   else {
    //     toast.error(`Request not prepared, please upload a valid document.`);

    //   }
    // });

    saveRequest();
  }

  function saveRequest() {
    try {
      if (redx.Client.ClientID && redx.FromUser.ID) {
        const ccEmail = redx.CCEmail
          ? redx.CCEmail.map((obj) => obj["E-Mail"])
          : "";
        const ToEmail = redx.ToEmail.map((obj) => obj["E-Mail"]);
        let obj = {
          accid: agrno,
          email: Email,
          password: password,
          senderID: redx.FromUser ? redx.FromUser.ID : "",
          sectionID: redx.Section
            ? redx.Section.SecID
            : toast("Section is blank"),
          ccode: redx.Client.ClientID,
          recipients: ToEmail,
          subject: redx.Subject ? redx.Subject : "",
          ccs: ccEmail ? ccEmail : [],
          forApproval: dateDx.Approval.name === "Yes" ? true : false, //isCheckedForApproval,
          highImportance: dateDx.Priority.name === "High" ? true : false,
          expiryDate: expireDate
            ? dayjs(expireDate).format("YYYY/MM/DD")
            : dayjs(new Date()).format("YYYY/MM/DD"),
          actionDate: actionDate
            ? dayjs(actionDate).format("YYYY/MM/DD")
            : dayjs(new Date()).format("YYYY/MM/DD"),
          trackIt: false,
          docTemplateTaskId: docRe.DocTempID ? docRe.DocTempID : 0,
          docTemplateId: docRe.DocTempID ? docRe.DocTempID : 0,
          //"filenames": fileNames,
          //  "attachments": fileDataBase64 ? fileDataBase64 : [],
          //"itemNos": ItemId ? ItemId : [],
          noMessage: false,
          message: btoa(redx.TextEditor ? redx.TextEditor : ""),
          docuBoxMessage: false,
          docuBoxEmails: "",
          daysToDelete: 0,
          approvalResponse: "",
          uploadID: dateDx.GUID ? dateDx.GUID : toast("Guid is blank"),
          PubTaskid: 0,
        };
        console.log("final save data obj", obj);
        dispatch(
          MessagePublishedPortalTask_Json_Redux(obj, function (res) {
            console.log("MessagePublishedPortalTask_Json_Redux", res);

            if (res.d === "") {
              toast.success("Message Sent Successfully");
              let strGuid = uuidv4().replace(/-/g, "");
              dispatch(setGUID_Redux(strGuid));
              UpdateRequestedDocStatusAction_Json();
              setStage(false);
            } else {
              toast.error(
                "This request has already been sent to one of the selected recipient(s)."
              );
            }
          })
        );
      }
    } catch (error) {
      toast.error("Please select a user (From dropdown)");
      console.log({
        status: false,
        message: "PortMessage Faild Try again",
        error: error,
      });
    }
  }

  const getActionDate = (e) => {
    const formattedDate = dayjs(e).format("YYYY-MM-DD");
    console.log(dayjs(e).format("YYYY-MM-DD"));
    dispatch(setActionDate_Redux(formattedDate));
    setActionDte(formattedDate);
  };

  const getExpireDate = (e) => {
    const formattedDate = dayjs(e).format("YYYY-MM-DD");
    dispatch(setExpireDate_Redux(formattedDate));
    setExpireDate(formattedDate);
  };

  const getPriorityFun = (e, value) => {
    console.log(value);
    dispatch(setPriority(value));
  };
  const getForApprovalFun = (e, value) => {
    console.log(value);
    dispatch(setApproval(value));
  };

  const SigningMethods = (e) => {
    try {
      // setAttachmentAnchorEl(null);
      console.log(e);
      if (e.FileType === "pdf") {
        if (redx.ToEmail) {
          const ToEmail = redx.ToEmail.map((obj) => obj["E-Mail"]).join(",");
          const url = `https://signing.docusms.uk/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sendclient=${redx.Client.ClientID}&sendemail=&clientname=${redx.Client.Client}&option=upload&file=${agrno}-${e.Guid}/${e.FileName}&to=${ToEmail}&rwndrnd=0.8166129123678032`;

          // Open the URL in a new window
          window.open(url, "_blank", "noopener,noreferrer");
          // handleCheckStatus(e);
        } else {
          toast.error("Please select an email!");
        }
      } else {
        toast.error("Please convert the file to PDF.");
      }
    } catch (error) {
      console.error("Error in SigningMethods:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Box>
      <h2 className="title mt-4 mb-3 font-20">Compose Portal Message</h2>

      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Typography variant="Body2" className="poppins-semibold">
            Folder
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="Body2">
            : {redx.Folder ? redx.Folder.Folder : ""}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="poppins-semibold text-black" variant="Body2">
            Section
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="Body2">
            : {redx.Section ? redx.Section.Sec : ""}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="poppins-semibold text-black" variant="Body2">
            Reference
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="Body2">
            : {redx.Client ? redx.Client.Client : ""}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="poppins-semibold text-black" variant="Body2">
            From
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="Body2">
            : {redx.FromUser ? redx.FromUser.ForwardTo : ""}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography className="poppins-semibold text-black" variant="Body2">
            To
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {redx.ToEmail && redx.ToEmail.length > 0
            ? redx.ToEmail.map((item, index) => {
                return (
                  <>
                    <Box className="mb-2" key={index}>
                      <Typography variant="Body2">
                        : {item["First Name"]} {item["Last Name"]}{" "}
                      </Typography>
                      <Typography variant="Body2" className="font-12">
                        ({item["E-Mail"]})
                      </Typography>
                    </Box>
                  </>
                );
              })
            : ""}
        </Grid>

        <Grid item xs={2}>
          <Typography className="poppins-semibold text-black" variant="Body2">
            CC
          </Typography>
        </Grid>

        <Grid item xs={10}>
          {redx.CCEmail && redx.CCEmail.length > 0
            ? redx.CCEmail.map((item, index) => {
                return (
                  <>
                    <Box className="mb-2" key={index}>
                      <Typography variant="Body2">
                        : {item["First Name"]} {item["Last Name"]}
                      </Typography>{" "}
                      <Typography variant="Body2" className="font-12">
                        ({item["E-Mail"]})
                      </Typography>
                    </Box>
                  </>
                );
              })
            : ""}
        </Grid>
      </Grid>

      <hr />

      <Box>
        <h2 className="title font-20">{redx.Subject}</h2>
        <Typography variant="Body2" className="font-13">
          <HtmlEditorDX templateDataMarkup={redx.TextEditor}></HtmlEditorDX>

          {/* <br />
          <br />
          Signature Text
          <br />
          <br />
          Disclaimer text */}
        </Typography>
      </Box>

      <hr />

      <Box>
        <h2 className="title font-20">Attached Files</h2>

        {redxAtt && redxAtt.length > 0 ? (
          <MenuList>
            {redxAtt && redxAtt.length > 0
              ? redxAtt.map((item, index) => {
                  return (
                    <>
                      <MenuItem key={index}>
                        <ListItemIcon>
                          <GetFileType
                            Type={item.FileType.toLowerCase()}
                          ></GetFileType>
                        </ListItemIcon>
                        <ListItemText>{item.FileName}</ListItemText>
                        {item.Status ? (
                          <Box>
                            <span class="badge text-bg-primary">Ready</span>
                          </Box>
                        ) : (
                          <Box>
                            <span
                              class="badge text-bg-primary"
                              onClick={() => SigningMethods(item)}
                            >
                              Sign
                            </span>
                          </Box>
                        )}
                      </MenuItem>
                    </>
                  );
                })
              : ""}
          </MenuList>
        ) : (
          <NoData message="No data here" />
        )}
      </Box>

      <hr />
      {/* <h2 className='title font-20'>Requested Documents</h2> */}

      {dateDx.NewRequest && (
        <>
          <RequestDocuments />
        </>
      )}

      <hr />

      <Box className="clearfix">
        <h2 className="title font-20">Additional Settings</h2>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="Body2">Action By</Typography>
            <CustomDatePicker comFun={getActionDate} value={actionDate} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="Body2">Expires On</Typography>
            <CustomDatePicker comFun={getExpireDate} value={expireDate} />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              ComData={forApproval}
              comFun={getForApprovalFun}
              target="name"
              lable="For Approval"
              defaultValue={dateDx.Approval}
            ></AutoComplete>
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              lable="Priority"
              ComData={priorityarr}
              target="name"
              comFun={getPriorityFun}
              defaultValue={priorityarr.find((item) => item.name === "Normal")}
            ></AutoComplete>
          </Grid>
          {/* <Grid item xs={6}>
            <AutoComplete target="Issue Reminders" lable="Issue Reminders"></AutoComplete>
          </Grid>
          <Grid item xs={6}>
            <AutoComplete target="Direct Access Link" lable="Direct Access Link"></AutoComplete>
          </Grid> */}
        </Grid>
      </Box>

      <Box className="mt-4 text-end">
        <Button
          onClick={CreatePortalMessage}
          className="btn-blue"
          startIcon={<SendIcon />}
        >
          Send Message
        </Button>
      </Box>
    </Box>
  );
}

export default Summary;
