import PrintIcon from "@mui/icons-material/Print";
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import NoData from "../../components/NoData";

// import pdf from "../../assets/images/files-icon/pdf.png";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import csv from "../../assets/images/files-icon/csv.png";
import doc from "../../assets/images/files-icon/doc.png";
import jpg from "../../assets/images/files-icon/jpg.png";
import pdf from "../../assets/images/files-icon/pdf.png";
import png from "../../assets/images/files-icon/png.png";
import text from "../../assets/images/files-icon/text.png";
import xls from "../../assets/images/files-icon/xls.png";
import userImg from "../../assets/images/user-2.svg";
import { useLocalStorage } from "../../custom-hook/useLocalStorage";
import { GetSignature_Json_Redux } from "../../redux/ak_api_helper";
import {
  setOpenDocumentPreviewModal,
  setOpenSignDocument,
} from "../../redux/modalSlice";

// import Signature from "../../assets/images/icons/icons8-autograph-24.png"
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ReactToPrint from "react-to-print";
import Comments from "../../components/Comments";
import CustomLoader from "../../components/CustomLoader";
import TimelineModal from "../../modals/TimelineModal";
import {
  GetAllSentMessagesByFolder_Json_Redux,
  GetMessageAttachments_Json_Redux,
} from "../../redux/api_helper";
import {
  downloadShareboxDocument,
  getApprovalCertificate,
  getApprovalCommentsHtml,
  getMessageHtml,
  getPortalMessageComments,
  getPortalMessageDocuments,
} from "../../redux/dev_chetan_api_helper";
import {
  setInboxMessageHtmlLoading,
  setPortalMessageDocumentsLoading,
  setshareboxDocForPreviewURL,
} from "../../redux/dev_chetan_slice";
import AttachmentStatusComponant from "../../user/componants/requestComponant/AttachmentStatusComponant";
import { clientBodyaccidData } from "../../utils/RequestsFunction";
import ClientRequestDetails from "./ClientRequestDetails";

export default function IncomngMessageViewer() {
  const location = useLocation();

  const {
    PortalDocID: portalDocID,
    Subject,
    Section,
    ReceivedDate,
    SendDate,
    ReferenceName,
    ClientName,
    customerId,
    Issued,
    Viewed,
    ViewDate,
    ExpiryDate,
    ActionBy,
    Status,
    EmailID,
    SendMessage,
    MessageID,
    messageStatus,
    isClient,
    senderName,
    TabIndex,
    data,
  } = location?.state || {};

  const portalObj = {
    accid: localStorage.getItem("agrno")
      ? localStorage.getItem("agrno")
      : "0003",
    email: localStorage.getItem("Email")
      ? localStorage.getItem("Email")
      : "patrick@docusoft.net",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "UGF0cmljazEyMy4=",
    portalUserEmail: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
  };

  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  // const portalDocID = searchParams.get("PortalDocID");
  // const Subject = searchParams.get("Subject");
  // const Section = searchParams.get("Section");
  // const ReceivedDate = searchParams.get("ReceivedDate");
  // const SendDate = searchParams.get("SendDate");
  // const ReferenceName = searchParams.get("ReferenceName");
  // const ClientName = searchParams.get("ClientName");
  // const customerId = searchParams.get("customerId");
  // const Issued = searchParams.get("Issued");
  // const Viewed = searchParams.get("Viewed");
  // const ViewDate = searchParams.get("ViewDate");
  // const ExpiryDate = searchParams.get("ExpiryDate");
  // const ActionBy = searchParams.get("ActionBy");
  // const Status = searchParams.get("Status");
  // const EmailID = searchParams.get("EmailID");
  // const SendMessage = searchParams.get("Inbox");
  // const MessageID = searchParams.get("MessageID");
  // const messageStatus = searchParams.get("messageStatus");
  // const isClient = searchParams.get("isClient");
  // const senderName = searchParams.get("senderName");
  // const TabIndex = searchParams.get("TabIndex");

  const clientLoginStatus = localStorage.getItem("LoginClient");

  // Attachment Dropudoun
  const [EditanchorEl, EditsetAnchorEl] = React.useState({});
  // const Editopen = Boolean(EditanchorEl);

  const EdithandleClick = (event, index) => {
    EditsetAnchorEl({ ...EditanchorEl, [index]: event.currentTarget });
  };

  const EdithandleClose = (index) => {
    let temp = { ...EditanchorEl };
    delete temp[index];
    EditsetAnchorEl(temp);
  };

  const PreviewAttachment = (data) => {
    EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const DocusoftedDocumnet = (data) => {
    if (getAllSentMessages.length > 0) {
      let res = getAllSentMessages.filter(
        (m) => m["Message ID"].toString() === MessageID
      );
    }
  };

  useEffect(() => {
    const userredirect = location.pathname.includes("/user");
    if (!portalDocID) {
      if (userredirect) navigate("/user/portal-messages");
      else navigate("/client/portalmessage/inboxes");
    }
  }, [portalDocID]);

  const DownloadAttachment = (data) => {
    EditsetAnchorEl(null);
    dispatch(
      downloadShareboxDocument(
        data.PortalDocId,
        data.Attachid,
        data.DocExtension,
        data.PortalName
      )
    );
  };

  const PreviewNewTab = (data) => {
    EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${data.PortalDocId}&extension=${data.DocExtension}`;
    dispatch(setshareboxDocForPreviewURL(url));
    window.open(url, "_blank");
  };

  // Button DropDoun
  const [EditanchorE2, EditsetAnchorE2] = React.useState(null);
  const {
    inboxMessageHtml,
    portalMessageDocuments,
    portalMessageComments,
    approvalCertificateBase64,
    portalApprovalCommentsHtml,
    inboxMessageHtmlLoading,
    portalMessageDocumentsLoading,
  } = useSelector((state) => state.dev_chetan);

  const { GetMessageAttachments } = useSelector((state) => state.docu);

  let certificateUrl = `data:application/pdf;base64,${approvalCertificateBase64}`;

  function groupByPortalDocId(documents) {
    return documents.reduce((acc, doc) => {
      if (!acc[doc.PortalDocId]) {
        acc[doc.PortalDocId] = [];
      }
      acc[doc.PortalDocId].push(doc);
      return acc;
    }, {});
  }

  const [value, setValue] = React.useState("1");

  const Item = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.5),

    color: theme.palette.text.secondary,
  }));

  let objReq = useLocalStorage("accid");

  useEffect(() => {
    let sentObj = {
      accid: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      ccode: "",
      emailAddress: EmailID || customerId,
      folder: 171,
    };

    if (clientLoginStatus != null) {
    } else {
      dispatch(
        GetAllSentMessagesByFolder_Json_Redux(sentObj, function (res) {})
      );
      dispatch(GetMessageAttachments_Json_Redux(portalDocID));
    }

    dispatch(getMessageHtml(portalDocID));
    dispatch(getPortalMessageDocuments(portalDocID));
    // setIsLoding(false);
    dispatch(getPortalMessageComments(portalDocID));
    // getIpAddress();
    if (messageStatus === "Approved")
      dispatch(getApprovalCertificate(portalDocID));
    dispatch(getApprovalCommentsHtml(portalDocID));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return () => {
      dispatch(setInboxMessageHtmlLoading(true));
      dispatch(setPortalMessageDocumentsLoading(true));
    };
  }, [dispatch]);

  const docTypeIcon = (target) => {
    switch (target) {
      case ".pdf":
        return pdf;
      case ".txt":
        return text;
      case ".docx":
        return doc;
      case ".jpg":
        return jpg;
      case ".jpeg":
        return jpg;
      case ".png":
        return png;
      case ".xls":
        return xls;
      case ".csv":
        return csv;
      default:
        return text;
    }
  };
  
  const downloadFile = (e, doc) => {
    e.stopPropagation();
    dispatch(
      downloadShareboxDocument(
        portalDocID,
        doc.Attachid,
        doc.DocExtension,
        doc.PortalName
      )
    );
  };

  const downloadAllDocs = (e) => {
    portalMessageDocuments.forEach((doc) => {
      downloadFile(e, doc);
    });
  };

  const handlePreview = (data) => {
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${portalDocID}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const handlePrint = () => {
    window.print();
  };

  // tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // accorion
  const [expanded, setExpandedAccordion] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const componentRef = useRef();

  return (
    <>
      <div ref={componentRef}>
        {false ? (
          <CustomLoader />
        ) : (
          <>
            {messageStatus === "Expired" && (
              <Box className="mb-3">
                <Alert variant="filled" severity="error">
                  {/* <AlertTitle>Warning</AlertTitle>  */}
                  This message has expired
                </Alert>
              </Box>
            )}

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box className="clearfix">
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    className="custom-tabs mb-0 flex-wrap-tabs min-h-auto"
                  >
                    <Tab label="Message" value="1" />
                    {messageStatus != "Expired" && (
                      <Tab label="Request" value="2" />
                    )}
                  </TabList>
                </Box>

                <TabPanel value="1" className="p-0">
                  <Box className="clearfix">
                    <Box className="white-box">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h2 className="title">
                          {Subject ? Subject : "Not Available"}
                        </h2>

                        {!ReferenceName &&
                          messageStatus === "Pending" &&
                          portalMessageDocuments.length > 0 &&
                          portalMessageDocuments.find(
                            (item) => item?.ForSigning === true
                          ) && (
                            <Box
                              sx={{ display: "flex", justifyContent: "end" }}
                            >
                              <Button
                                variant="contained"
                                checkCircleIcon
                                size="small"
                                // startIcon={<CheckCircleIcon />}
                                className="btn-blue me-2 mb-3"
                                onClick={() => {
                                  dispatch(
                                    GetSignature_Json_Redux(clientBodyaccidData)
                                  );
                                  dispatch(setOpenSignDocument(true));
                                }}
                              >
                                Action
                              </Button>
                            </Box>
                          )}
                      </Box>

                      <hr />

                      <Box sx={{ marginTop: "20px" }}>
                        <Box className="d-flex justify-content-between align-items-center flex-wrap">
                          <Box className="d-flex align-items-center">
                            <Box className="me-3">
                              {userImg && (
                                <img
                                  src={userImg}
                                  height={50}
                                  width={50}
                                  style={{
                                    borderRadius: "50%",
                                    background: "#eea706",
                                  }}
                                />
                              )}
                            </Box>

                            <Box>
                              <Typography
                                variant="h3"
                                className="font-20 poppins-semibold"
                              >
                                {ClientName ? ClientName : senderName}
                              </Typography>

                              <Typography variant="p">
                                {EmailID && EmailID}
                                {customerId && customerId}
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="d-flex">
                            <Box className="d-flex">
                              {ReferenceName ? (
                                <>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">Section</p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {Section && Section.slice(3)}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Send Date
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {SendDate}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">View On</p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {ViewDate}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Reference
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {ReferenceName}
                                    </p>
                                  </Box>
                                </>
                              ) : ClientName ? (
                                <>
                                  {clientLoginStatus ? (
                                    <Box className="me-3 border-end pe-3 text-center">
                                      <p className="poppins-semibold">
                                        Section
                                      </p>
                                      <p className="text-gray font-12 poppins-medium">
                                        {Section && Section !== "Not available"
                                          ? Section.slice(3)
                                          : "Not available"}
                                      </p>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Issued on
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {Issued}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Viewed on
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {Viewed}
                                    </p>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">Section</p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {Section && Section.slice(3)}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Recived Date
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {ReceivedDate}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Action By
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {ActionBy === "Invalid date"
                                        ? "Not Assigned"
                                        : ActionBy}
                                    </p>
                                  </Box>
                                  <Box className="me-3 border-end pe-3 text-center">
                                    <p className="poppins-semibold">
                                      Expiry Date
                                    </p>
                                    <p className="text-gray font-12 poppins-medium">
                                      {ExpiryDate}
                                    </p>
                                  </Box>
                                </>
                              )}
                            </Box>

                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  className="min-width-auto ms-1"
                                  size="small"
                                  onClick={handlePrint}
                                >
                                  <PrintIcon className="text-black" />
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                            {!clientLoginStatus && (
                              <TimelineModal location={location} />
                            )}
                          </Box>
                        </Box>

                        <hr />
                      </Box>

                      <Box>
                        <Box className="font-12">
                          <div className="mb-2 font-13">
                            {/* {inboxMessageHtml} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: inboxMessageHtml,
                              }}
                            ></div>
                          </div>
                          <Typography className="mb-2 font-13"></Typography>

                          <hr />
                        </Box>
                      </Box>
                      {/* </Grid> */}

                      <Box sx={{ width: "100%" }}>
                        <Grid container rowSpacing={1}>
                          <Grid item xs={12}>
                            <Item sx={{ marginRight: "20px" }}>
                              <Box className="d-flex justify-content-between mb-0 align-items-center">
                                <Typography
                                  variant="h3"
                                  className="font-18 text-black poppins-semibold mb-0"
                                >
                                  Attachments
                                </Typography>
                                {portalMessageDocuments.length > 0 && (
                                  <Button
                                    onClick={downloadAllDocs}
                                    variant="contained"
                                    className="btn-blue"
                                    size="small"
                                    startIcon={<DownloadIcon />}
                                  >
                                    Download All
                                  </Button>
                                )}
                              </Box>

                              {/* message Document */}
                              <Box className="d-flex flex-wrap main-email-attachment w-100">
                                {clientLoginStatus ? (
                                  portalMessageDocumentsLoading ? (
                                    <CustomLoader />
                                  ) : portalMessageDocuments.length > 0 ? (
                                    portalMessageDocuments.map((doc) => {
                                      return (
                                        <>
                                          {/* <Tooltip arrow> */}
                                          <Box
                                            className="email-attachment mb-2"
                                            title={
                                              doc && doc.PortalName
                                                ? doc.PortalName
                                                : "Not Available"
                                            }
                                            onDoubleClick={() =>
                                              handlePreview(doc)
                                            }
                                          >
                                            <Box className="download">
                                              <Tooltip title="Download" arrow>
                                                <Button
                                                  className="min-width-auto"
                                                  size="small"
                                                  onClick={(e) =>
                                                    downloadFile(e, doc)
                                                  }
                                                >
                                                  <DownloadIcon className="text-blue font-20" />
                                                </Button>
                                              </Tooltip>
                                            </Box>

                                            <img
                                              src={docTypeIcon(
                                                doc.DocExtension
                                              )}
                                              style={{ width: 28 }}
                                              alt="documents"
                                            />
                                            <Typography
                                              sx={{
                                                fontSize: "13px",
                                                fontWeight: "550",
                                              }}
                                              className="d-flex align-items-center"
                                            >
                                              <span className="d-inline-block ms-2">
                                                {doc && doc.PortalName
                                                  ? doc.PortalName
                                                  : "Not Available"}
                                              </span>
                                            </Typography>
                                          </Box>
                                          {/* </Tooltip> */}
                                        </>
                                      );
                                    })
                                  ) : (
                                    <Box className="w-100">
                                      <NoData
                                        className="w-100"
                                        message="No Attachments here"
                                      />
                                    </Box>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>

                      {!clientLoginStatus && (
                        <Box sx={{ width: "100%" }}>
                          <Grid container rowSpacing={1}>
                            <Grid item xs={12}>
                              <Box className="request-details-accordian">
                                {!clientLoginStatus &&
                                  // Array.isArray(
                                  //   GetMessageAttachments[portalDocID]
                                  // ) &&
                                  // GetMessageAttachments[portalDocID].map(
                                    GetMessageAttachments.map(
                                    (itm, index) => (
                                      <AttachmentStatusComponant
                                        key={itm.id || index}
                                        portalDocId={portalDocID}
                                        itm={itm}
                                        index={index}
                                        EdithandleClick={EdithandleClick}
                                        EditanchorEl={EditanchorEl}
                                        EditsetAnchorEl={EditsetAnchorEl}
                                        MessageID={MessageID}
                                      />
                                    )
                                  )}
                                {/* {(!GetMessageAttachments[portalDocID] ||
                                  GetMessageAttachments[portalDocID].length ===
                                    0) && (
                                  <NoData message="Data is not found" />
                                )} */}
                                {(!GetMessageAttachments ||
                                  GetMessageAttachments.length ===
                                    0) && (
                                  <NoData message="Data is not found" />
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>

                    {(messageStatus === "For Info" ||
                      messageStatus === "Pending" ||
                      ReferenceName ||
                      ClientName) && (
                      <Comments
                        portalDocID={portalDocID}
                        portalMessageComments={portalMessageComments}
                        ClientName={ClientName}
                        customerId={customerId}
                      />
                    )}

                    {messageStatus === "Approved" && (
                      <Box className="white-box 02">
                        <Box className="d-flex justify-content-between align-items-center">
                          <Typography
                            className="font-18 text-black poppins-semibold"
                            variant="h3"
                          >
                            Certificate of approval
                          </Typography>
                        </Box>

                        <hr />

                        <Box>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              sm={6}
                              sx={{
                                height: "400px",
                                overflow: "auto",
                              }}
                              className="pe-2"
                            >
                              <Card sx={{ border: "1px solid black" }}>
                                {certificateUrl.split(",")[1] ===
                                "undefined" ? (
                                  <>
                                    <CustomLoader />
                                  </>
                                ) : (
                                  <iframe
                                    src={certificateUrl}
                                    width="100%" // Set the width
                                    height="700px" // Set the height
                                    frameBorder="0" // Set frameborder to 0
                                    allowFullScreen // Allow fullscreen mode
                                    title="Embedded Content" // Set the title for accessibility
                                  ></iframe>
                                )}
                              </Card>
                            </Grid>
                            <Grid item sm={6}>
                              <Box>
                                <Box className="mb-2">
                                  <Typography
                                    variant="h2"
                                    className="font-18 poppins-semibold"
                                  >
                                    Comments
                                  </Typography>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: portalApprovalCommentsHtml,
                                    }}
                                    className="single-user-comments"
                                  ></div>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </TabPanel>

                <TabPanel value="2" className="p-0">
                  <ClientRequestDetails />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
      </div>
    </>
  );
}
