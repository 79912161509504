import { Divider, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../components/AutoComplete";
import CustomLoader from "../../../components/CustomLoader";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import {
  GetAllFolders_Json_Redux,
  GetAllReceivedMessages_Json_Redux,
  GetAllSentMessages_Json_Redux,
  GetClientsByFolder_Json_Redux,
  Json_GetAccountUsersContactsByClient_Redux,
} from "../../../redux/api_helper";
import PortalMessages from "./PortalMessages";

export default function UserNewPortalMesage() {
  let objReq = useLocalStorage("accid");
  const dispatch = useDispatch();

  const [getFolderData, setGetFolderData] = React.useState([]);
  const [clientsByFolder, setClientsByFolder] = React.useState([]);
  const [customersByClient, setCustomersByClient] = React.useState([]);
  const { getAllSentMessagesLoding } = useSelector((state) => state.docu);
  const [showClient, setShowClient] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        setGetFolderData(res);
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    if (getFolderData.length > 0) {
      getAllClients(getFolderData[0]);
    }
  }, [getFolderData]);

  const getAllClients = (data) => {
    setClientsByFolder([]);
    const newObj = { ...objReq, folder: parseInt(data?.FolderID) };
    dispatch(
      GetClientsByFolder_Json_Redux(newObj, function (res) {
        setClientsByFolder(res);
        setShowClient(res);
      })
    );
  };

  const getContact = (value) => {
    const contactObj = {
      agrno: objReq.accid,
      Email: objReq.email,
      password: objReq.password,
      Clientid: value.ClientID,
    };

    dispatch(
      Json_GetAccountUsersContactsByClient_Redux(contactObj, function (res) {
        setCustomersByClient(JSON.parse(res));
      })
    );
  };

  const getFolderObject = (event, value) => {
    console.log(event, value);
    setCustomersByClient([]);
    setShowClient([]);
    setCustomersByClient([]);
    getAllClients(value);
  };

  const SearchHandler = (event) => {
    setSearchString(event.target.value);
  };

  React.useEffect(() => {
    if (searchString.length > 2) {
      setShowClient(
        clientsByFolder.filter((client) =>
          client.Client.toLowerCase().includes(searchString.toLocaleLowerCase())
        )
      );
    } else {
      setShowClient(clientsByFolder);
    }
  }, [searchString]);

  const getMessagesByCustomer = (customer) => {
    let sentObj = {
      accid: objReq.accid,
      email: objReq.email,
      password: objReq.password,
      ccode: "",
      emailAddress: customer ? customer?.EmailId : "",
    };
    dispatch(GetAllSentMessages_Json_Redux(sentObj, function (res) {}));
    dispatch(GetAllReceivedMessages_Json_Redux(sentObj, function (res) {}));
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={2.2}>
          <Grid container>
            <Grid item xs={12}>
              {getAllSentMessagesLoding ? (
                <Box>
                  <CustomLoader />
                </Box>
              ) : (
                <Box>
                  <AutoComplete
                    ComData={getFolderData}
                    target="Folder"
                    comFun={getFolderObject}
                    lable={"Folder"}
                    defaultValue={
                      getFolderData.length !== 0 && getFolderData[0].Folder
                        ? getFolderData[0].Folder
                        : ""
                    }
                  />
                  <Divider
                    className="mt-3"
                    textAlign="left"
                    sx={{
                      borderColor: "#000",
                      borderWidth: 2,
                      borderRadius: 10,
                    }}
                  />

                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">Client List</Typography>
                    <TextField
                      id="outlined-basic"
                      label="Search client"
                      variant="outlined"
                      fullWidth
                      className="my-3"
                      placeholder="Seach client hear"
                      onChange={SearchHandler}
                    />
                    <Box
                      sx={{
                        maxHeight: 300,
                        overflow: "hidden",
                        overflowY: "auto",
                        mb: 2, // margin-bottom for spacing between items
                        p: 1, // padding for internal spacing
                        mt: 1,
                      }}
                    >
                      {showClient && showClient.length > 0 ? (
                        showClient.map((client, index) => (
                          <Box key={index}>
                            <Typography
                              sx={{
                                display: "flex",
                                width: "100%",
                                color: "#000",
                                textAlign: "left",
                                marginBottom: 2,
                                cursor: "pointer",
                                "&:hover": {
                                  color: "blue",
                                },
                              }}
                              variant="button"
                              onClick={() => getContact(client)}
                            >
                              {client.Client}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography>No clients available</Typography>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">Contacts</Typography>
                    <Box
                      sx={{
                        maxHeight: 300,
                        overflow: "hidden",
                        overflowY: "auto",
                        mb: 2, // margin-bottom for spacing between items
                        p: 1, // padding for internal spacing
                        mt: 1,
                      }}
                    >
                      {customersByClient && customersByClient.length > 0 ? (
                        customersByClient.map((customer, index) => (
                          <Box key={index}>
                            <Typography
                              sx={{
                                display: "flex",
                                width: "100%",
                                color: "#000",
                                textAlign: "left",
                                marginBottom: 2, // margin-top for spacing
                                cursor: "pointer",
                                "&:hover": {
                                  color: "blue", // Example hover effect
                                },
                              }}
                              variant="button"
                              onClick={() => getMessagesByCustomer(customer)}
                            >
                              {customer.ContactName}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography>No clients available</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9.8}>
          <PortalMessages />
        </Grid>
      </Grid>
      
    </Box>
  );
}
