import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { EditContactOpenMdalReducer } from "../redux/adityaReducer";
import AddContacts from "../user/componants/contact/AddContacts";
import CloseIcon from '@mui/icons-material/Close';

const EditContactModal = ({ open, close, refreshData }) => {
  const [params, setsearchParams] = useSearchParams();
  const [contactData, setContactData] = useState();

  const contactNo = params.get("contactNo");
  const { allContactsByClientID_Redux } = useSelector(
    ({ dev_chetan }) => dev_chetan
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(EditContactOpenMdalReducer(false));
    close && close();
  };

  useEffect(() => {
    setContactData();
    allContactsByClientID_Redux.length > 0 &&
      contactNo &&
      setContactData(
        allContactsByClientID_Redux.filter(
          (itm) => Number(itm.ContactNo) === Number(contactNo)
        )
      );
  }, [allContactsByClientID_Redux, contactNo]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal full-modal"
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="dropdown-box">
          <Typography variant="h4" className="font-18 bold text-black mb-0">
            Edit Contact
          </Typography>
        </Box>

        {/*  */}
        <Button startIcon={<CloseIcon />} onClick={handleClose}>
          
        </Button>
      </Box>

      {/* <hr /> */}

      <DialogContent className="pt-0">
        <DialogContentText id="alert-dialog-description">
          {/* <h3>Add Contacts here</h3> */}
          <AddContacts
            handleClose51={handleClose}
            contactDetails={contactData}
            refreshData={refreshData}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default EditContactModal;
