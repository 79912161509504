import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setWhatappUpdate, setWhatsAppValidModal } from "../redux/modalSlice";
import AutoComplete from "../components/AutoComplete";
import DocuTextField from "../cmp-utils/DocuTextField";
import {
  DeleteWhatappValidationOtp,
  GetPortalContactDetailsByEmail,
  SendWhatappValidOtp,
  SentWhatappValidationOtp,
  UDF_Add_Redux1,
} from "../redux/ak_api_helper";
import { toast } from "react-toastify";

function WhatsAppValidModal({ open, Mobilenumber }) {
  const getWhatappNumber = useSelector(
    (state) => state.modals?.getWhatappNumber
  );

  const getWhatappUpdate = useSelector(
    (state) => state.modals?.getWhatappUpdate
  );

  const getOrigantryNumber = useSelector(
    (state) => state.modals?.getOrigantryNumber
  );

  const dispatch = useDispatch();
  const [sendbuttonVisible, setSendButtonVisible] = useState(true);
  const [resendVisibleButton, setResendVisibleButton] = useState(false);
  const [values, setValues] = useState(Array(6).fill(""));
  // const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [count, setCount] = useState(60);
  const [notificationMsgVisible, setNotificationMsgVisible] = useState(true);
  const [otp, setOtp] = useState(null);
  const [WhatsAppNumber, setWhatsAppNumber] = useState(null);
  const [tempWhatsAppNumber, setTempWhatsAppNumber] = useState(null);
  const [WhatsAppNumberUpdateTrue, setWhatsAppNumberUpdateTrue] = useState("");
  const [WhatsAppNumberUpdateFalse, setWhatsAppNumberUpdateFalse] =
    useState("");
  // setInterval(() => {
  //     setcount(count-1)
  // }, 50000);

  useEffect(() => {
    setWhatsAppNumber(getWhatappNumber);
    setTempWhatsAppNumber(getWhatappNumber);
    // setWhatsAppNumberUpdate(getWhatappUpdate);
  
   console.log('GetWhataapp Number' , getWhatappUpdate )

    if (getWhatappUpdate == true) {
      setWhatsAppNumberUpdateTrue("true");
      // setWhatsAppNumberUpdateFalse("false");
    } else {
      setWhatsAppNumberUpdateFalse("false");
    }
  },[open]);

  useEffect(() => {
    let interval;
    if (resendVisibleButton) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendVisibleButton]);

  const handleClose = () => {
    setOtp(null);
    setValues(Array(6).fill(""));
    setCount(60);
    setSendButtonVisible(true);
    setResendVisibleButton(false);
    setNotificationMsgVisible(true);
    dispatch(setWhatsAppValidModal(false));
    // dispatch(setWhatappUpdate(false))
    // setWhatsAppNumberUpdateFalse("");
    // setWhatsAppNumberUpdateTrue("");

    const obj1 = {
      phone: WhatsAppNumber ? WhatsAppNumber : getWhatappNumber,
    };
    dispatch(DeleteWhatappValidationOtp(obj1));

    setWhatsAppNumber(null);
    // dispatch(setWhatsAppNumber(''))
  };

  const sendCode = async () => {
    
    if (WhatsAppNumber && WhatsAppNumber.length > 0) {
      console.log("WhatsAppNumber set ", WhatsAppNumber);
      setNotificationMsgVisible(true);
      const obj1 = {
        phone: WhatsAppNumber,
      };

      console.log("phone obj1", obj1);

      const result = dispatch(SentWhatappValidationOtp(obj1));

      result
        .then((response) => {
          return response;
        })
        .then((data) => {
          console.log("data response", data.data.status == 1);
          if (data && data.data && data.data.status == 1) {
            toast.success("OTP sent successfully ! Plese Check Your WhatsApp");
            setSendButtonVisible(false);
          } else if (data.data.status == 0) {
            toast.error("This is not a valid phone numbe");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
        });

      console.log("result", result);

      setTimeout(() => {
        setResendVisibleButton(true);
        setNotificationMsgVisible(false);
      }, 1000);

      setTimeout(() => {
        setCount(60);
        setResendVisibleButton(false);
        setNotificationMsgVisible(false);

        const obj1 = {
          phone: WhatsAppNumber,
        };
        dispatch(DeleteWhatappValidationOtp(obj1));

        setOtp(null);
        setValues(Array(6).fill(""));
      }, 60000);
    } else {
      toast.error("Enter WhatsApp Number");
    }
  };

  const submitOtp = () => {
    if (WhatsAppNumber && WhatsAppNumber.length > 0) {
      console.log(
        "Whataap NUmber Updated true and false",
        typeof WhatsAppNumberUpdateFalse,
        typeof WhatsAppNumberUpdateTrue
      );

      console.log(
        "getWhatappNumber_true_false true false",
        typeof WhatsAppNumberUpdateTrue
      );

      const obj1 = {
        // argo: localStorage.getItem('accIdClient') ? localStorage.getItem('accIdClient') : '',
        // userEmail :  localStorage.getItem('EmailClient') ? localStorage.getItem('EmailClient') : '',
        // password:  localStorage.getItem('passwordClient') ? localStorage.getItem('passwordClient') : '',

        phone: WhatsAppNumber,
        otp: otp,
        agrno: localStorage.getItem('accIdClient') ?  localStorage.getItem('accIdClient') : '',
        userEmail: localStorage.getItem('EmailClient') ? localStorage.getItem('EmailClient') : '',
        password: localStorage.getItem('passwordClient') ? localStorage.getItem('passwordClient') : '',
        isTelUpdate: WhatsAppNumber == tempWhatsAppNumber ? 'true' : 'true',
        telNumber: WhatsAppNumber == tempWhatsAppNumber ? WhatsAppNumber : WhatsAppNumber,
        // isTelUpdate: "false",
        // telNumber: "",
      };

      console.log("verify Object Otp", obj1);
      const result = dispatch(SendWhatappValidOtp(obj1));
      result
        .then((response) => {
          return response;
        })
        .then((data) => {
          console.log("data response", data.data.status == 1);

          if (data && data.data && data.data.status == 1) {
            // let obj12 = {
            //   accId: localStorage.getItem("accIdClient"),
            //   username: localStorage.getItem("EmailClient"),
            //   sPassword: localStorage.getItem("passwordClient"),
            //   strProjectId: "15",
            //   strOrignatorNumber: getOrigantryNumber ? getOrigantryNumber : "",
            // };

            // dispatch(UDF_Add_Redux1(obj12));

            const contactObj = {
              accId: localStorage.getItem('accIdClient') ? localStorage.getItem('accIdClient') : '',
              username: localStorage.getItem('EmailClient') ? localStorage.getItem('EmailClient') : '',
              sPassword: localStorage.getItem('passwordClient') ? localStorage.getItem('passwordClient')  : ''
            }
        
            dispatch(GetPortalContactDetailsByEmail(contactObj))

            toast.success("OTP verified successfully ");
            setSendButtonVisible(true);
            setOtp(null);
            setValues(Array(6).fill(""));
            setOtp(null);
            dispatch(setWhatsAppValidModal(false));
            const obj1 = {
              phone: WhatsAppNumber,
            };
            dispatch(DeleteWhatappValidationOtp(obj1));
          } else {
            toast.error("Invalid OTP or OTP expired");
            setOtp(null);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
        });

      console.log("result", result);
    }
  };

  const handleChange = (e, index) => {
    const newValues = [...values];
    newValues[index] = e.target.value;
    setValues(newValues);

    console.log("new velue", newValues);

    const concatenatedString = newValues.join("");
    console.log("concatenated string", concatenatedString);
    setOtp(concatenatedString);

    if (e.target.value.length === 1 && index < values.length - 1) {
      document.getElementById(`input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior

      // Create a new array and clear the values up to the current index
      const newValues = [...values];
      newValues[index] = "";

      // Move cursor to the previous input if it's not the first one
      if (index > 0) {
        const prevInput = document.getElementById(`input-${index - 1}`);
        if (prevInput) prevInput.focus();
      }

      setValues(newValues);
    }
  };

  const handleTextChange = (event) => {
    console.log("event", event.target.value);
    setWhatsAppNumber(event.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
      sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "400px" } }}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="clearfix">
          <DialogTitle>{"Validated WhatsApp"}</DialogTitle>
        </Box>
        <Button onClick={handleClose} autoFocus>
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent>
        <div>
          <DocuTextField
            defaultValue={getWhatappNumber}
            size={"small"}
            fieldLabel={"Enter Enter WhatsApp Number"}
            onChange={handleTextChange}
          />
          <div className="mt-3">
            {notificationMsgVisible ? <></> : ""}

            {sendbuttonVisible || notificationMsgVisible ? (
              <>
                <div className=" d-flex justify-content-between">
                  <Typography className="poppins-medium font-12 mt-1">
                    OTP SENT ! Plese Check Your WhatsApp
                  </Typography>

                  <Button
                    id="basic-button"
                    aria-haspopup="true"
                    className="btn-blue"
                    onClick={sendCode}
                  >
                    Send Code
                  </Button>
                </div>
              </>
            ) : (
              <>
                {resendVisibleButton ? (
                  <>
                    <div className=" d-flex justify-content-end">
                      <Button
                        id="basic-button"
                        aria-haspopup="true"
                        className="btn-blue"
                        // onClick={sendCode}
                      >
                        Resend Code In {count} Sec
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" d-flex justify-content-end">
                      <Button
                        id="basic-button"
                        aria-haspopup="true"
                        className="btn-blue"
                        onClick={sendCode}
                      >
                        Resent Code
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {sendbuttonVisible ? (
          ""
        ) : (
          <>
            <div>
              <div className="validation-container">
                {/* {values.map((value, index) => (
                  <input
                    key={index}
                    id={`input-${index}`}
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    className="validation-input"
                    maxLength={1}
                  />
                ))} */}

                {values.map((value, index) => (
                  <input
                    key={index}
                    id={`input-${index}`}
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="validation-input"
                    maxLength={1}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  id="basic-button"
                  aria-haspopup="true"
                  className="btn-blue mt-3"
                  onClick={submitOtp}
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default WhatsAppValidModal;
