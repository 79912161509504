import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as React from "react";

const ProSpan = styled("span")({
  display: "inline-block",
  height: "1em",
  width: "1em",
  verticalAlign: "middle",
  marginLeft: "0.3em",
  marginBottom: "0.08em",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(https://mui.com/static/x/pro.svg)",
});

function Label({ componentName, valueType, isProOnly }) {
  const content = (
    <span>
      {/* <strong>{componentName}</strong> for {valueType} editing */}
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a
            href="https://mui.com/x/introduction/licensing/#pro-plan"
            aria-label="Included on Pro package"
          >
            <ProSpan />
          </a>
        </Tooltip>
        {/* {content} */}
      </Stack>
    );
  }

  return content;
}

export default function CustomDatePicker({ value, onChange, comFun }) {
  // Validate the value prop
  let formattedValue = null;
  if (value) {
    formattedValue = dayjs(value).isValid() ? dayjs(value) : dayjs();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem
        label={
          <Label componentName="DatePicker" valueType="date" class="mt-0" />
        }
      >
        <DatePicker
          value={formattedValue}
          // onChange={(newValue) => comFun(newValue.format('YYYY-MM-DD'))}
          onChange={comFun}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              size: "small",
            },
          }}
          renderInput={(params) => <TextField {...params} />}
          className="mt-0"
        />
      </DemoItem>
    </LocalizationProvider>
  );
}
