import { Box } from "@mui/material";
import React from "react";
import CustomePortalMessageTable from "../../../components/CustomePortalMessageTable";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import { GetRetractedMessages_Json_Redux } from "../../../redux/api_helper";
import moment from "moment";
import NoData from "../../../components/NoData";
import CustomLoader from "../../../components/CustomLoader";
function RetractedMessages({ value }) {
  let dispatch = useDispatch();
  const { getRetractedMessages, getRetractedMessagesLoding } = useSelector(
    (state) => state.docu
  );

  let objReq = useLocalStorage("accid");

  let retrackObj = {
    accid: objReq.accid,
    email: objReq.email,
    password: objReq.password,
    ccode: "",
    emailAddress: "",
  };
  React.useEffect(() => {
    dispatch(GetRetractedMessages_Json_Redux(retrackObj, function (res) {}));
  }, [dispatch]);

  const TableHeder = [
    "Subject",
    "Recipient",
    "Approval Requested",
    "Issued On",
    "Due Date",
    "Status",
  ];

  // Create a JSON object from the array using the map method
 // Create a JSON object from the array using the map method
 const jsonObjectArray = getRetractedMessages.map((item) => {
  let approvedDate = "Not available";
  let viewedDate = "Not available";
  if (item["Date Approved"]) {
    approvedDate = moment(item["Date Approved"]).format("DD/MM/yyyy");
  }
  if (item.Viewed) {
    viewedDate = moment(item.Viewed).format("DD/MM/yyyy");
  }
  let viewStatus = "Pending";
  if (item.Viewed == "") {
    viewStatus = "Unread";
  } else if (item.Viewed != "" && item.Approved == "Yes") {
    viewStatus = "Approved";
  } else if (item.Viewed != "" && item.Approved == "No") {
    viewStatus = "Disapproved";
  } else {
    viewStatus = "Pending";
  }
  let objectAll = {
    Subject: item?.Subject ? item.Subject : "Not available",
    Recipient: item["Client Name"] ? item["Client Name"] : "Not available",
    ApprovalRequested: item["To be Approved"]
      ? item["To be Approved"]
      : "Not available",
    IssuedOn: item.Issued
      ? moment(item.Issued).format("DD/MM/yyyy")
      : "Not available",
    DueDate: item["Due Date"]
      ? moment(item["Due Date"]).format("DD/MM/yyyy")
      : "Not available",
    Status: viewStatus,
    MessageNo:item["Message No"] ? item["Message No"] : 'Not available',

    MessageID:item["Message ID"] ? item["Message ID"] : 'Not available',
    ReferenceName: item["Client Name"] ? item["Client Name"] : 'Not available',
    History: [
      {
        date: "Section",
        customerId: ": " + item.Section ? item.Section : "Not available",
      },
      {
        date: "Date Approved",
        customerId: ": " + approvedDate,
      },
      {
        date: "Viewed On",
        customerId: ": " + viewedDate,
      },
      {
        date: "Email ID",
        customerId: ": " + item.Emailid ? item.Emailid : "Not available",
      },
      {
        date: "Sent By",
        customerId:
          ": " + item["Sent/Received By"]
            ? item["Sent/Received By"]
            : "Not available",
      },
    ],
  };
  return objectAll;
});

  return (
    <>
      <Box>
        {getRetractedMessagesLoding ? (
          <>
            <Box>
              <CustomLoader />
            </Box>
          </>
        ) : jsonObjectArray && jsonObjectArray.length > 0 ? (
          <>
            <CustomePortalMessageTable
              TableHeder={TableHeder}
              TableData={jsonObjectArray}
              value={value}
            />
          </>
        ) : (
          <NoData messsage="No Message here" />
        )}
      </Box>
    </>
  );
}

export default RetractedMessages;
