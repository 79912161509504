import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { toast } from "react-toastify";
import { useLocalStorage } from "../../../custom-hook/useLocalStorage";
import {
  ad_Json_GetAllClientList_Action,
  ad_Json_GetAllContacts_Action,
  ad_Json_UpdateContact_Action,
  ad_UpdateContactField_Json_Action,
} from "../../../redux/dev_aditya_action";
import { getAllContactsByClientID } from "../../../redux/dev_chetan_api_helper";
import ContactMainForm from "./ContactMainForm";

let folderData;
let clientData;
let defaultclientData;
let clientName;
function AddContacts({
  addContactData,
  contactDetails,
  handleClose51,
  refreshData,
}) {
  // console.log(addContactData, "addContactData11111", contactDetails);
  const [contact, setContact] = useState([]);
  const [Contactudfnull, setContactudfnull] = useState(false);
  const getAllStateReduxSonam = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [fillcontact, setFillContact] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = searchParams.get("val");
  const [agrno, setAgrNo] = useState(localStorage.getItem("agrno"));
  const [password, setPassword] = useState(localStorage.getItem("Password"));
  const [Email, setEmail] = useState(localStorage.getItem("Email"));
  const [folderId, setFolderId] = useState(localStorage.getItem("FolderId"));
  const [value, setValue] = React.useState(tabValue ? tabValue : "1");
  const [Importdata, setImportdata] = useState([]);
  const [Importcontactdata, setImportcontactdata] = useState({});
  const [clientNames, setclientNames] = useState("");
  const [clientIddata, setClientIddata] = useState("");
  const [ImportContact, setImportContact] = useState([]);
  const [contactlistdata, setContactlistdata] = useState([]);
  const [defaultClient, setdefaultClient] = useState(null);
  const localObj = useLocalStorage();
  const [txtValue, setTxtValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [userContactDetails, setContactDetails] = useState({
    Title: "",
    FirstName: "",
    LastName: "",
    ReferenceName: "",
    MainContact: false,
    Inactive: false,
    GreetingName: "",
    EmailName: contactDetails?.[0]["E-Mail"]
      ? contactDetails?.[0]["E-Mail"]
      : "",
    MainUserId: -1,
    MainUserName: "",
    MainLine1Name: "",
    MainLine2Name: "",
    MainLine3Name: "",
    MainTownName: "",
    MainPostcodeName: "",
    Maincontactcountry: "",
    MainTelephoneName: "",
    MainMobileName: "",
    mainCountry: "",
    billingsCountry: "",
    ragistersCountry: "",
    ReferenceID: "",
    BirthDate: "",
    RolesData: "",
    Base64ImgData: "",
    CreatePortal: false,
    Notes: "",
  });

  // upload document modal start
  const location = useLocation();
  const navigation = useNavigate();
  const { ad_GetAllContacts, ad_GetAllClientList } = useSelector(
    ({ aditya }) => aditya
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(contactData, "contactData");
    if (contactDetails && contactDetails.length > 0) {
      let contactData = {
        FirstName: userContactDetails.FirstName
          ? userContactDetails.FirstName
          : "",
        LastName: userContactDetails.LastName
          ? userContactDetails.LastName
          : "",
        Add1: userContactDetails.MainLine1Name
          ? userContactDetails.MainLine1Name
          : "",
        Add2: userContactDetails.MainLine2Name
          ? userContactDetails.MainLine2Name
          : "",
        Add3: userContactDetails.MainLine3Name
          ? userContactDetails.MainLine3Name
          : "",
        Town: userContactDetails.MainTownName
          ? userContactDetails.MainTownName
          : "",
        PostCode: userContactDetails.MainPostcodeName
          ? userContactDetails.MainPostcodeName
          : "",
        Country: userContactDetails.mainCountry
          ? userContactDetails.mainCountry
          : "United Kingdom",
        ManagerName:
          userContactDetails.FirstName + " " + userContactDetails.LastName,
        Role: userContactDetails.RolesData ? userContactDetails.RolesData : "",
        Tel: userContactDetails.MainTelephoneName
          ? userContactDetails.MainTelephoneName
          : "",
        Mobile: userContactDetails.MainMobileName
          ? userContactDetails.MainMobileName
          : "",
        greeting: userContactDetails.GreetingName
          ? userContactDetails.GreetingName
          : "",
        Contactemail: userContactDetails.EmailName
          ? userContactDetails.EmailName
          : "",
        note: userContactDetails.Notes ? userContactDetails.Notes : "",
        emailupdate: contactDetails[0]["E-Mail"]
          ? contactDetails[0]["E-Mail"]
          : "",
        CActive: userContactDetails.Inactive === false ? "Yes" : "No",
        AssignedManager: userContactDetails.MainUserId
          ? userContactDetails.MainUserId
          : -1,
        maincontact: userContactDetails.MainContact
          ? userContactDetails.MainContact
          : false,
        CCode: contactDetails[0].OriginatorNo
          ? contactDetails[0].OriginatorNo
          : "-1",
        Salutation: userContactDetails.Title ? userContactDetails.Title : "",
        accid: agrno,
      };

      dispatch(
        ad_Json_UpdateContact_Action(contactData, (res) => {
          // dispatch(setAllContactsByClientID([]));
          dispatch(
            getAllContactsByClientID(
              contactDetails[0]?.OriginatorNo,
              localObj?.FolderId
            )
          );
          if (location.pathname === "/user/portal-messages") {
            navigation("/user/portal-messages");
          }

          toast.success("Contact updated");
          handleClose51();
        })
      );
      let contactBody = {
        ClientId: contactDetails[0]?.OriginatorNo
          ? contactDetails[0]?.OriginatorNo
          : "-1",
        ContactNo: contactDetails[0]?.ContactNo,
        fieldName: "BirthDate",
        fieldValue: userContactDetails.BirthDate,
        projectid: "171",
      };
      dispatch(ad_UpdateContactField_Json_Action(contactBody, (res) => {}));
    }
    refreshData && refreshData();
  };

  useEffect(() => {
    dispatch(
      ad_Json_GetAllContacts_Action((res) => {
        // console.log(res,"===== ad_Json_GetAllContacts_Action res")
      })
    );

    dispatch(
      ad_Json_GetAllClientList_Action((res) => {
        // console.log(res,"=== ad_Json_GetAllClientList_Action res");
        if (res && res.length > 0) {
          const filtercontact = res.find(
            (obj) => obj.ClientId == contactDetails[0].OriginatorNo
          );
          setdefaultClient(filtercontact);
        } else {
          const filteredData = res.find(
            (obj) => obj.ClientId === addContactData.Clientid
          );

          if (filteredData) {
            setdefaultClient(filteredData);
            defaultclientData = filteredData.ClientId;
            console.log(filteredData, "filteredData", res);
          }
        }
      })
    );
  }, [dispatch]);

  const updateReferenceID = (client) => {
    let data = { ...userContactDetails };
    data = { ...data, ReferenceID: client };
    console.log(data, "Update ReferenceID");
    setContactDetails(data);
  };

  const onChangebussines = (event, value) => {
    event.preventDefault();
    if (value) {
      console.log(value, "valueclientid");
      clientData = value.ClientId;
      defaultclientData = value.ClientId;
      localStorage.setItem("origiNator", clientData);
      clientName = value.Client;
      setclientNames(clientName);
      // setDefaultFolders(value);
      setdefaultClient(value);
      setClientIddata(value.ClientId);
      updateReferenceID(value.Client);
    } else {
    }
  };

  const onChangecontactlist = (event, value) => {
    event.preventDefault();
    if (value) {
      let result = contactlistdata.filter(
        (el) => el["EmailName"] === value.EMailId
      );
      setContact(result[0]);
    } else {
    }
  };

  const onChangeImportData = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    console.log(inputValue, "import_data");
    setImportdata(inputValue);
  };

  const handleOptionClick = (item) => {
    console.log(item, "onSelectData");
    let data = { ...userContactDetails };
    data = {
      ...data,
      ["Title"]: "",
      ["FirstName"]: "",
      ["LastName"]: "",
      ["ReferenceName"]: "",
      ["MainContact"]: "",
      ["Inactive"]: "",
      ["GreetingName"]: "",
      ["EmailName"]: "",
      ["MainUserId"]: -1,
      ["MainLine1Name"]: "",
      ["MainLine2Name"]: "",
      ["MainLine3Name"]: "",
      ["MainTownName"]: "",
      ["Notes"]: "",
      ["MainPostcodeName"]: "",
      ["Maincontactcountry"]: "",
      ["MainTelephoneName"]: "",
      ["MainMobileName"]: "",
      ["mainCountry"]: "",
      ["billingsCountry"]: "",
      ["ragistersCountry"]: "",
      ["ReferenceID"]: "",
      ["CreatePortal"]: "",
      ["Base64ImgData"]: "",
      ["RolesData"]: null,
    };
    setContactDetails(data);
    setImportcontactdata("");
    setTxtValue(item);
    setOpen(false);
    setImportcontactdata(item);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      console.log(e.target.value, "onChangeImportData");
    }
  };

  const handleChangeTextArea = (e) => {
    e.preventDefault();
    let data = { ...userContactDetails };
    data = { ...data, ["Notes"]: e.target.value };
    setContactDetails(data);
  };

  useEffect(() => {
    setAgrNo(localStorage.getItem("agrno"));
    setPassword(localStorage.getItem("Password"));
    setEmail(localStorage.getItem("Email"));
    setFolderId(localStorage.getItem("FolderId"));
    // setIntUserid(localStorage.getItem("UserId"));
    if (contactDetails && contactDetails.length > 0) {
      // console.log(contactDetails, "contactdetailssonam");
      let item = contactDetails[0];
      let data = { ...userContactDetails };
      data = {
        ...data,
        ["Title"]: item.Title,
        ["FirstName"]: item["First Name"],
        ["LastName"]: item["Last Name"],
        ["ReferenceName"]: "",
        ["MainContact"]: item["Main Contact"],
        ["Inactive"]: item.Active === "Yes" ? false : true,
        ["GreetingName"]: item.Greeting,
        ["EmailName"]: item["E-Mail"],
        ["MainUserId"]: item["Assigned Manager"],
        ["MainLine1Name"]: item["Address 1"],
        ["MainLine2Name"]: item["Address 2"],
        ["MainLine3Name"]: item["Address 3"],
        ["MainTownName"]: item.Town,
        ["Notes"]: item.Note,
        ["MainPostcodeName"]: item.Postcode,
        ["Maincontactcountry"]: item.Country,
        ["MainTelephoneName"]: item.Tel,
        ["MainMobileName"]: item.Mobile,
        ["mainCountry"]: "",
        ["billingsCountry"]: "",
        ["ragistersCountry"]: "",
        ["ReferenceID"]: clientNames,
        ["CreatePortal"]: item["Portal User"],
        ["Base64ImgData"]: item.imgPath,
        ["BirthDate"]: item["Date Of Birth"],
        ["RolesData"]: item?.Role,
      };
      setContactDetails(data);
    }
    const clientName = localStorage.getItem("ClientName");
    // Update userContactDetails state with the retrieved value
    if (clientName) {
      setContactDetails((prevState) => ({
        ...prevState,
        ReferenceID: clientName,
      }));
    }
  }, []);

  const clearDataCard = () => {
    console.log("cleardata", getAllStateReduxSonam);
    setdefaultClient(null);
    setContactudfnull(true);
    let data = { ...userContactDetails };
    data = {
      ...data,
      ["Title"]: "",
      ["FirstName"]: "",
      ["LastName"]: "",
      ["ReferenceName"]: "",
      ["MainContact"]: "",
      ["Inactive"]: "",
      ["GreetingName"]: "",
      ["EmailName"]: "",
      ["MainUserId"]: -1,
      ["MainLine1Name"]: "",
      ["MainLine2Name"]: "",
      ["MainLine3Name"]: "",
      ["MainTownName"]: "",
      ["Notes"]: "",
      ["MainPostcodeName"]: "",
      ["Maincontactcountry"]: "",
      ["MainTelephoneName"]: "",
      ["MainMobileName"]: "",
      ["mainCountry"]: "",
      ["billingsCountry"]: "",
      ["ragistersCountry"]: "",
      ["ReferenceID"]: "",
      ["CreatePortal"]: "",
      ["Base64ImgData"]: "",
      ["RolesData"]: null,
    };
    setContactDetails(data);
  };

  return (
    <Box className="container-fluid p-0">
      <Box sx={{ width: "100%", typography: "body1" }} className="mt-3">
        <Box className="general-tab white-box">
          <Box className="d-lg-flex">
            <Box className="mb-3 pe-2 me-2">
              <Box className="position-sticky top-0">
                <textarea
                  className="form-control textarea-2 mt-3"
                  placeholder="Notes.."
                  name="Notes"
                  value={userContactDetails?.Notes}
                  onChange={handleChangeTextArea}
                ></textarea>
              </Box>
            </Box>

            <Box className="mb-3 w-100">
              <Box className="well mb-4">
                <h2 className="font-20 mb-3 text-black">Contact Details</h2>
                <Box className="well well-2 mb-3">
                  <Grid container spacing={2}>
                    <Grid item xs={10} md={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} >
                          <h2 className="font-12 bold mb-2 text-black">
                            Import Existing DocuSoft Contact
                          </h2>
                          <Autocomplete
                            // {...contactlist}
                            options={ad_GetAllContacts}
                            key={`contactlistkey`}
                            getOptionLabel={(option) => option.Email}
                            id="contactlist"
                            clearOnEscape
                            onChange={onChangecontactlist}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                name="contactlist"
                                value={userContactDetails.EmailName}
                                label="Enter Contact Email"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <h2 className="font-12 bold mb-2 text-black">
                            Import from Companies House
                          </h2>
                          <Autocomplete
                            fullWidth
                            options={ImportContact} // Pass the entire ImportContact array
                            getOptionLabel={(option) =>
                              option.FirstName + " " + option.LastName
                            }
                            onChange={(e, value) => setImportdata(value)}
                            onKeyDown={handleKeyDown}
                            // inputValue={ImportContact}
                            noOptionsText="No matches found"
                            filterOptions={(x) => x}
                            autoComplete
                            includeInputInList
                            value={txtValue}
                            open={open} // Controlled by state
                            onOpen={() => setOpen(true)} // Open the Autocomplete dropdown
                            onClose={() => setOpen(false)} // Close the Autocomplete dropdown
                            renderOption={(props, option) => {
                              return (
                                !option.resigned_on && (
                                  <div>
                                    <li
                                      {...props}
                                      onClick={() => {
                                        handleOptionClick(option); // Pass the id directly
                                      }}
                                    >
                                      <Grid container alignItems="center">
                                        <Grid
                                          item
                                          sx={{
                                            width: "calc(100% - 44px)",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {option.FirstName +
                                            " " +
                                            option.LastName}
                                        </Grid>
                                      </Grid>
                                    </li>
                                  </div>
                                )
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                name="importclient"
                                onChange={onChangeImportData}
                                label="Enter Company Name"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      md={1}
                      className="d-flex align-items-end pb-1"
                    >
                      <Button className="min-width-auto text-danger">
                        <HighlightOffIcon
                          className="font-32"
                          onClick={clearDataCard}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="well well-2 mb-3">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={ad_GetAllClientList}
                        key={"someDynamicValue"} // Use a dynamic and unique key here
                        getOptionLabel={(option) => option.Client}
                        id="clear-on-escape-teams"
                        clearOnEscape
                        value={defaultClient || null}
                        onChange={onChangebussines}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            option.Client.toLowerCase().includes(
                              inputValue.toLowerCase()
                            )
                          )
                        }
                        autoHighlight // Highlight the first suggestion
                        selectOnFocus // Select suggestion when input is focused
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="Selectteamsa"
                            value={""}
                            label="Reference List"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <ContactMainForm
                      clientNames={clientNames}
                      contact={contact}
                      setContact={setContact}
                      contactlistdata={contactlistdata}
                      userContactDetails={userContactDetails}
                      setContactDetails={setContactDetails}
                      Importcontactdata={Importcontactdata}
                      setImportcontactdata={setImportcontactdata}
                      contactDetails={contactDetails}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* <hr /> */}

              <Box className="well mb-4" style={{ display: "none" }}>
                <h2 className="font-20 mb-3 text-black">AML Details</h2>
              </Box>

              {/* <hr /> */}

              {/* <Box className="well mb-4">
                <h2 className="font-20 mb-3 text-black">UDF Details</h2>
              </Box> */}
              {addContactData && addContactData == {} ? (
                <Button
                  style={{ marginTop: "5px" }}
                  variant="contained"
                  //  disabled={!clientData || !selectedFolderID}
                  onClick={handleSubmit}
                  className="btn-blue-2"
                >
                  Add New Contact
                </Button>
              ) : contactDetails && contactDetails.length > 0 ? (
                <Button
                  style={{ marginTop: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                  className="btn-blue-2"
                >
                  Update Contact
                </Button>
              ) : (
                <Button
                  style={{ marginTop: "5px" }}
                  variant="contained"
                  disabled={!defaultclientData}
                  onClick={handleSubmit}
                  className="btn-blue-2"
                >
                  Add New Contact
                </Button>
              )}
            </Box>
          </Box>

          <Box className="mb-3">
            {/* <MainContact 
      userContactDetails={userContactDetails}
      setContactDetails={setContactDetails}
      /> */}
            <Grid container spacing={3}></Grid>
          </Box>
        </Box>
        {/* end */}
      </Box>
    </Box>
  );
}
export default AddContacts;
