import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchdocuRequestData } from "../../redux/api_helper";
import RenderRequest from "../../components/RenderRequest";
import {useLocation, useSearchParams } from "react-router-dom";
import NoData from "../../components/NoData";
import UserRenderRequest from "../../user/componants/requestComponant/UserRenderRequest";
import { useCustomerId } from "../../redux/get_email_form_url";

const ClientRequestDetails = () => {
  const [load, setLoad] = useState(false);
  let portalAllRequestAll = useSelector(
    (state) => state.docu.docuRequestDataClientAll
  );

  const location = useLocation();
  const {
    PortalDocID: portalDocID,
    Subject,
    Section,
    ReceivedDate,
    SendDate,
    ReferenceName,
    ClientName,
    customerId,
    Issued,
    Viewed,
    ViewDate,
    ExpiryDate,
    ActionBy,
    Status,
    EmailID,
    SendMessage,
    MessageID,
    messageStatus,
    isClient,
    senderName,
    TabIndex,
    data,
  } = location?.state || {};

  const [searchParams] = useSearchParams();
  // const portalDocID = searchParams.get("PortalDocID");
  // const MessageID = searchParams.get("PortalDocID");

  // const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  // const customerId = searchParam.get("customerId");
  // const EmailID = searchParam.get("EmailID");

  const [allShowDoc, setAllShowDoc] = useState(portalAllRequestAll);

  const dispatch = useDispatch();
  const customerEmail = useCustomerId();

  useEffect(() => {
    dispatch(fetchdocuRequestData(customerId || EmailID));
  }, []);

  useEffect(() => {
    setLoad(false);
    if (portalDocID) {
      const newData = portalAllRequestAll.filter(
        (portalReq) => portalReq.PortalDocId === portalDocID
      );
      setAllShowDoc(newData);
      setLoad(true);
    }
  }, [portalAllRequestAll, portalDocID, MessageID]);

  const LoginClient = localStorage.getItem("LoginClient");
  return (
    <>
      <Box className="">
        <h2 className="title mb-0">Requests</h2>
        <Grid container spacing={2} className="mt-2">
          {load && allShowDoc && allShowDoc.length > 0 ? (
            LoginClient ? (
              allShowDoc.map((accordionItem, accordionIndex) => (
                <RenderRequest
                  accordionItem={accordionItem}
                  accordionIndex={accordionIndex}
                />
              ))
            ) : (
              <UserRenderRequest accordionItem={allShowDoc} id={0} />
            )
          ) : (
            <Grid item xs={12}>
              <NoData className="w-100" message={"No Data Here"} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ClientRequestDetails;
