import { toast } from "react-toastify";
import { portalClient, portalUser } from "./AxiosInstance";
import { setPortalMessageComments } from "./dev_chetan_slice";
import { setGetUserDetails, setviewHistoryData } from "./dev_sonam_slice";
let commanEmail = "patrick.docusoft@outlook.com";
let portalUserObj = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
    // userEmail:commanEmail
};
let commanObj = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password")
}
let commanAccid = {
    accId: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password")
}
let portalUserObjData = {
  accid: localStorage.getItem("agrno") ? localStorage.getItem("agrno") :"0003",
  email: localStorage.getItem("Email") ? localStorage.getItem("Email") : "patrick@docusoft.net",
  password: localStorage.getItem("password") ? localStorage.getItem("password") : "UGF0cmljazEyMy4=",
};

const LoginClient = localStorage.getItem("LoginClient");

export const GetMessageViewHistory_Json = (messageIdData,userEmail) => async (dispatch) => {
    try {
      const res = await portalClient.post("/GetMessageViewHistory_Json", {
        ...portalUserObj,
        messageId: messageIdData,
        userEmail:userEmail
      });
      if(res?.data?.d){
        dispatch(setviewHistoryData(JSON.parse(res.data.d)));
        return "Done";
      }else{
        return "Done";
      }
      
    } catch (err) {
      console.log('Error while calling GetMessageViewHistory_Json',err);
    }
  };

  export const Json_GetUserDetails = (emailData,callBack) => async (dispatch) => {
    try {
      const res = await portalClient.post("/Json_GetUserDetails", {
        ...commanObj,
        stremail: emailData,
      });
      if(res?.data?.d){
        let userDetail = JSON.parse(res.data.d);
        dispatch(setGetUserDetails(userDetail?.Table));
        callBack(userDetail?.Table);
        return "Done";
      }else{
        return "Done";
      }
      
    } catch (err) {
      console.log('Error while calling Json_GetUserDetails',err);
    }
  };

  export const UpdateRequestedDocStatusAction_Json_ReduxClient = (obj, callBack) => async (dispatch) => {
   if(LoginClient){
      try {
        let res = await portalUser.post("UpdateRequestedDocStatusAction_Json",obj);
        if (res) {
            let str = JSON.parse(JSON.stringify(res.data));
            console.log("UpdateRequestedDocStatusAction_Json", str.d);
            callBack(str.d);          
        }
    } catch (error) {
        console.log("Network error: No response received from server, UpdateRequestedDocStatusAction_Json", error)
    }
   }else{
    try {
      let res = await portalClient.post("UpdateRequestedDocStatusAction_Json",obj);
      if (res) {
          let str = JSON.parse(JSON.stringify(res.data));
          console.log("UpdateRequestedDocStatusAction_Json", str.d);
          callBack(str.d);          
      }
  } catch (error) {
      console.log("Network error: No response received from server, UpdateRequestedDocStatusAction_Json", error)
  }
   }
}

export const UpdateRequestedDocStatusAction_Json_ReduxUser = (obj, callBack) => async (dispatch) => {
  try {
      let res = await portalClient.post("UpdateRequestedDocStatusAction_Json",obj);
      if (res) {
          let str = JSON.parse(JSON.stringify(res.data));
          console.log("UpdateRequestedDocStatusAction_Json", str.d);
          callBack(str.d);          
      }
  } catch (error) {
      console.log("Network error: No response received from server, UpdateRequestedDocStatusAction_Json", error)
  }
}


export const RequestedDocumentCreated_Json_ReduxClient = (obj, callBack) => async (dispatch) => {
    try {
        // let res = await portalClient.post("RequestedDocumentCreated_Json", obj);
        let res = await portalUser.post("RequestedDocumentCreated_Json", obj);
        if (res) {
            let js = JSON.parse(JSON.stringify(res.data));
            callBack(js)
        }
    } catch (error) {
        console.log("Network error: No response received from server, RequestedDocumentCreated_Json", error)
    }
}



export const AddTaskComment_Json_Redux =(portalDocId, comment,customerId,callBack)=>async (dispatch) => {
  try {
    const response = await portalClient.post('/AddTaskComment_Json',{
      ...portalUserObjData,
      messageID: portalDocId,
      response: comment,
      ConatctEmail:customerId? customerId : "patrick.docusoft@outlook.com"
    });
    if(Boolean(response?.data?.d)){
      toast.success("Comment added successfully!!");
      callBack(response?.data?.d)
      // dispatch(getPortalMessageComments(portalDocId));
      return;
    }else{
      toast.error("Something went wrong please try again");
    }
  } catch (err) {
    console.log("Error while calling AddTaskComment_Json", err);
  }
}


export const getPortalMessageComments=(portalDocId)=>async (dispatch) => {
  try {
    const response = await portalClient.post('/GetCommentsHtml_Json',{
      ...portalUserObjData,
      messageId: portalDocId
    });
    if(response?.data?.d){
      dispatch(setPortalMessageComments(response?.data?.d));
    }else{
      dispatch(setPortalMessageComments(""));
    }
  } catch (err) {
    console.log("Error while calling GetCommentsHtml_Json", err);
  }
}

export const GetPortalInformationTotalByContact_Json = (callBack) => async (dispatch) => {
  try {
      // let res = await portalClient.post("RequestedDocumentCreated_Json", obj);
      let res = await portalClient.post("GetPortalInformationTotalByContact_Json", {
        ...commanAccid,
        username: localStorage.getItem("EmailClient") ? localStorage.getItem("EmailClient") : "patrick.docusoft@outlook.com"
      });
      if (res) {
        console.log(res,"dataofpendingrquest")
          let js = JSON.parse(JSON.stringify(res.data));
          callBack(JSON.parse(js.d));
      }
  } catch (error) {
      console.log("Network error: No response received from server, GetPortalInformationTotal_Json", error)
  }
}
// export const GetPortalInformationTotal_Json = (obj, callBack) => async (dispatch) => {
//   try {
//       // let res = await portalClient.post("RequestedDocumentCreated_Json", obj);
//       let res = await portalClient.post("GetPortalInformationTotal_Json", obj);
//       if (res) {
//         console.log(res,"dataofpendingrquest")
//           let js = JSON.parse(JSON.stringify(res.data));
//           callBack(JSON.parse(js.d));
//       }
//   } catch (error) {
//       console.log("Network error: No response received from server, GetPortalInformationTotal_Json", error)
//   }
// }
