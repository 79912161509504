import VerifiedIcon from "@mui/icons-material/Verified";
import {
    Box,
    Grid,
    Switch,
    TextField,
    Tooltip
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

// import AutoComplete from './AutoComplete';
import DrawIcon from "@mui/icons-material/Draw";
import FormControlLabel from "@mui/material/FormControlLabel";
import AutoComplete from "../../../components/AutoComplete";
import FileUpload from "../../../components/FileUpload";
import {
    GetAllFolders_Json_Redux,
    GetPortalDocReqTemplates_Json_Redux,
    GetPortalRequestDocuments_Json_Redux,
    PortalDocTemplateCreated_Json_Redux,
    PortalDocTemplateExists_Json_Redux,
    PortalRequestedDocCreated_Json_Redux,
    PortalRequestedDocDeleted_Json_Redux,
    PortalRequestedDocUpdated_Json1_Redux
} from "../../../redux/api_helper";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { setRequestDocument_Redux } from "../../../redux/docuSlice";

const agrno = localStorage.getItem("agrno");
const password = localStorage.getItem("password");
const FolderId = localStorage.getItem("FolderId");
const Email = localStorage.getItem("Email");

export default function CreateTemplate({ isHideTitle, selectedTemplate }) {
  const redx = useSelector((state) => state.docu);
  const redx1 = useSelector((state) => state.docu.ComposePortalMgsObj);

  const [addDocumentList, setAddDocumentList] = React.useState([]);

  const [isExists, setIsExists] = React.useState("");
  const [txtType, settxtType] = React.useState("");
  const [docIdOnRow, setDocIdOnRow] = React.useState("");
  const [txtDocName, setTxtDocName] = React.useState("");
  const [txtExpireDays, setTxtExpireDays] = React.useState("");
  const [txtReminddays, setTxtRenddays] = React.useState("");

  const [getFolderList, setGetFoldersList] = React.useState([]);
  const [defaltFolderData, setDefaltFolderData] = React.useState({});

  const [txtTempName, setTxtTemplateName] = React.useState(
    redx.RequestDocumnets.TemplateName
      ? redx.RequestDocumnets.TemplateName
      : null
  );
  const [txtFolderData, settxtFolderData] = React.useState({});
  const [editId, setEditId] = React.useState(null);
  const [activeStatus, setActiveStatus] = React.useState(true);
  const [btnbool, setBtnBool] = React.useState(false);
  const [btnUpdate, setBtnUpdate] = React.useState("Add Request");

  let dispatch = useDispatch();

  React.useEffect(() => {
    if (redx.RequestDocumnets || redx.RequestDocumnets.DocTempID) {
      getTemplatesObject();
    }
    // setTxtTemplateName(redx.RequestDocumnets ? redx.RequestDocumnets : null)
  }, []);

  const getTemplatesObject = () => {
    try {
      const obj = {
        id: redx.RequestDocumnets.DocTempID,
      };

      dispatch(
        GetPortalRequestDocuments_Json_Redux(obj, function (res) {
          if (res) {
            // dispatch(setRequestDocument_Redux(value));
            if (res.length > 0) {
              let result = res.map((e) => {
                let gtype = RequestType.filter(
                  (tr) => tr.id === e.RequestDocType
                );

                let strGuid = uuidv4().replace(/-/g, "");
                let o = { ...e }; // Create a shallow copy of the object
                o.DocName = e.DocName;
                o.id = strGuid;
                o.DocuEx = e.ExpiryInDays;
                o.DocuRemind = e.RemindIn;
                o.DocuType = gtype.length > 0 ? gtype[0] : "";
                o.DocId = e.DocId;
                o.DocTempId = e.DocTempId;

                return o; // Return the modified object
              });

              console.log("GetPortalRequestDocuments_Json", result);

              if (result.length > 0) {
                setAddDocumentList(result);
              }
            }
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalUsersRequestedDocs_Json_Redux",
        error
      );
    }
  };

  const handleClickAddDoc = () => {
    if (
      selectedTemplate &&
      selectedTemplate.action &&
      selectedTemplate.action === "Edit"
    ) {
      getTemplatesObject();
      return;
    }

    let strGuid = uuidv4().replace(/-/g, "");
    try {
      if (txtDocName) {
        let obj = {
          id: strGuid,
          DocName: txtDocName,
          DocuEx: txtExpireDays,
          DocuRemind: txtReminddays,
          DocuType: txtType,
        };
        // Check if the ID is unique
        setAddDocumentList((pre) => [...pre, obj]);
      } else {
        toast.warning("Document Name is empty");
      }
    } catch (error) {
      toast.error("Error Please Try again");
    }
  };

  const AddDocument = () => {
    try {
      if (txtDocName) {
        let o = {
          name: txtDocName,
          templateid: redx.RequestDocumnets.DocTempID,
          expire: txtExpireDays,
          remind: txtReminddays,
          RequestDocType: txtType.id,
        };
        dispatch(
          PortalRequestedDocCreated_Json_Redux(o, function (res) {
            console.log("PortalRequestedDocCreated_Json_Redux", res);
            if (res) {
              getTemplatesObject();
              toast.success("Template and Document Created");
            } else {
              toast.error("Faild Please Try again");
            }
          })
        );
      } else {
        toast.error("Name is empty");
      }
    } catch (error) {
      toast.error("Faild Please Try again");
      console.log("create teplate error", error);
    }
  };

  const AddRequestFun = (e, value) => {
    console.log("Add request =====", value);

    settxtType(value);
  };

  React.useEffect(() => {
    dispatch(
      GetAllFolders_Json_Redux(function (res) {
        if (res) {
          setGetFoldersList(res);
          let result = res.filter((e) => e.FolderID === parseInt(FolderId));
          if (result.length > 0) {
            setDefaltFolderData(result[0]);
            settxtFolderData(result[0]);
          }
        }
      })
    );
    setIsExists("");
  }, [dispatch]);

  const [isTyping, setIsTyping] = React.useState(false);

  const handleChangeTemplateName = (e) => {
    try {
      setIsTyping(true);
      setTxtTemplateName(e.target.value);
      setIsExists("");
    } catch (error) {
      console.log("folder click option", error);
    }
  };

  const handleFolderChange = (e, value) => {
    try {
      console.log(value);
      settxtFolderData(value);
    } catch (error) {
      console.log("folder click option", error);
    }
  };

  let RequestType = [
    { id: 0, name: "Request Document" },
    { id: 1, name: "Request Signature" },
    { id: 2, name: "Send Form" },
    { id: 3, name: "For Info" },
  ];

  const getRemindDays = (e, value) => {
    setTxtRenddays(e.target.value);
  };

  const getExpireDayes = (e, value) => {
    setTxtExpireDays(e.target.value);
  };

  const addDocumnetName = (e) => {
    setTxtDocName(e.target.value);
  };

  const removeDocument = (data) => {
    console.log(data, "remove do1");
    if (selectedTemplate) {
      PortalRequestedDocDeleted_Json(data);
    } else {
      try {
        Swal.fire({
          // title: "Are you sure you want to delete this item?",
          text: "Are you sure you want to delete this item?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            let res = addDocumentList.filter((e) => e.id !== data.id);
            console.log(res, "remove do");
            setAddDocumentList(res);
          }
        });
      } catch (error) {
        console.log("Remove documnet ", error);
      }
    }
  };

  const PortalRequestedDocDeleted_Json = (dt) => {
    try {
      Swal.fire({
        // title: "Are you sure you want to delete this item?",
        text: "Are you sure you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let o = {
            templateid: redx.RequestDocumnets.DocTempID,
            id: dt.DocId,
          };
          dispatch(
            PortalRequestedDocDeleted_Json_Redux(o, function (res) {
              if (res) {
                console.log("PortalRequestedDocDeleted_Json_Redux", res);
                toast.success("Document deleted successfully");
                getTemplatesObject();
              } else {
                toast.error("Not Updated please try again");
              }
            })
          );
        }
      });
    } catch (error) {
      console.log(
        "Network error: No response received from server PortalRequestedDocDeleted_Json_Redux",
        error
      );
    }
  };

  const AddSigNature = (fileData) => {
    console.log("fileData", fileData);
    //https://signing.docuengager.com/Signing.aspx?accid=0003&email=patrick@docusoft.net&password=UGF0cmljazEyMy4=&sEmailId=patrick.docusoft@outlook.com&sendemail=&clientname=Jones%20Demo%20Client&option=upload&DocTempId=34&DocId=3&to=patrick.docusoft@outlook.com&template=34&rwndrnd=0.8166129123678032
    try {
      if (
        redx1.ToEmail &&
        redx1.ToEmail.length > 0 &&
        redx1.ToEmail[0]["E-Mail"]
      ) {
        console.log("add signature", fileData);
        let url = `https://Signing.docuengager.com/Signing.aspx?accid=${agrno}&email=${Email}&password=${password}&sEmailId=${redx1.ToEmail[0]["E-Mail"]}&sendemail=&clientname=${redx1.Client.Client}&option=upload&DocTempId=${fileData.DocTempId}&DocId=${fileData.DocId}&to=${redx1.ToEmail[0]["E-Mail"]}&template=${fileData.DocTempId}&rwndrnd=0.8166129123678032`;
        window.open(url);
        //handleCheckStatus(fileData.item)
      } else {
        toast.error("Please select the email");
      }
    } catch (error) {
      console.log("add signature", error);
    }
  };

  const CopyAddDocument = (copd) => {
    try {
      let o = {
        name: copd.DocName,
        templateid: redx.RequestDocumnets.DocTempID,
        expire: copd.DocuEx,
        remind: copd.DocuRemind,
        RequestDocType: copd.DocuType.id,
      };
      dispatch(
        PortalRequestedDocCreated_Json_Redux(o, function (res) {
          console.log("PortalRequestedDocCreated_Json_Redux", res);
          if (res) {
            getTemplatesObject();
            toast.success("Document Copyed Success");
          } else {
            toast.error("Faild Please Try again");
          }
        })
      );
    } catch (error) {
      toast.error("Faild Please Try again");
      console.log("create teplate error", error);
    }
  };

  const [editFormData, setEditFormData] = React.useState({
    DocName: "",
    DocuEx: "",
    DocuRemind: "",
    DocuType: {},
  });

  const handleEditClick = (item) => {
    let obj = RequestType.find((type) => type.id === item.DocuType.id) || {};
    console.log("handleedit", item);

    setEditId(item);
    setEditFormData({
      DocName: item.DocName,
      DocuEx: item.DocuEx,
      DocuRemind: item.DocuRemind,
      DocuType: obj,
      DocId: item.DocId,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };

  const handleDocuTypeChange = (event, newValue) => {
    setEditFormData({
      ...editFormData,
      DocuType: newValue,
    });
  };

  const hancelChangeActiveStatus = (e) => {
    try {
      console.log(e.target.checked);
      setActiveStatus(e.target.checked);
    } catch (error) {
      console.log("Active status error", error);
    }
  };

  const handleBlur = () => {
    if (isTyping) {
      PortalDocTemplateExists_Json();
      setIsTyping(false);
    }
  };

  const PortalDocTemplateExists_Json = () => {
    try {
      let o = {
        accId: agrno,
        email: Email,
        password: password,
        name: txtTempName.trim(),
        id: 0,
        folder: txtFolderData.FolderID ? txtFolderData.FolderID : FolderId,
      };

      console.log("create template", o);
      dispatch(
        PortalDocTemplateExists_Json_Redux(o, function (data) {
          if (data) {
            setIsExists("This temaplage name is already exists");
            toast("This temaplage name is already exists");
          } else {
            HandleClickPortalDocTemplateCreate();
            setIsExists("");
            // setIsExists("This temaplage name is allready")
          }
        })
      );
    } catch (error) {
      console.log("Network error PortalDocTemplateExists_Json_Redux", error);
    }
  };

  const HandleClickPortalDocTemplateCreate = () => {
    try {
      let o = {
        accId: agrno,
        password: password,
        email: Email,
        name: txtTempName,
        status: activeStatus,
        folder: txtFolderData.FolderID,
      };

      console.log("create template", o);
      dispatch(
        PortalDocTemplateCreated_Json_Redux(o, function (data) {
          if (data) {
            setTimeout(() => {
              toast.success("Template Created");
              getAllDocTemplate();
            }, 2000);
          }
        })
      );
    } catch (error) {
      console.log("Network error PortalDocTemplateCreated", error);
    }
  };

  function getAllDocTemplate() {
    try {
      let p = {
        accId: agrno,
        password: password,
        email: Email,
        allTemplates: "true",
        folder: txtFolderData.FolderID,
        forCompose: "true",
      };

      dispatch(
        GetPortalDocReqTemplates_Json_Redux(p, async function (res) {
          if (res.length > 0) {
            //Client - Manish233
            let folderName = txtFolderData.Folder + " - " + txtTempName;
            //console.log("GetPortalDocReqTemplates_Json_Redux2", folderName)
            let data = res.filter((temp) => temp.TemplateName === folderName);
            console.log("GetPortalDocReqTemplates_Json_Redux1", data);
            if (data.length > 0) {
              dispatch(setRequestDocument_Redux(data[0]));
              // await HandleCliekCreateTemplate(data[0].DocTempID);
            }
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server, GetPortalDocReqTemplates_Json_Redux",
        error
      );
    }
  }

  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const HandleCliekCreateTemplate = async () => {
    console.log("selectedFiles", selectedFiles, txtType);
    try {
      // Check if the document type is 3, but continue execution
      if (txtType.id === 3) {
        // Allow execution to continue if txtType.id is 3 without returning
        console.log("Type ID is 3, continuing with document creation...");
      }

      // Check if the document type is not 0 and ensure exactly one file is selected
      else if (txtType.id !== 0 && selectedFiles.length !== 1) {
        toast.error("Please upload document");
        return false; // Stop execution if this condition fails
      }
      // Prepare the document object for dispatch
      const o = {
        name: txtDocName,
        templateid: redx.RequestDocumnets.DocTempID,
        expire: txtExpireDays,
        remind: txtReminddays,
        RequestDocType: txtType.id,
        DocExtension: txtType.id !== 0 ? selectedFiles[0]?.FileType || "" : "", // Safeguard for undefined
        DocData: txtType.id !== 0 ? selectedFiles[0]?.Base64 || "" : "", // Safeguard for undefined
        username: redx1.ToEmail[0]["E-Mail"],
      };
      // Dispatch the action to create the document
      dispatch(
        PortalRequestedDocCreated_Json_Redux(o, (res) => {
          console.log("PortalRequestedDocCreated_Json_Redux Response:", res);

          if (res) {
            toast.success("Document Created");
            getTemplatesObject(); // Reload templates or do any related action
            setSelectedFiles([]); // Clear selected files after successful upload
          } else {
            toast.error("Failed. Please try again");
          }
        })
      );
    } catch (error) {
      // Handle any unexpected errors
      toast.error("Failed. Please try again");
      console.error("Create template error:", error);
    }
  };

  const HandleCliekUpdateTemplate = () => {
    try {
      if (txtType.id !== 0 && selectedFiles.length !== 1) {
        toast.error("Please upload document");
        return;
      }

      let o = {
        name: txtDocName,
        templateid: redx.RequestDocumnets.DocTempID,
        expire: txtExpireDays,
        remind: txtReminddays,
        docid: docIdOnRow,
        DocExtension: txtType.id !== 0 ? selectedFiles[0].FileType : "",
        DocData: txtType.id !== 0 ? selectedFiles[0].Base64 : "",
      };

      dispatch(
        PortalRequestedDocUpdated_Json1_Redux(o, function (res) {
          if (res) {
            console.log("PortalRequestedDocCreated_Json1", res);
            toast.success("Document updated successfully");
            getTemplatesObject();
          } else {
            toast.error("Not Updated please try again");
          }
        })
      );
    } catch (error) {
      console.log(
        "Network error: No response received from server response",
        error
      );
    }
  };

  const getFileExtension = (fileName) => {
    // Split the file name by the dot (.)
    const parts = fileName.split(".");
    // Return the last part, which is the extension
    return parts[parts.length - 1];
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const selectedFilesArray = Array.from(files);
    const filesData = [];
    selectedFilesArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = () => {
        let fileByte = reader.result.split(";")[1].replace("base64,", "");
        const fileData = {
          FileName: file.name,
          Base64: fileByte ? fileByte : "", // Base64 data of the file
          FileSize: file.size,
          Preview: reader.result, // Data URL for preview
          DocId: "",
          FileType: getFileExtension(file.name).toLowerCase(),
        };
        filesData.push(fileData);
        console.log(fileData, "file size check");
        // Check if this is the last file
        if (index === selectedFilesArray.length - 1) {
          // Add new files to the uploadedFiles array
          setSelectedFiles(filesData);
        }
      };
      reader.readAsDataURL(file); // Read file as data URL (base64)
    });
  };

  function byteArrayToBase64(byteArray) {
    try {
      if (byteArray) {
        let binaryString = "";
        for (let i = 0; i < byteArray.length; i++) {
          binaryString += String.fromCharCode(byteArray[i]);
        }
        return btoa(binaryString);
      } else {
        console.log("byteArray....", byteArray);
      }
    } catch (error) {
      console.log("Error is", error);
    }
  }

  const [isRowClickDisabled, setIsRowClickDisabled] = React.useState(false);

  const copyObject = (data) => {
    setIsRowClickDisabled(false);
    console.log("copy data", data);
    let dname = `Copy of ${data.DocName}`;
    setTxtDocName(dname);
    // if (selectedTemplate) {
    //     CopyAddDocument(data);
    // }

    // console.log("Copy  documnet ", data);
    // let strGuid = uuidv4().replace(/-/g, '');

    // try {
    //     let res = addDocumentList.find((e) => e.id === data.id);
    //     if (res) {
    //         let newObject = { ...res, id: strGuid };
    //         setAddDocumentList((pre) => [...pre, newObject]);
    //     }

    // } catch (error) {
    //     console.log("Remove documnet ", error)
    // }
  };

  const handleSelectedRow = (data) => {
    if (!isRowClickDisabled) {
      SelectedRowFn(data);
    }
  };

  const SelectedRowFn = (data) => {
    setSelectedFiles([]);

    setIsRowClickDisabled(false);

    try {
      setBtnUpdate("Update Request");
      setBtnBool(true);

      console.log("Selected DAta", data);

      setTxtDocName(data.DocName ? data.DocName : "");
      setDocIdOnRow(data.DocId);
      settxtType(data.DocuType);

      setTxtExpireDays(data.DocuEx ? data.DocuEx : "");
      setTxtRenddays(data.DocuRemind ? data.DocuRemind : "");

      const fileData = [
        {
          FileName: data.DocName + "." + data.DocExt,
          Base64: byteArrayToBase64(data.DocData), // Base64 data of the file
          DocId: "",
          FileType: data.DocExt,
        },
      ];
      setSelectedFiles(fileData);
    } catch (error) {
      console.log("Selected Row Error ", error);
    }
  };

  return (
    <Box className="container">
      <Box className="row">
        <Box className="col-md-12 m-auto">
          <Box className="white-box">
            {isHideTitle && <h2 className="title">Create Template</h2>}
            <Box className="well-box mb-3">
              <Box className="white-box p-4 mb-lg-0">
                <Grid container spacing={2} className="mb-">
                  <Grid item lg={5} xs={5} md={5}>
                    <AutoComplete
                      ComData={getFolderList}
                      target="Folder"
                      comFun={handleFolderChange}
                      lable={"Select Folder"}
                      defaultValue={txtFolderData}
                    ></AutoComplete>
                  </Grid>
                  <Grid item lg={5} xs={5} md={5}>
                    <TextField
                      required
                      label="Template Name"
                      // defaultValue="Hello World"
                      value={txtTempName}
                      className="w-100"
                      size="small"
                      onChange={handleChangeTemplateName}
                      onBlur={handleBlur}
                    />
                    <label sx={{ color: "red" }}>{isExists}</label>
                  </Grid>
                  <Grid item lg={2} xs={2} md={2}>
                    <Box className="mt-0">
                      <FormControlLabel
                        onChange={hancelChangeActiveStatus}
                        control={<Switch checked={activeStatus} />}
                        label="Active Status"
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* <hr /> */}

                <h2 className="title font-20 mt-3 text-black">2. </h2>
                {addDocumentList && addDocumentList.length > 0 ? (
                  <Box className="table-responsive">
                    <table className="table custom-table table-striped">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Expire in (Days)</th>
                          <th>Remind in (Days)</th>
                          <th>Request Type</th>
                          <th>File</th>
                          <th>Status</th>
                          {/* <th>Files</th> */}
                          <th width="200">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addDocumentList.map((item, index) => {
                          let filename = item.DocData
                            ? item.DocName + "." + item.DocExt
                            : "";
                          let status = item.SignTemplateId ? (
                            <Tooltip title="" arrow>
                              <Button
                                size="small"
                                className="min-width-auto ms-2"
                              >
                                <VerifiedIcon className="font-20 text-success" />
                              </Button>
                            </Tooltip>
                          ) : (
                            ""
                          );
                          return (
                            <tr
                              onClick={() => handleSelectedRow(item)}
                              key={index}
                            >
                              <td>
                                {editId && editId.id === item.id ? (
                                  <TextField
                                    type="text"
                                    name="DocName"
                                    value={editFormData.DocName}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    size="small"
                                  />
                                ) : (
                                  item.DocName
                                )}
                              </td>
                              <td>
                                {editId && editId.id === item.id ? (
                                  <TextField
                                    type="text"
                                    name="DocuEx"
                                    value={editFormData.DocuEx}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    size="small"
                                  />
                                ) : (
                                  item.DocuEx
                                )}
                              </td>
                              <td>
                                {editId && editId.id === item.id ? (
                                  <TextField
                                    type="text"
                                    name="DocuRemind"
                                    value={editFormData.DocuRemind}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    size="small"
                                  />
                                ) : (
                                  item.DocuRemind
                                )}
                              </td>
                              <td>
                                {editId && editId.id === item.id ? (
                                  <Autocomplete
                                    options={RequestType} //{['Request Document', 'Request Signature', 'Send Form','For Info']}
                                    getOptionLabel={(option) => option.name}
                                    value={editFormData.DocuType}
                                    onChange={handleDocuTypeChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  />
                                ) : (
                                  item.DocuType.name
                                )}
                              </td>
                              <td>
                                {editId && editId.id === item.id ? (
                                  <Autocomplete
                                    options={RequestType} //{['Request Document', 'Request Signature', 'Send Form','For Info']}
                                    getOptionLabel={(option) => option.name}
                                    value={editFormData.DocuType}
                                    onChange={handleDocuTypeChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  />
                                ) : (
                                  filename
                                )}
                              </td>
                              <td>
                                {editId && editId.id === item.id ? (
                                  <Autocomplete
                                    options={RequestType} //{['Request Document', 'Request Signature', 'Send Form','For Info']}
                                    getOptionLabel={(option) => option.name}
                                    value={editFormData.DocuType}
                                    onChange={handleDocuTypeChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  />
                                ) : (
                                  status
                                )}
                              </td>

                              <td>
                                <Box className="d-flex align-items-center">
                                  {item.RequestDocType === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="min-width-auto px-1 me-1"
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent row click
                                        AddSigNature(item);
                                      }}
                                    >
                                      <DrawIcon className="font-16" />
                                    </Button>
                                  )}
                                  <Button
                                    variant="outlined"
                                    className="min-width-auto px-1 me-1 btn-outline-danger"
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent row click
                                      removeDocument(item);
                                    }}
                                  >
                                    <DeleteIcon className="font-16" />
                                  </Button>
                                </Box>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Box>
                ) : (
                  ""
                )}

                <Grid container spacing={2} className="mb-34 mt-2">
                  <Grid item lg={6} xs={6} md={6}>
                    <TextField
                      required
                      label="Document Name"
                      // defaultValue="Hello World"
                      value={txtDocName}
                      className="w-100"
                      size="small"
                      onChange={addDocumnetName}
                    />
                  </Grid>

                  <Grid item lg={6} xs={6} md={6}>
                    <AutoComplete
                      ComData={RequestType}
                      target="name"
                      comFun={AddRequestFun}
                      defaultValue={txtType}
                      lable="Request Type"
                      className="w-100"
                    />
                  </Grid>

                  <Grid item lg={3} xs={3} md={3}>
                    <TextField
                      type="number"
                      required
                      label="Expire in (Days)"
                      // defaultValue="Hello World"
                      value={txtExpireDays}
                      className="w-100"
                      size="small"
                      onChange={getExpireDayes}
                    />
                  </Grid>

                  <Grid item lg={3} xs={3} md={3}>
                    <TextField
                      value={txtReminddays}
                      type="number"
                      required
                      label="Remind in (Days)"
                      // defaultValue="Hello World"
                      className="w-100"
                      size="small"
                      onChange={getRemindDays}
                    />
                  </Grid>

                  <Grid item lg={6} xs={6} md={6}>
                    {/* <Button autoFocus variant="outlined" className='btn-blue w-100' onClick={handleClickAddDoc} startIcon={<CreateIcon />}>
                                            Add Request
                                        </Button> */}
                    {btnbool ? (
                      <Button
                        autoFocus
                        variant="outlined"
                        className="btn-blue w-100"
                        onClick={HandleCliekUpdateTemplate}
                        startIcon={<CreateIcon />}
                      >
                        {btnUpdate}
                      </Button>
                    ) : (
                      <Button
                        autoFocus
                        variant="outlined"
                        className="btn-blue w-100"
                        onClick={HandleCliekCreateTemplate}
                        startIcon={<CreateIcon />}
                      >
                        {btnUpdate}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {txtType && txtType.id !== 0 && (
              <Box className="clearfix">
                <FileUpload
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </Box>
            )}

            <hr />
            {!selectedTemplate && (
              <Box className="text-end mt-4">
                <Button
                  autoFocus
                  variant="outlined"
                  onClick={HandleClickPortalDocTemplateCreate}
                  className="btn-blue"
                  startIcon={<CreateIcon />}
                >
                  Save Template
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
