import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import GetFileType from "../../../components/FileType";

import { useDispatch, useSelector } from "react-redux";
import OrderApprove from "../../../assets/images/icons/OrderApprove.svg";
import Signature from "../../../assets/images/icons/icons8-autograph-24.png";
import { requestDocumentStatusAction } from "../../../redux/dev_aditya_action";
import { downloadShareboxDocument } from "../../../redux/dev_chetan_api_helper";
import { setshareboxDocForPreviewURL } from "../../../redux/dev_chetan_slice";
import { setOpenDocumentPreviewModal } from "../../../redux/modalSlice";

const AttachmentStatusComponant = ({
  portalDocId,
  itm,
  index,
  EdithandleClick,
  EditanchorEl,
  EditsetAnchorEl,
  MessageID,
}) => {
  const dispatch = useDispatch();

  const [docuStatusList, setDocuStatusList] = useState([]);
  const [forApprovalNum, setForApprovalNum] = useState(0);
  const [approvedNum, setApprovedNum] = useState(0);
  const [forSigningNum, setForSigningnum] = useState(0);

  useEffect(() => {
    // console.log(portalDocId,"========== portalDocId");
    let body = {
      docName: itm?.PortalName,
      messageId: portalDocId,
    };

    dispatch(
      requestDocumentStatusAction(body, (res) => {
        setDocuStatusList(res);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (docuStatusList.length > 0) {
      // Reset the counters before updating
      let approvalCount = 0;
      let approvedCount = 0;
      let signingCount = 0;

      // Count based on conditions
      docuStatusList.forEach((data) => {
        if (data.Approved === "Yes") {
          approvedCount += 1;
        }
        if (data.ForApproval === "Yes") {
          approvalCount += 1;
        }
        if (data.ForSigning === "Yes") {
          signingCount += 1;
        }
      });

      // Update the state with the final counts
      setApprovedNum(approvedCount);
      setForApprovalNum(approvalCount);
      setForSigningnum(signingCount);
    }
  }, [docuStatusList]); // Depend only on docuStatusList

  const { getAllSentMessages, getAllSentMessagesLoding } = useSelector(
    (state) => state.docu
  );

  const portalObj = {
    accid: localStorage.getItem("agrno")
      ? localStorage.getItem("agrno")
      : "0003",
    email: localStorage.getItem("Email")
      ? localStorage.getItem("Email")
      : "patrick@docusoft.net",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "UGF0cmljazEyMy4=",
    portalUserEmail: localStorage.getItem("EmailClient")
      ? localStorage.getItem("EmailClient")
      : "",
  };

  const PreviewAttachment = (data) => {
    // EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${portalDocId}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const EdithandleClose = (index) => {
    let temp = { ...EditanchorEl };
    delete temp[index];
    EditsetAnchorEl(temp);
  };

  const DocusoftedDocumnet = (data) => {
    if (getAllSentMessages.length > 0) {
      let res = getAllSentMessages.filter(
        (m) => m["Message ID"].toString() === MessageID
      );
    }
  };

  const PreviewNewTab = (data) => {
    // EditsetAnchorEl(null);
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${portalObj.accid}&attachment=${data.Attachid}&Id=${portalDocId}&extension=${data.DocExtension}`;
    dispatch(setshareboxDocForPreviewURL(url));
    window.open(url, "_blank");
  };

  const DownloadAttachment = (data) => {
    // EditsetAnchorEl(null);
    dispatch(
      downloadShareboxDocument(
        portalDocId,
        data.Attachid,
        data.DocExtension,
        data.PortalName
      )
    );
  };

  return (
    <Accordion
      key={portalDocId}
      sx={{
        marginTop: "5px",
        marginBottom: "5px",
      }}
      className="accordian-box "
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <GetFileType Type={itm.DocExtension.slice(1)}></GetFileType>
            <Box sx={{ marginLeft: "5px" }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "550",
                }}
                className="d-flex align-items-center"
              >
                <span className="d-inline-block ms-2">{itm.PortalName}</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "550",
                }}
                className="d-flex align-items-center"
              >
                <span className="d-inline-block ms-2">
                  {itm?.DDate
                    ? dayjs(parseInt(itm.DDate.match(/\d+/)[0], 10)).format(
                        "ddd DD MMM YYYY HH:mm"
                      )
                    : null}
                </span>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className="d-flex me-2 border-end pe-2">
              <PersonIcon className="font-20" />
              <Typography className="poppins-semibold font-14 ps-1">
                {docuStatusList.length}
              </Typography>
            </Box>
            <Box className="d-flex me-2 border-end pe-2">
              {/* <img src={prize} height={18} /> */}
              <Typography className="poppins-semibold font-14 ps-1">
                {forSigningNum}/{docuStatusList.length}
              </Typography>
            </Box>
            <Box className="d-flex me-2 border-end pe-2">
              {/* <CloseIcon className="font-20" /> */}
              <Typography className="poppins-semibold font-14 ps-1">
                {/* {"1.2"} */}
                {approvedNum}/{docuStatusList.length}
              </Typography>
            </Box>
            <Box>
              <Button
                className="min-width-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  EdithandleClick(e, index);
                }}
                id="basic-button"
                //   aria-controls={
                //     Boolean(
                //       EditanchorE1[index]
                //     )
                //       ? "basic-menu"
                //       : undefined
                //   }
                aria-haspopup="true"
                aria-expanded={
                  Boolean(EditanchorEl[index]) ? "true" : undefined
                }
              >
                <MoreVertIcon className="text-black" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={EditanchorEl[index]}
                open={Boolean(EditanchorEl[index])}
                onClose={() => EdithandleClose(index)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    PreviewAttachment(itm);
                  }}
                >
                  Preview Document
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    DocusoftedDocumnet(itm);
                  }}
                >
                  DocuSoft Document
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    DownloadAttachment(itm);
                  }}
                >
                  Download Document
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    EdithandleClose(e);
                  }}
                >
                  View as flipbook
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    PreviewNewTab(itm);
                  }}
                >
                  View in new tab
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {docuStatusList.length > 0 &&
          docuStatusList.map((Item, ind) => (
            <Box
              key={ind}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "550",
                  }}
                  className="d-flex align-items-center"
                >
                  <span className="d-inline-block ms-2">{Item.Emailid}</span>
                </Typography>
              </Box>
              <Box>
                <img
                  src={OrderApprove}
                  style={{
                    marginRight: "15px",
                  }}
                />
                <img
                  src={Signature}
                  style={{
                    marginRight: "15px",
                  }}
                />
              </Box>
            </Box>
          ))}
        {/*  ))} */}
      </AccordionDetails>
    </Accordion>
  );
};

export default AttachmentStatusComponant;
