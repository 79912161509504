import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import pdf from "../../src/assets/images/files-icon/pdf.png";
import CustomeSignature from "../components/CustomeSignature";
import {
  fetchdocuRequestData,
  SignRequestedDocument_Json,
} from "../redux/api_helper";
import { ad_SetPubUserSign_Json_Action, GetSignatureDocumentAction } from "../redux/dev_aditya_action";
import {
  downloadShareboxDocument,
  getPortalMessageDocuments,
} from "../redux/dev_chetan_api_helper";
import {
  setOpenFromInbox,
  setshareboxDocForPreviewURL,
} from "../redux/dev_chetan_slice";
import {
  setOpenDocumentPreviewModal,
  setOpenSignDocument,
} from "../redux/modalSlice";
import HtmlEditorDX from "../user/pages/create-request/HtmlEditor";
import {
  clientBodyaccIdData,
  RequestDownloadFile,
} from "../utils/RequestsFunction";

function SignDocument({ open, location }) {
  const { PortalDocID, customerId, EmailID } = location?.state || {};
  const agrno = localStorage.getItem("accIdClient");

  const [signaturePreview, setSignaturePreview] = useState(true);
  const [signatureSetup, setSignatureSetup] = useState(false);
  const [commentHtml, setCommentHtml] = useState("");
  const [signatureSetupVisible, setSignatureSetupVisibleSetting] =
    useState(true);
  const { signDocument } = useSelector(({ aditya }) => aditya);

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const customerId = searchParams.get("customerId");
  // const EmailID = searchParams.get("EmailID");
  // const PortalDocID = searchParams.get("PortalDocID");
  const [openSignatur, setOpenSignatur] = React.useState(false);
  const [AddanchorEl, AddsetAnchorEl] = React.useState(null);
  const Addopen = Boolean(AddanchorEl);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenSignDocument(false));
    setSignaturePreview(true);
    setSignatureSetup(false);
  };

  const handleChangeSignature = () => {
    // dispatch(SignatureRemoved(obj12));
    setSignaturePreview(false);
    setSignatureSetup(true);
  };

  const handleChangeSignatureSaved_Json = async () => {
    let signObj;
    if (portalMessageDocuments.length > 0) {
      const allDoc = portalMessageDocuments
        .filter((signDoc) => signDoc.ForSigning)
        .map((signDoc) => {
          const body = { messageId: PortalDocID, attachid: signDoc?.Attachid };
          return dispatch(ad_SetPubUserSign_Json_Action(body, (res) => {}));
        });

      try {
        await Promise.all(allDoc);
        toast.success("All documents processed successfully!");
        dispatch(getPortalMessageDocuments(PortalDocID));
      } catch (error) {
        console.error("Error processing documents:", error);
        toast.error("Failed to process some documents."); // Show error toast
      }
    } else {
      signObj = {
        ...clientBodyaccIdData,
        DocTempId: portalAllRequest.DocTempId ? portalAllRequest.DocTempId : "",
        DocId: portalAllRequest.DocId ? portalAllRequest.DocId : "",
      };
      let obj = {
        DocTempId: portalAllRequest.DocTempId,
        DocId: portalAllRequest.DocId,
      };

      dispatch(
        SignRequestedDocument_Json(signObj, function (res) {
          if (res.length > 0) {
            toast.error(res);
          } else {
            toast.success("Document Signed");
            dispatch(fetchdocuRequestData(EmailID));
            dispatch(GetSignatureDocumentAction(obj, (res) => {}));

            dispatch(setOpenSignDocument(false));
          }
        })
      );
    }
  };

  const AddhandleClick = (event) => {
    AddsetAnchorEl(event.currentTarget);
  };

  const AddhandleClose = () => {
    AddsetAnchorEl(null);
  };

  let portalAllRequest = useSelector(
    (state) => state.docu.fetchDocumentRequest
  );
  let portalAllRequestTest = useSelector((state) => state.docu);

  const { portalMessageDocuments, portalDocIdForSignDocModal, openFromInbox } =
    useSelector((state) => state.dev_chetan);

  //  Aakash Singh
  const { signature } = useSelector((state) => state.ak);
  const obj12 = {
    accid: localStorage.getItem("agrno"),
    email: localStorage.getItem("Email"),
    password: localStorage.getItem("password"),
  };
  // React.useEffect(() => {
  //   dispatch(GetSignature_Json_Redux(obj12));
  // }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setOpenFromInbox(false));
    };
  }, []);

  const base64String = signature ? signature : false; // Replace with your Base64 string
  const format = "png";
  const ImageBox = styled(Box)({
    width: "100%",
    height: "auto",
  });

  const downloadFile = (e, doc) => {
    e.stopPropagation();
    dispatch(
      downloadShareboxDocument(
        PortalDocID,
        doc.Attachid,
        doc.DocExtension,
        doc.PortalName
      )
    );
  };

  const handlePreview = (data) => {
    let url = `https://www.sharedocuments.co.uk/Preview.aspx?accid=${agrno}&attachment=${data.Attachid}&Id=${PortalDocID}&extension=${data.DocExtension}`;
    dispatch(setOpenDocumentPreviewModal(true));
    dispatch(setshareboxDocForPreviewURL(url));
  };

  const handleCommentHtml = (e, editor) => {
    setCommentHtml(e);
  };

  const handleSendApprovalComment = () => {
    setCommentHtml("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-modal"
      sx={{ "& .MuiDialog-paper": { width: "60%", maxWidth: "600px" } }}
    >
      <Box className="d-flex align-items-center justify-content-between modal-head">
        <Box className="clearfix">
          <DialogTitle className="p-0">{"Sign & Approve Document"}</DialogTitle>
        </Box>

        {/*  */}
        <Button onClick={handleClose} autoFocus>
          <CloseIcon />
        </Button>
      </Box>

      <DialogContent>
        {openFromInbox ? (
          <Box>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h3"
                  className="font-12 mb-1 poppins-semibold text-black "
                >
                  {"Attachment List"}
                </Typography>

                {signatureSetup && (
                  <>
                    <Button className="" onClick={AddhandleClick}>
                      <MoreVertIcon />
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={AddanchorEl}
                      open={Addopen}
                      onClose={AddhandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={AddhandleClose}>
                        <DownloadIcon className="" />
                        Download Selected
                      </MenuItem>
                      <MenuItem onClick={AddhandleClose}>
                        <FolderIcon className="" /> Download All
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>

              {portalMessageDocuments.length > 0
                ? portalMessageDocuments.map((doc, index) => (
                    <Box
                      sx={{
                        border: "1px solid #f1f1f1",
                        boxShadow: "none",
                      }}
                      className="white-box d-flex align-items-center justify-content-between p-2"
                      onClick={() => handlePreview(doc)}
                      key={index}
                    >
                      <Box className="d-flex">
                        <img
                          src={pdf}
                          style={{ width: 30 }}
                          alt="documents"
                          className="me-2"
                        />
                        {/* {console.log('test for priview for jay', portalAllRequest)} */}
                        <Typography className="d-flex align-items-center font-13 poppins-semibold">
                          {doc.PortalName ? doc.PortalName : "Not Available"}
                        </Typography>
                      </Box>

                      <Box>
                        <Tooltip title="Download">
                          <Button className="ms-2 min-width-auto" size="small">
                            <DownloadIcon
                              className="text-blue"
                              onClick={(e) => downloadFile(e, doc)}
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  ))
                : "No Attachments"}

              <Box
                sx={{
                  border: "1px solid black",
                  marginTop: "10px",
                  borderRadius: "10px",
                  background: "#7a7a7a",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    fontSize: "12px",
                    color: "#fff",
                    fontWeight: "700",
                    padding: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={pdf} className=" m-auto" height={24} width={24} />

                    <Typography sx={{ marginLeft: "10px" }}>
                      {portalAllRequest.DocName + portalAllRequest.DocExtension}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <hr />

            <HtmlEditorDX
              templateDataMarkup={commentHtml}
              handleEditorChange={handleCommentHtml}
            />

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    // className="font-12 mb-1 poppins-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Preview"}
                  </Typography>

                  <IconButton
                    sx={{ marginTop: "-15px" }}
                    onClick={handleChangeSignature}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Box
                    sx={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {base64String && (
                      <ImageBox
                        component="img"
                        src={`data:image/${format};base64,${base64String}`}
                        alt="Base64 Image"
                        sx={{ width: 300, height: 95 }}
                      />
                    )}
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-red mb-3"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Disapprove{" "}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Sign & Approve{" "}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue mb-3"
                      onClick={handleSendApprovalComment}
                    >
                      Comment{" "}
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                    // setOpenSignatur={setOpenSignatur}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            {portalAllRequest && (
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    className="font-12 mb-1 poppins-semibold text-black "
                  >
                    {"Attachment List"}
                  </Typography>

                  {signatureSetup && (
                    <>
                      <Button className="" onClick={AddhandleClick}>
                        <MoreVertIcon />
                      </Button>

                      <Menu
                        id="basic-menu"
                        anchorEl={AddanchorEl}
                        open={Addopen}
                        onClose={AddhandleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={AddhandleClose}>
                          <DownloadIcon className="" />
                          Download Selected
                        </MenuItem>
                        <MenuItem onClick={AddhandleClose}>
                          <FolderIcon className="" /> Download All
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>

                {Object.keys(signDocument).length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid #f1f1f1",
                      boxShadow: "none",
                    }}
                    className="white-box d-flex align-items-center justify-content-between p-2"
                  >
                    <Box className="d-flex">
                      <img
                        src={pdf}
                        style={{ width: 30 }}
                        alt="documents"
                        className="me-2"
                      />
                      <Typography className="d-flex align-items-center font-13 poppins-semibold">
                        {signDocument.DocName + "." + signDocument.DocExtension}
                      </Typography>
                    </Box>

                    <Box>
                      <Tooltip title="Download">
                        <Button className="ms-2 min-width-auto" size="small">
                          <DownloadIcon
                            className="text-blue"
                            onClick={(e) =>
                              RequestDownloadFile(e, signDocument)
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                )}

                {portalMessageDocuments.length > 0 &&
                  portalMessageDocuments.map(
                    (doc) =>
                      doc.ForSigning && (
                        <Box
                          sx={{
                            border: "1px solid #f1f1f1",
                            boxShadow: "none",
                          }}
                          className="white-box d-flex align-items-center justify-content-between p-2"
                          onDoubleClick={() => handlePreview(doc)}
                        >
                          <Box className="d-flex">
                            <img
                              src={pdf}
                              style={{ width: 30 }}
                              alt="documents"
                              className="me-2"
                            />
                            {/* {console.log('test for priview for jay', portalAllRequest)} */}
                            <Typography className="d-flex align-items-center font-13 poppins-semibold">
                              {doc.PortalName
                                ? doc.PortalName
                                : "Not Available"}
                            </Typography>
                          </Box>

                          <Box>
                            <Tooltip title="Download">
                              <Button
                                className="ms-2 min-width-auto"
                                size="small"
                              >
                                <DownloadIcon
                                  className="text-blue"
                                  onClick={(e) => downloadFile(e, doc)}
                                />
                              </Button>
                            </Tooltip>
                          </Box>
                        </Box>
                      )
                  )}
              </Box>
            )}

            <hr />

            {signaturePreview && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h3"
                    className="mb-2"
                    // className="font-12 mb-1 poppins-semibold text-black "
                    sx={{ fontSize: "14px", fontWeight: "500", color: "black" }}
                  >
                    {"Signature Preview"}
                  </Typography>

                  {base64String && base64String.length <= 1 && (
                    <IconButton
                      sx={{ marginTop: "-15px" }}
                      onClick={handleChangeSignature}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      background: "#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {base64String && base64String.length > 0 ? (
                      <ImageBox
                        component="img"
                        src={`data:image/${format};base64,${base64String}`}
                        alt="Base64 Image"
                        sx={{ width: 300, height: 95 }}
                      />
                    ) : (
                      <h2 className="title font-18">No Signature</h2>
                    )}
                  </Box>

                  <hr />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      className="btn-blue"
                      onClick={handleChangeSignatureSaved_Json}
                    >
                      Sign & Approve{" "}
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            {signatureSetup && (
              <>
                <Box>
                  <CustomeSignature
                    setSignaturePreview={setSignaturePreview}
                    setSignatureSetup={setSignatureSetup}
                    setSignatureSetupVisibleSetting={
                      setSignatureSetupVisibleSetting
                    }
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SignDocument;
