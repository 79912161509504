import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  Paper,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect } from "react";

function LinkDropDown({
  ddType,
  label,
  dataOpt,
  changeMethod,
  isVisible,
  setIsVisible,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setIsVisible(false);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const uniqueDataOpt = dataOpt.map((item, index) => ({
    ...item,
    id: item.id || index, // Ensure every item has a unique id
  }));

  useEffect(() => {
    if (Boolean(isVisible)) {
      setAnchorEl(null);
    }
  }, [isVisible]);

  return (
    <Box className="select-dropdown">
      <Tooltip title="Add" arrow>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {label}
          <KeyboardArrowDownIcon />
        </Button>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="search-list-main custom-dropdown"
      >
        <Paper className="custom-select-dropdown">
          {ddType && ddType == 1 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={uniqueDataOpt}
              getOptionLabel={(option) => option.Folder}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              onChange={changeMethod}
              size="small"
              open={true}
              renderInput={(params) => <TextField {...params} label="All" />}
              className="w-100"
            />
          ) : ddType && ddType == 2 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={uniqueDataOpt}
              getOptionLabel={(option) => option.Client}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              onChange={changeMethod}
              size="small"
              open={true}
              renderInput={(params) => <TextField {...params} label="All" />}
              className="w-100"
            />
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={uniqueDataOpt}
              getOptionLabel={(option) => option.Title+" " + option["First Name"]+" "+option["Last Name"]}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={changeMethod}
              size="small"
              open={true}
              renderInput={(params) => <TextField {...params} label="All" />}
              className="w-100"
            />
          )}
        </Paper>
      </Menu>
    </Box>
  );
}

export default LinkDropDown;
